import * as React from "react";
import ImageIcon from "@mui/icons-material/Image";
import {Box, Typography, useTheme} from "@mui/material";

type MissingImageProps = {
    text?: string;
};

export default function MissingImage({ text }: MissingImageProps) {
    const theme = useTheme();
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '200px',
            backgroundColor: theme.palette.primary.light,
            padding: '20px',
        }}>
            <ImageIcon fontSize={"large"} color={"disabled"}></ImageIcon>
            <Typography component={"article"} variant="body1">{text}</Typography>
        </Box>
    );
}