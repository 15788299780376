import {Preferences} from "../restserver-openapi";

export interface PreferencesModel {
    preferencesId?: number;
    userId?: number;
    language?: Preferences.language;
    currency?: Preferences.currency;
    acceptedAgb: boolean;
    acceptedSaveAndEdit: boolean;
    acceptedNotifications: boolean;
}

export function mappingPreferencesModelToDto (userId: number, inputPreferences: PreferencesModel): Preferences{
    const mappedPreferences: Preferences = {
        //"preferencesId": 0,
        "userId": userId,
        "language": Preferences.language.CH,
        "currency": Preferences.currency.CHF,
        "acceptedAgb": inputPreferences.acceptedAgb,
        "acceptedSaveAndEdit": inputPreferences.acceptedSaveAndEdit,
        "acceptedNotifications": inputPreferences.acceptedNotifications,
    }
    return mappedPreferences;
}
