import * as React from 'react';
import {Box, Card, Grid, useMediaQuery} from '@mui/material';
import UserLevelStats from "./LevelAndRank/UserLevelStats";
import {RewardHistory} from "./RewardHistory";
import UserBadges from "./Badges/UserBadges";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import RankAndLevelExplanationDialog from "./LevelAndRank/RankAndLevelExplanationDialog";
import {Main} from "../../../layouts";
import Container from "../../../components/Container";

function UserLevelSection(): React.ReactElement {
    return (
        <React.Fragment>
            <Typography variant={"h4"} style={{display: 'flex', alignItems: 'center'}}>
                Rang
                <RankAndLevelExplanationDialog/>
            </Typography>
            <Card>
                <CardContent>
                    <UserLevelStats />
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

function RewardSection(): React.ReactElement {
    return (
        <React.Fragment>
            <Typography variant={"h4"}>Verlauf</Typography>
            <Card>
                <CardContent>
                    <RewardHistory />
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

function BadgeSection(): React.ReactElement {
    return (
        <React.Fragment>
            <Typography variant={"h4"}>Auszeichnungen</Typography>
            <UserBadges />
        </React.Fragment>
    );
}

function GamificationProfile(): React.ReactElement {
    const isMobile = useMediaQuery('(max-width: 1200px)');

    return (
      <Main>
        <Container>
        <Grid container spacing={{xs: 2, md: 5}}>
            {isMobile &&
                <Grid item xs={12}>
                    <UserLevelSection />
                </Grid>
            }
            <Grid item xs={12} md={5}>
                <RewardSection />
            </Grid>
            <Grid item xs={12} md={7}>
                {!isMobile &&
                    <Box mb={{xs: 2, md: 3}}>
                        <UserLevelSection />
                    </Box>
                }
                <Grid item>
                    <BadgeSection />
                </Grid>
            </Grid>
        </Grid>
        </Container>
      </Main>
    );
}

export default GamificationProfile;