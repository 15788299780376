import React from 'react';
import {Alert, Tab, Tabs, Typography} from '@mui/material';
import Container from '../../components/Container';
import Main from '../../layouts/Main';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import UserTable from "../../components/agentComponents/UserTable";
import {User} from "../../services/restserver-openapi";
import {setTabNumber} from "../../store/Agent/userOverviewSlice";

const AgentOverviewPage = () => {
    const dispatch = useAppDispatch();
    const tabNumber = useAppSelector((state) => state.userOverview.tabNumber);
    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        if (typeof newValue === "number") {
            dispatch(setTabNumber(newValue));
        }
    };

    const isVerifiedAgent = useAppSelector((state) => state.user.isVerifiedAgent);

    if (!isVerifiedAgent) {
        return (
            <Main>
                <Container>
                    <Alert severity="error">
                        Sie haben keine Agent-Berechtigung. Womöglich ist Ihr Account noch nicht freigeschaltet.
                    </Alert>
                </Container>
            </Main>
        );
    }

    return (
        <Main>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Agent Overview
                </Typography>
                <div>
                    <Tabs value={tabNumber} onChange={handleChange}>
                        <Tab label="Hosts"/>
                        <Tab label="Vanlifers"/>
                        <Tab label="Agents" />
                    </Tabs>
                    {tabNumber === 0 && <UserTable userRole={User.userRole.HOST}/>}
                    {tabNumber === 1 && <UserTable userRole={User.userRole.VANLIFER}/>}
                    {tabNumber === 2 && <UserTable userRole={User.userRole.AGENT}/>}
                </div>
            </Container>

        </Main>
    );
};

export default AgentOverviewPage;