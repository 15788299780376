import React from 'react';
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import {urlLogo, urlLogoDark} from "../../constants/constants";
import {PaletteMode, useTheme} from "@mui/material";

function VanlifeLogo({xs, md, lg}: { xs: string, md: string, lg: string }) {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const navigate = useNavigate();

    function onClick() {

        navigate('/')
    }

    return (
        <Link to='/' onClick={onClick} style={{ textDecoration: 'none' }}>
            <Box
                component="img"
                sx={{
                    height: 80,
                    backgroundColor: 'primary.main',
                    display: {xs: xs, md: md, lg: lg}, mr: 1
                }}
                alt="Vanlife logo."
                src={mode === 'light' as PaletteMode ? urlLogo : urlLogoDark}
            />
        </Link>
    );
}

export default VanlifeLogo
