import React from 'react';
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from 'react-router-dom';

export default function VanlifeTitle({xs, md, lg}: { xs: string, md: string, lg: string}) {
    const navigate = useNavigate();

    function onClick() {
        navigate('/')
    }

    return (
        <Link to='/' onClick={onClick} style={{ textDecoration: 'none' }}>
            <Typography
                variant="h1"
                noWrap
                sx={{
                    mr: 2,
                    display: {xs: xs, md: md, lg: lg},
                    fontWeight: 700,
                    fontSize: 30,
                    color: 'secondary.main',
                    textDecoration: 'none',
                }}
            >
                Vanlife Travel
            </Typography>
        </Link>
    );
}