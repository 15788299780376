import React from 'react';
import {useAppSelector} from "../../hooks/hooks";
import {Main} from "../../layouts";
import Container from "../../components/Container";
import {Typography} from "@mui/material";
import BookingOverview from "../../components/vanliferComponents/BookingOverview";

function BookingOverviewPage() {
    const userSpecificationId = useAppSelector(state => state.user.userSpecificationId)

    return (
        <Main>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Meine Reisen
                </Typography>
                <BookingOverview  userSpecificationId={userSpecificationId} />
            </Container>
        </Main>
    );
}

export default BookingOverviewPage;