import React, {useEffect} from 'react';
import {Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {fetchAllSizes} from "../../store/Host/sitesManagement/sizesSlice";
import OfferImages from "../presentationalComponents/OfferImages";

function SiteManagement() {
    const dispatch = useAppDispatch();
    const site = useAppSelector(state => state.site.site);
    const sizes = useAppSelector(state => state.sizes.sizes);

    useEffect(() => {
        dispatch(fetchAllSizes);
    })

    if (!site) {
        return (
            <Typography> Kein Stellplatz ausgewählt </Typography>
        )
    }

    return (
        <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 3}} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={2}>
                <Typography> Platz Nummer: {site.label}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography> Stellplatz Name: {site.offer!.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography> Platz Grösse: {sizes.map(size => size.sizeId === site.sizeId ? size.name : "")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography> Maximale Anzahl Gäste: {site.offer!.maxPerson}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography component={"article"} variant="body1"> Beschreibung: {site.offer?.description}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <FormControlLabel control={<Checkbox disabled checked={site.electricity} name="electricity"/>}
                                  label="Strom"/>
                <FormControlLabel control={<Checkbox disabled checked={site.water} name="water"/>}
                                  label="Frischwasser"/>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography component={"article"} variant="body1"> Gästeinformationen zum Stellplatz: {site.guestInformation}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography component={"article"} variant="body1"> Bilder: </Typography>
                { site.imageIds && site.imageIds.length > 0 ?
                    <OfferImages imageIds={site.imageIds} altText={"Bild vom Stellplatz " + site.label}/>
                    :
                    <Typography component={"article"} variant="body1"> Keine Bilder vorhanden </Typography>
                }
            </Grid>
        </Grid>
    );
}

export default SiteManagement;

