import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {TitleWithIcon} from "./TitleWithIcon";

interface CompoundDetailCardProps {
    icon: React.ReactNode;
    title: string;
    children: React.ReactNode;
}

export function CompoundDetailCard({icon, title, children}: CompoundDetailCardProps): React.ReactElement {
    return (
        <Card sx={{
          width: 1,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 4,
          bgcolor: 'transparent',
          backgroundImage: 'none',
        }}>
            <CardContent>
                <TitleWithIcon icon={icon} title={title}/>
                {children}
            </CardContent>
        </Card>
    );
}