import React from 'react';
import {CircularProgress, Grid} from "@mui/material";
import Container from "@mui/material/Container";

function LoadingPage() {
    return (
        <Container>
            <Grid container justifyContent={'center'} alignContent={'center'} marginTop={20}>
                    <CircularProgress color="primary"/>
            </Grid>
        </Container>
    );
}

export default LoadingPage;