import * as React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Skeleton} from '@mui/material';

interface BadgesSkeletonProps {
    numberOfElements: number;
}

export function BadgesSkeleton({numberOfElements}: BadgesSkeletonProps): React.ReactElement {
    return <>
        {Array.from(Array(numberOfElements).keys()).map(index =>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={index}>
                <Card>
                    <Skeleton variant="rectangular" height={200} />

                    <CardContent>
                        <Typography variant="h6">
                            <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle2" sx={{ mt: 1 }}>
                            <Skeleton width="50%" />
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>)}
    </>;
}