import React, {useEffect, useState} from 'react';
import {de} from "date-fns/locale";
import {Grid, PaletteMode} from "@mui/material";
import {DateRangePicker} from "react-date-range";
import {useTheme} from "@mui/material/styles";
import './editStayCalenderStyles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import moment from "moment";

type editTourCalenderProps = {
    startDateTimestamp?: number | undefined;
    endDateTimestamp?: number | undefined;
    isStartDateFix?: boolean | undefined;
    isEndDateFix?: boolean | undefined;
    minDate?: number | undefined;
    maxDate?: number | undefined;
    handleEditedStayDates?: (newFromDate: number, newToDate: number) => void;
}

function EditStayCalender(props: editTourCalenderProps) {
    const startDate = new Date(props.startDateTimestamp!);
    const endDate = new Date(props.endDateTimestamp!);
    const minDate = new Date(props.minDate!);
    const maxDate = new Date(props.maxDate!);
    const [areDisabledButtons, setAreDisabledButtons] = React.useState(true);

    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection"
        }
    ]);

    useEffect(() => {
    }, [state])

    const handleOnChange = (ranges: any) => {
        const {selection} = ranges;
        const curentStartDate = state[0].startDate;
        const curentEndDate = state[0].endDate;
        setAreDisabledButtons(false);
        if (props.isStartDateFix) {
            if (state[0].startDate > selection.endDate || state[0].startDate > selection.startDate) {
                setState([{startDate: selection.startDate, endDate: selection.startDate, key: "selection"}])
            } else {
                setState([{startDate: curentStartDate, endDate: selection.endDate, key: "selection"}]);
            }

        } else if (props.isEndDateFix) {
            if (state[0].endDate < selection.endDate || state[0].endDate < selection.startDate) {
                setState([{startDate: selection.endDate, endDate: selection.endDate, key: "selection"}])
            } else if (selection.startDate === selection.endDate || state[0].startDate > selection.startDate) {
                setState([{startDate: selection.startDate, endDate: curentEndDate, key: "selection"}]);
            } else if (state[0].startDate < selection.endDate) {
                setState([{startDate: selection.endDate, endDate: curentEndDate, key: "selection"}]);
            }
        }
    };

    function onReset() {
        setState([{startDate: startDate, endDate: endDate, key: "selection"}])
        setAreDisabledButtons(true);
    }

    function onSave() {
        if (props.handleEditedStayDates) {
            let newStartDateTimestamp = moment(state[0].startDate).add(12, 'hours').utc().startOf('day').valueOf();
            let newEndDateTimestamp = moment(state[0].endDate).add(12, 'hours').utc().startOf('day').valueOf()
            props.handleEditedStayDates(newStartDateTimestamp, newEndDateTimestamp);
        }
    }

    const theme = useTheme();
    const mode = theme.palette.mode;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <DateRangePicker
                    showPreview={false}
                    locale={de}
                    ranges={state}
                    onChange={handleOnChange}
                    showMonthAndYearPickers={false}
                    months={isSmallScreen ? 1 : 2}
                    direction={"horizontal"}
                    showDateDisplay={false}
                    minDate={minDate}
                    maxDate={maxDate}
                    className={'date-range-picker'}
                    rangeColors={[`${mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.dark}`]}
                />
            </Grid>
            <Grid item my={1} display={"flex"} justifyContent={"space-around"} xs={12} md={12}>
                <Button onClick={onReset} variant="contained" disabled={areDisabledButtons}
                        type="submit" color="secondary"
                >Reset</Button>
                <Button onClick={onSave}
                        variant="contained" disabled={areDisabledButtons}
                        type="submit">Save</Button>
            </Grid>

        </Grid>);
}

export default EditStayCalender;
