import * as React from 'react';
import Tooltip from "@mui/material/Tooltip";
import PowerIcon from "@mui/icons-material/Power";
import WaterIcon from "@mui/icons-material/WaterDrop";
import WifiIcon from "@mui/icons-material/Wifi";
import WcIcon from "@mui/icons-material/Wc";
import ShowerIcon from "@mui/icons-material/Shower";
import ShopIcon from "@mui/icons-material/ShoppingCart";
import SewerageIcon from "@mui/icons-material/QuestionMark";
import DogsAllowedIcon from "@mui/icons-material/Pets";
import FamilyFriendlyIcon from "@mui/icons-material/FamilyRestroom";
import InTheMountainsIcon from "@mui/icons-material/Landscape";
import ByTheWaterIcon from "@mui/icons-material/Water";
import CulturalOfferingsIcon from "@mui/icons-material/TheaterComedy";
import {Typography} from "@mui/material";
import {Compound, Site} from "../../services/restserver-openapi";
import { StayCompound, StaySite } from '../../services/restserver-openapi';

interface CompoundEquipmentProps {
    compound?: StayCompound|Compound|undefined|null;
    site?: Site|StaySite|undefined;
    largeIcons?: boolean;
    showTextIfNoEquipment?: boolean;
}

export function CompoundEquipment({compound, site, largeIcons = false, showTextIfNoEquipment = false}: CompoundEquipmentProps): React.ReactElement {
    const fontSize = largeIcons ? "large" : "medium";

    interface SiteAttribute {
        attribute: boolean|undefined;
        icon: React.ReactElement;
    }

    const allAttributes: SiteAttribute[] = [
        {attribute: site?.electricity, icon: <Tooltip title="Elektrizität" key="Elektrizität"><PowerIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.water, icon: <Tooltip title="Wasser" key="Wasser"><WaterIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.wifiAvailable, icon: <Tooltip title="WiFi" key="Wifi"><WifiIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.toilet, icon: <Tooltip title="Toilette" key="Toilette"><WcIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.shower, icon: <Tooltip title="Dusche" key="Dusche"><ShowerIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.shop, icon: <Tooltip title="Shop" key="Shop"><ShopIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.sewerage, icon: <Tooltip title="Abwasser" key="Abwasser"><SewerageIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.dogsAllowed, icon: <Tooltip title="Hunde willkommen" key="Hunde"><DogsAllowedIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.familyFriendly, icon: <Tooltip title="Besonders gut für Familien" key="Familien"><FamilyFriendlyIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.inTheMountains, icon: <Tooltip title="In den Bergen" key="Bergen"><InTheMountainsIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.byTheWater, icon: <Tooltip title="am Wasser" key="Wasser"><ByTheWaterIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.culturalOfferings, icon: <Tooltip title="Kulturangebote in der Nähe" key="Kultur"><CulturalOfferingsIcon fontSize={fontSize} /></Tooltip>}
    ];

    if (showTextIfNoEquipment) {
        if (allAttributes.every(a => !a.attribute)) {
            return <Typography>Keine Ausstattungen erfasst</Typography>;
        }
    }

    return (
        <Typography component="div" display="flex" flexWrap="wrap">
            {allAttributes.map((a) => a.attribute ? a.icon : null)}
        </Typography>
    );
}
