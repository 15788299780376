import Typography from '@mui/material/Typography';
import React, {useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import {Badge, GamificationBadgeService} from '../../../../services/restserver-openapi';
import {useAppSelector} from '../../../../hooks/hooks';
import {BadgesSkeleton} from "./BadgesSkeleton";
import {formatAsSwissDate} from "../../../../utils/dateUtil";

function UserBadges(): React.ReactElement {
    let [badges, setBadges] = React.useState<Badge[] | null>(null);
    let userId = useAppSelector(state => state.login.userId);
    let [error, setError] = React.useState<string | null>(null);

    useEffect(() => {
        async function fetchBadges() {
            try {
                const response = await GamificationBadgeService.getBadges();
                setError(null);
                setBadges(response);
            } catch (error) {
                setError('Fehler: User-Fortschritt konnte nicht geladen werden');
            }
        }

        if (userId !== undefined) {
            fetchBadges();
        }
    }, [userId]);

    function Badges(): React.ReactElement {

        if (error !== null) {
            return (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography color={"error"}>
                                {error}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            );
        }

        if (badges === null) {
            // still loading
            return <BadgesSkeleton numberOfElements={6}/>;
        }

        if (badges.length === 0) {
            return (
                <Grid item>
                    <Typography>Noch keine Auszeichnungen.</Typography>
                </Grid>
            );
        }

        return (
            <>
                {badges.map((badge: Badge) => {
                    let image;
                    try {
                        image = require(`../../../../assets/Images/badges/${badge.id}.jpg`);
                    }
                    catch (e) {
                        image = require(`../../../../assets/Images/badges/placeholder.jpg`);
                    }

                    let receivedAt = formatAsSwissDate(Number(badge.receivedAt));

                    return (
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={badge.id} container justifyContent={"space-between"}>
                            <Grid item xs={12} container justifyContent="center">
                                <Card style={{ width: '100%' }}>
                                    <CardMedia
                                        component="img"
                                        image={image}
                                        style={{
                                            display: 'block',
                                            margin: 'auto',
                                            maxWidth: '200px',
                                            maxHeight: '200px',
                                            boxSizing: 'border-box',
                                            borderRadius: '100%',
                                            padding: '16px'
                                        }}
                                    />
                                    <CardContent style={{paddingTop: 0}}>
                                        <Typography variant="h6" style={{ overflowWrap: 'break-word'}}>{badge.title}</Typography>
                                        <Typography variant="body1">{badge.description}</Typography>
                                        <Typography variant="subtitle2" sx={{ mt: 1 }}>Erhalten am: {receivedAt}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )
                })}
            </>
        );
    }

    return (
        <Grid container spacing={2}>
            <Badges />
        </Grid>
    )
}

export default UserBadges;
