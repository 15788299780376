/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Site } from '../models/Site';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OfferImageService {
    /**
     * Add an image to a site
     * @param siteId
     * @param formData
     * @returns number OK
     * @throws ApiError
     */
    public static addSiteImage(
        siteId: number,
        formData?: {
            image: Blob;
        },
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/image/site/{siteId}',
            path: {
                'siteId': siteId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Add an image to a region
     * @param regionId
     * @param formData
     * @returns number OK
     * @throws ApiError
     */
    public static addRegionImage(
        regionId: number,
        formData?: {
            image: Blob;
        },
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/image/region/{regionId}',
            path: {
                'regionId': regionId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Add an image to a compound
     * @param compoundId
     * @param formData
     * @returns number OK
     * @throws ApiError
     */
    public static addCompoundImage(
        compoundId: number,
        formData?: {
            image: Blob;
        },
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/image/compound/{compoundId}',
            path: {
                'compoundId': compoundId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get an image by id
     * @param imageId
     * @returns string OK
     * @throws ApiError
     */
    public static getImage(
        imageId: number,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/image/{imageId}',
            path: {
                'imageId': imageId,
            },
        });
    }
    /**
     * Delete an image by id
     * @param imageId
     * @returns Site OK
     * @throws ApiError
     */
    public static deleteImage(
        imageId: number,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/image/{imageId}',
            path: {
                'imageId': imageId,
            },
        });
    }
}
