import React, {useEffect} from 'react';
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {Box, Grid, LinearProgress, linearProgressClasses, styled, useMediaQuery} from '@mui/material';
import Avatar from "@mui/material/Avatar";
import {asyncGetUserStats} from "../../../../store/Vanlifer/Gamification/UserStatsSlice";

function UserLevelStats(): React.ReactElement {
    const gamificationConfig = useAppSelector(state => state.gamificationConfig.levelAndRankConfig);
    const userStats = useAppSelector(state => state.userStats.userStats);
    const loading = useAppSelector(state => state.userStats.loading);
    const error = useAppSelector(state => state.userStats.error);
    const userPicture = useAppSelector((state) => state.login.picture);
    const userId = useAppSelector((state) => state.login.userId);
    const dispatch = useAppDispatch();
    const removeAvatar = useMediaQuery('(max-width: 450px)');

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 20,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
        },
    }));

    useEffect(() => {
        if (userId !== undefined)
        {
            dispatch(asyncGetUserStats());
        }
    }, [dispatch, userId]);

    if (error !== null) {
        return (
            <Typography color="error">
                {error}
            </Typography>
        );
    }

    if (loading) {
        return <Skeleton variant="rounded" height={100}/>
    }

    if (userStats === null || userStats === undefined || userStats.rankStats === undefined || userStats.levelStats === undefined || userStats.rankStats.nextRank === undefined) {
        return (
            <Typography color="error">
                Keine Daten vorhanden
            </Typography>
        );
    }

    function getNextLevelThreshold(nextRank: string) {
        return gamificationConfig?.ranks?.find(rank => rank.name === nextRank)?.travelPointThreshold;
    }

    function DynamicAvatar() {
        return removeAvatar
            ? null
            : <Grid item xs={3} sm={2}>
                <Avatar src={userPicture}
                        variant={"rounded"}
                        sx={{height: '100%', width: '100%'}}
                />
            </Grid>;
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} alignItems="stretch">
                <DynamicAvatar />
                <Grid item xs={removeAvatar ? 12 : 9} sm={removeAvatar ? 12 : 10}>
                    <Grid
                        container
                        direction="column"
                        alignItems={"stretch"}
                        justifyContent={"space-between"}
                        height="100%">
                        <Grid item>
                            <Typography variant="h6">
                                {userStats.rankStats.rank}
                                <Typography variant={"subtitle1"} component="span" sx={{ display: 'inline', ml: 0.5 }}>| Level {userStats.levelStats.level}</Typography>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%'}}>
                                    <BorderLinearProgress variant="determinate" value={userStats.rankStats.travelPointsProgressToNextRankPercentage} />
                                </Box>
                                {userStats.rankStats.rank !== userStats.rankStats.nextRank ?  (
                                        <Box sx={{ml: 1}}>
                                            <Typography noWrap={true} variant="body2" color="grey">
                                                {userStats.rankStats.nextRank}
                                            </Typography>
                                        </Box>
                                    ) : null}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {userStats.rankStats.travelPoints}{' / '}
                                {getNextLevelThreshold(userStats.rankStats.nextRank)} Travel Points (TP)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default UserLevelStats;
