import './TourLoadingSpinner.css';
import * as React from 'react';
import SVGComponent from "../../../components/presentationalComponents/SVGComponent";
import {Container} from "@mui/material";

export function TourLoadingSpinner(): React.ReactElement {
    return (
        <Container maxWidth={false} className="camper-loading-icon">
            <SVGComponent iconValue="camper"/>
        </Container>
    );
}
