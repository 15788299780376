import React, { useMemo, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Page from './Page/Page';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Main from '../../layouts/Main';
import AboOverview from '../../components/profileComponents/AboOverview';
import SubscribeAbo from '../../components/profileComponents/SubscribeAbo';
import SelfServicePortal from '../../components/profileComponents/SelfServicePortal';
import { User } from '../../services/restserver-openapi';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getUserSubscriptionsAsync } from '../../store/navBar/userSlice';
import { hostAbos, vanliferAbos } from '../../constants/constants';
import { getMaxAllowedSitesWithoutAddon, getTotalOwnedSites } from '../../store/Host/sitesManagement/siteSlice';

export default function Abos() {
  const dispatch = useAppDispatch();

  const userRole = useAppSelector(state => state.login.userRoleLogin);
  const subscriptions = useAppSelector(state => state.user.subscriptions);
  const totalOwnedSites = useAppSelector(state => state.site.totalOwnedSites);
  const maxAllowedSitesWithoutAddon = useAppSelector(state => state.site.maxAllowedSitesWithoutAddon);
  const userEntitlements = useAppSelector(state => state.user.entitlements);

  const currentAbos = useMemo(() => {
    return subscriptions.flatMap(sub =>
      sub.subscriptionItems?.map(item => item.itemPriceId ? item.itemPriceId : '') ?? [],
    );
  }, [subscriptions]);

  const hasPaidSubscription = useMemo(() => {
    return currentAbos.length > 0 && !currentAbos.some(abo => abo.startsWith('host-free') || abo.startsWith('vanlifer-free'));
  }, [currentAbos]);

  useEffect(() => {
    dispatch(getUserSubscriptionsAsync());
    if (userRole === User.userRole.HOST) {
      dispatch(getTotalOwnedSites());
      dispatch(getMaxAllowedSitesWithoutAddon());
    }
  }, [dispatch, userRole]);

  return (
    <Main>
      <Page>
        <Box>
          {userRole && subscriptions && userEntitlements &&
            <AboOverview subscriptions={subscriptions}
                         maxAllowedSitesWithoutAddon={maxAllowedSitesWithoutAddon}
                         totalOwnedSites={totalOwnedSites}
                         userEntitlements={userEntitlements}
                         userRole={userRole}
                         editable={true} />
          }
          {hasPaidSubscription ?
            <Box />
            :
            <Box>
              <Typography variant="h4" gutterBottom fontWeight={700}>
                Neues Abo abschliessen
              </Typography>
              <SubscribeAbo abos={userRole === User.userRole.HOST ? hostAbos : vanliferAbos} />
            </Box>
          }
        </Box>
      </Page>
    </Main>
  );
};