import {
    Availability,
    Compound,
    OfferSiteService,
    OfferAvailabilityService,
    OfferRegionService,
    OfferCompoundService,
    OpenAPI,
    Site,
    OfferSizeService, SubscriptionService
} from '../../services/restserver-openapi';

export function setTokenOfferInApi(identityToken: string) {
    OpenAPI.TOKEN = identityToken;
}

export const getSizes= ()=>OfferSizeService.getSizes();

export const getSites = async (compoundId: number) => {
    return OfferSiteService.getSites(compoundId);
}

export const addSite = (site: Site) => OfferSiteService.addSite(site);

export const updateSite = (site: Site) => OfferSiteService.updateSite(site);

export const deleteSite = (siteId: number) => OfferSiteService.deleteSite(siteId);

export const getAmountOfSites = (hostId: number|undefined = undefined) => OfferSiteService.getAmountOfSites(hostId);

export const getAsyncMaxAllowedSitesWithoutAddon = (hostId: number|undefined = undefined) => SubscriptionService.getAllowedAmountOfSitesWithoutAddon(hostId);

export const getAvailabilities = (offerId: number)=> OfferAvailabilityService.getAvailabilities(offerId);

export const addAvailabilities = (availabiities: Availability[]) => OfferAvailabilityService.addAvailabilities(availabiities);

export const updateAvailabilities = (availabilities: Availability[])=>OfferAvailabilityService.updateAvailabilities(availabilities);

export const deleteAvailabilities=(availabilityIds: number[])=>OfferAvailabilityService.deleteAvailabilities(availabilityIds);

export const getRegions= ()=>OfferRegionService.getRegions();

export const getCompounds= (hostId: number)=>OfferCompoundService.getCompounds(hostId);

export const getCompoundsByRegion = (regionId: number) => OfferCompoundService.getCompounds(0, regionId);

export const getCompoundEntitlements= (compoundId: number)=> SubscriptionService.getCompoundEntitlements(compoundId);

export const updateCompound = (compound: Compound) => OfferCompoundService.updateCompound(compound);

export const addCompound = (compound: Compound) => OfferCompoundService.addCompound(compound);
export const getAvailabilitiesBookedByHost= (hostId: number, fromTimestamp: number, toTimestamp: number)=>OfferAvailabilityService.getAvailabilitiesBookedByHost(hostId, fromTimestamp, toTimestamp);

