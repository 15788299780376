import React, {Component} from "react";

class EmbedReviewComponent extends Component {
    componentDidMount() {
        (function(d, s, id) {
            var js; 
            if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; 
            js.src = "https://embedsocial.com/cdn/ht.js"; 
            d.getElementsByTagName("head")[0].appendChild(js); 
          } (document, "script", "EmbedSocialHashtagScript"));
    }
    
    render() {
        return (
            <div 
                className="embedsocial-hashtag" 
                data-ref="152d5642c1bdda2580c6ae3446ce74cef72f7c0a">
                <a className="feed-powered-by-es feed-powered-by-es-feed-new" href="https://embedsocial.com/products/reviews/" target="_blank" rel="noreferrer" title="Widget by EmbedSocial"></a>
          </div>
        );
    }
}

export default EmbedReviewComponent;



