import React from 'react';
import {Box, Button, Grid} from '@mui/material';
import {setPaymentComponent} from "../../../store/Vanlifer/vanliferComponentsSlice";
import {useAppDispatch} from "../../../hooks/hooks";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import PaymentNotice from "./PaymentNotice";
import {feePaymentComponent} from "../../../constants/textConstant";
import {useNavigate} from "react-router-dom";

function PaymentComponent() {
    const dispatch = useAppDispatch();
    const navigate= useNavigate();
    function onBackClick() {
        dispatch(setPaymentComponent(false));
    }
    function onForwardClick() {
        dispatch(setPaymentComponent(false));
        navigate('/profil/abos')
    }

    return (
        <Grid container my={2} rowSpacing={4} columnSpacing={{xs: 1, sm: 2, md: 3}}
              justifyContent="space-evenly" alignItems="flex-start" padding={1}>
                <PaymentNotice
                    paymentNoticeText={
                    feePaymentComponent.feeText}
                />
            <Grid item xs={12} sm={12} md={12}
                  sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box>
                    <Button fullWidth variant="contained" type="submit" color="primary"
                             onClick={onBackClick}>
                        <ArrowBackIosNewRoundedIcon/>
                        Zurück
                    </Button>
                </Box>
                <Box>
                    <Button  onClick={onForwardClick} fullWidth variant="contained" color="secondary" type="submit">
                        Kostenpflichtiges Abo abschliessen <ArrowForwardIosRoundedIcon/>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default PaymentComponent;
