import {Box, Typography} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "../../hooks/hooks";
import {fetchToursFromUser} from "../../store/Vanlifer/tourSlice";
import {TourOverview} from "../../services/restserver-openapi";
import BookingOverviewGridComponent, {
    BookingOverviewSkeleton
} from "./BookingOverviewGridComponent";

interface BookingOverviewProps {
    userSpecificationId: number;
}

function BookingOverview({ userSpecificationId }: BookingOverviewProps) {
    const dispatch = useAppDispatch();

    const [futureTours, setFutureToursData] = useState<TourOverview[] | null>(null);
    const [pastTours, setPastToursData] = useState<TourOverview[] | null>(null);
    const [cancelTours, setCancelToursData] = useState<TourOverview[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const getFirstStartDate = useCallback((tour: TourOverview): number | undefined => {
        const stays = tour.stays;

        if (stays && stays.length > 0) {
            const sortedStays = stays
                .slice()
                .sort((stayA, stayB) => (stayA.fromDate || 0) - (stayB.fromDate || 0));

            return sortedStays[0]?.fromDate;
        }

        return undefined;
    }, []);

    const orderByFirstStartDate = useCallback((tours: TourOverview[]): TourOverview[] => {
        return tours.slice().sort((tourA, tourB) => {
            const firstStartDateA = getFirstStartDate(tourA);
            const firstStartDateB = getFirstStartDate(tourB);

            if (firstStartDateA === undefined && firstStartDateB === undefined) {
                return 0;
            } else if (firstStartDateA === undefined) {
                return 1;
            } else if (firstStartDateB === undefined) {
                return -1;
            }

            return firstStartDateA - firstStartDateB;
        });
    }, [getFirstStartDate]);

    const fetchTours = useCallback(async() =>{
        setIsLoading(true);

        try{
            let [booked, cancelled] = await Promise.all([
                dispatch(fetchToursFromUser({vanliferId: userSpecificationId, tourStatus: 'BOOKED'})).unwrap(),
                dispatch(fetchToursFromUser({vanliferId: userSpecificationId, tourStatus: 'CANCELED'})).unwrap()
            ]);

            if (booked !== undefined) {
                const uncompletedTours = booked.filter(t => !t.tourCompleted);
                setFutureToursData(orderByFirstStartDate(uncompletedTours));

                const completedTours = booked.filter(t => t.tourCompleted);
                setPastToursData(orderByFirstStartDate(completedTours));
            }

            if (cancelled !== undefined) {
                setCancelToursData(orderByFirstStartDate(cancelled));
            }
        } catch (error) {
            setIsError(true);
        }

        setIsLoading(false);
    }, [dispatch, orderByFirstStartDate, userSpecificationId]);

    useEffect(() => {
        fetchTours();
    }, [fetchTours]);


    const renderTours = (tours: TourOverview[] | null, textForNoTours: string): React.ReactNode => {
        if (isLoading) {
            return <BookingOverviewSkeleton />;
        }

        if (isError) {
            return <Typography color="red">Es ist ein Fehler aufgetreten beim Laden.</Typography>;
        }

        if (tours === null || tours.length === 0) {
            return <Typography>{textForNoTours}</Typography>;
        }

        return <BookingOverviewGridComponent tours={tours} />;
    }

    return (
        <Box>
            {renderTours(futureTours, "Keine Reisen geplant.")}

            <Typography variant="h5" sx={{mt: '14px'}}>Vergangene Reisen</Typography>
            {renderTours(pastTours, "Keine Reisen durchgeführt.")}

            <Typography variant="h5" sx={{mt: '14px'}}>Stornierte Reisen</Typography>
            {renderTours(cancelTours, "Keine Reisen durchgeführt.")}
        </Box>
    );
}
export default BookingOverview;