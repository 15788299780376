import './Timeline.css';
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import {timelineOppositeContentClasses} from "@mui/lab";

interface TimelineProps {
    children: React.ReactNode;
}
const TimelineFrame = ({children} : TimelineProps) => {

        return (
            <Timeline sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.3,
                },
                [`.MuiTimelineItem-missingOppositeContent::before`]: {
                    display: "none"
                }
            }}>
                {children}
            </Timeline>
        );
    };

export default TimelineFrame;
