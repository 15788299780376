import React from 'react';
import moment from 'moment';
import { Calendar,  momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';
import './vltCalendar.css';
import {CalendarEvent, getEventsBase, getEventStyle} from "../../utils/Host/calendarUtil";
import {AvailabilityDetail} from "../../services/restserver-openapi";

require('moment/locale/de.js');

const localizer = momentLocalizer(moment);

interface ManagementCalendarOverviewProps {
    availabilities: AvailabilityDetail[];
}

const ManagementCalendarOverview: React.FC<ManagementCalendarOverviewProps> = ({ availabilities }) => {
    const sites = useAppSelector((state) => state.site.sites);


    function getEvents(): CalendarEvent[] {
        return getEventsBase(availabilities, sites, true);
    }

    return (
        <Box>
            <div>
                <Calendar
                    events={getEvents()}
                    startAccessor="startDate"
                    endAccessor="endDate"
                    defaultDate={moment().toDate()}
                    localizer={localizer}
                    defaultView={'agenda'}
                    views={{ agenda: true, month: true  }}
                    selectable={false}
                    messages={{
                        previous: '<',
                        next: '>',
                        today: 'Heute',
                        month: 'Monat',
                        agenda: 'Terminübersicht',
                    }}
                    style={{ height: '1000pt' }}
                    eventPropGetter={getEventStyle}
                />
            </div>
        </Box>
    );
}

export default ManagementCalendarOverview;
