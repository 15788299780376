import * as React from 'react';
import Skeleton from "@mui/material/Skeleton";
import TimelineFrame from './Gamification/Timeline/TimelineFrame';
import TimelineItem from "@mui/lab/TimelineItem";
import {Separator} from "./Gamification/Timeline/Separator";
import {Box, Grid, Stack, useMediaQuery, useTheme} from "@mui/material";
import {useState, useEffect} from 'react';

/**
 *  From https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
 */

function useWindowDimensions() {
    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function TourEditorSkeleton(): React.ReactElement {
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {height, width} = useWindowDimensions();
    const numberOfElements = 4;

    // Using 100% as width doesn't work with the TimelineItem
    const elementWidth = isSmallScreen ? width - 80 : width * 0.5 - 80;

    return (
        <TimelineFrame>
            {Array.from({length: numberOfElements}).map((_, index) => {
                return (
                    <TimelineItem key={index}>
                        <Separator index={index} numberOfElements={numberOfElements}>
                            {index === 0
                                ? <Skeleton variant="rectangular" width={elementWidth} height={53}/>
                                : <Skeleton variant="rectangular" width={elementWidth} height={310}/>
                            }
                        </Separator>
                    </TimelineItem>
                );
            })
            }
        </TimelineFrame>
    );
}

function HereMapSkeleton(): React.ReactElement {
    return (
        <Box sx={{px: 2, mb: 1, mt: 4}}>
            <Skeleton variant="rectangular" width='100%' height={470}/>
        </Box>
    );
}

export function BookingTourOverviewSkeleton(): React.ReactElement {
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    if (isSmallScreen) {
        return (
            <Stack>
                <HereMapSkeleton/>
                <TourEditorSkeleton/>
            </Stack>
        );
    }

    return (
        <Grid container display="flex" justifyContent="space-around">
            <Grid item xs={12} md={6.8}>
                <TourEditorSkeleton/>
            </Grid>

            <Grid item xs={11} md={5.2}>
                <HereMapSkeleton/>
            </Grid>
        </Grid>
    );
}
