import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {loginReducer} from "./navBar/loginSlice";
import {userReducer} from "./navBar/userSlice";
import {campingManagementReducer} from "./Host/sitesManagement/campingManagementSlice";
import {siteReducer} from "./Host/sitesManagement/siteSlice"
import {tourReducer} from "./Vanlifer/tourSlice";
import {availabilityReducer} from "./Host/sitesManagement/availabilitySlice";
import {sizesReducer} from "./Host/sitesManagement/sizesSlice";
import {regionReducer} from "./Vanlifer/regionSlice";
import {vanliferComponentsReducer} from "./Vanlifer/vanliferComponentsSlice";
import {compoundsReducer} from "./Host/compoundsSlice";
import {upcomingStaysReducer} from "./Host/sitesManagement/upcomingStays";
import {kudosReducer} from "./Vanlifer/Gamification/KudosSlice";
import {gamificationConfigReducer} from "./Vanlifer/Gamification/gamificationConfigSlice";
import {userStatsReducer} from "./Vanlifer/Gamification/UserStatsSlice";
import {agentUserDetailSliceReducer} from "./Agent/agentUserDetailSlice";
import {userOverviewSliceReducer} from "./Agent/userOverviewSlice";

const appReducer = combineReducers({
    login: loginReducer,
    user: userReducer,
    site: siteReducer,
    sizes: sizesReducer,
    camping: campingManagementReducer,
    tour: tourReducer,
    availability: availabilityReducer,
    upcomingStays: upcomingStaysReducer,
    region: regionReducer,
    compounds: compoundsReducer,
    vanliferComponents: vanliferComponentsReducer,
    kudos: kudosReducer,
    gamificationConfig: gamificationConfigReducer,
    userStats: userStatsReducer,
    userOverview: userOverviewSliceReducer,
    agentUserDetail: agentUserDetailSliceReducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'login/removeUserFromState') {
        state = undefined
    }

    return appReducer(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
