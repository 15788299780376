/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Badge } from '../models/Badge';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GamificationBadgeService {
    /**
     * Get a list of badges by logged in user
     * @returns Badge OK
     * @throws ApiError
     */
    public static getBadges(): CancelablePromise<Array<Badge>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gamification/user/badges',
        });
    }
}
