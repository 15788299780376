import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {Availability, AvailabilityDetail} from "../../services/restserver-openapi";
import {
    createAvailabilities,
    defaultAvailability,
    deleteSelectedAvailabilities,
    editAvailabilities,
    fetchAllAvailabilities,
    setEndDateRange,
    setStartDateRange
} from "../../store/Host/sitesManagement/availabilitySlice";
import {Moment} from "moment/moment";
import DatePickerVanlife from "../presentationalComponents/DatePickerVanlife";
import FormControl from "@mui/material/FormControl";
import VltAlertDialog from "../presentationalComponents/VltAlertDialog";
import {vltAlertDialog} from "../../constants/textConstant";
import availabilityStatus = Availability.availabilityStatus;

interface AvailabilityFormProps {
    availabilities: AvailabilityDetail[];
}

const AvailabilityForm: React.FC<AvailabilityFormProps> = ({ availabilities }) => {
    const dispatch = useAppDispatch();
    const site = useAppSelector(state => state.site.site);
    const startDateRange = useAppSelector(state => state.availability.startDateRange);
    const endDateRange = useAppSelector(state => state.availability.endDateRange);
    const [startDate, setStartDate] = React.useState<Moment | null>(startDateRange); //moment('2022-06-01', 'YYYY-MM-DD')
    const [endDate, setEndDate] = React.useState<Moment | null>(endDateRange);
    const [availability, setAvailability] = useState<Availability>(defaultAvailability);
    let [popupContent, setPopUpContent] = React.useState(vltAlertDialog.dialogTextForAgreeDeleteOnlyAvailableAvailabilities)
    let [agreeButtonText, setAgreeButtonText] = React.useState(vltAlertDialog.dialogAgreeDeleteButtonText)
    const [openDeleteAvailabilitiesDialog, setOpenDeleteAvailabilitiesDialog] = React.useState(false);
    const [openEditAvailabilitiesDialog, setOpenEditAvailabilitiesDialog] = React.useState(false);
    const [endDateModified, setEndDateModified] = React.useState<boolean>(false);
    const selectedCompoundEntitlements = useAppSelector(state => state.compounds.selectedCompoundEntitlements);
    const hasHostAddAvailabilities = useMemo(() => {
        return selectedCompoundEntitlements.some(entitlement => entitlement.featureId === 'hostaddavailabilities' && entitlement.value === "true")
    }, [selectedCompoundEntitlements]);

    const [isSaving, setIsSaving] = React.useState(false);

    let areBookedOrReservedAvailabilitiesSelected = false
    let areBookedByHostAvailabilitiesSelected = false
    const [selectedAvailabilitiesIds, setSelectedAvailabilitiesIds] = React.useState<number[]>([]);
    const [selectedAvailabilitiesToCreate, setSelectedAvailabilitiesToCreate] = React.useState<Availability[]>([]);
    const [selectedAvailabilitiesToEdit, setSelectedAvailabilitiesToEdit] = React.useState<Availability[]>([]);

    useEffect(() => {
        setStartDate(startDateRange);
        setEndDate(endDateRange);
    }, [startDateRange, endDateRange])

    const handleStartDateChange = (newValue: Moment | null) => {
        dispatch(setStartDateRange(newValue));
        if (!endDateModified) {
            dispatch(setEndDateRange(newValue));
        }
    };
    const handleEndDateChange = (newValue: Moment | null) => {
        setEndDateModified(true);
        dispatch(setEndDateRange(newValue));
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        let temporaryCreatedAvailabilities: Availability[] = [];
        let temporaryEditedAvailabilities: Availability[] = [];
        let startDateMidday = startDate?.add(12, 'hours');
        let endDateMidday = endDate?.add(12, 'hours');
        if (startDateMidday && site.offer!.offerId && endDateMidday) {
            while (startDateMidday.isSameOrBefore(endDate)) {
                let startDateTimestamp = startDateMidday.utc().startOf('day').valueOf();
                let siteAvailabilities = availabilities.filter(availability => availability.offerId === site.offer!.offerId)
                let found: boolean = false;
                for (let siteAvailability of siteAvailabilities) {
                    if (siteAvailability.datetime === startDateTimestamp) {
                        if (siteAvailability.availabilityStatus === availabilityStatus.BOOKED ||  siteAvailability.availabilityStatus === availabilityStatus.RESERVED) {
                            areBookedOrReservedAvailabilitiesSelected = true;
                            found = true
                        } else if (siteAvailability.availabilityStatus === availabilityStatus.BOOKED_BY_HOST) {
                            areBookedByHostAvailabilitiesSelected = true;
                            temporaryEditedAvailabilities.push({...siteAvailability, availabilityStatus: availabilityStatus.AVAILABLE, comment: '', price: availability.price })
                            found = true
                        } else {
                            temporaryEditedAvailabilities.push({ ...siteAvailability, price: availability.price })
                            found = true
                        }
                    }
                }
                if (!found) {
                    temporaryCreatedAvailabilities.push({
                        ...availability,
                        offerId: site.offer!.offerId,
                        datetime: startDateTimestamp
                    })
                }
                startDateMidday.add(1, 'days');
            }
        }
        if (areBookedOrReservedAvailabilitiesSelected) {
            setSelectedAvailabilitiesToEdit(temporaryEditedAvailabilities);
            setSelectedAvailabilitiesToCreate(temporaryCreatedAvailabilities);
            setPopUpContent(vltAlertDialog.dialogTextForEditOnlyAvailableAvailabilities);
            setAgreeButtonText(vltAlertDialog.dialogAgreeEditAvailabilitiesButtonText);
            setOpenEditAvailabilitiesDialog(true);
        } else if (areBookedByHostAvailabilitiesSelected) {
            setSelectedAvailabilitiesToEdit(temporaryEditedAvailabilities);
            setSelectedAvailabilitiesToCreate(temporaryCreatedAvailabilities);
            setPopUpContent(vltAlertDialog.dialogTextForBookedByHostAvailabilities);
            setAgreeButtonText(vltAlertDialog.dialogAgreeDeleteButtonText);
            setOpenEditAvailabilitiesDialog(true);
        } else {
            if (temporaryCreatedAvailabilities.length > 0) {
                await dispatch(createAvailabilities(temporaryCreatedAvailabilities));
            }
            if (temporaryEditedAvailabilities.length > 0) {
                await dispatch(editAvailabilities(temporaryEditedAvailabilities));
            }
            setAvailability(defaultAvailability);
            dispatch(setStartDateRange(null));
            dispatch(setEndDateRange(null));
            setEndDateModified(false);
            dispatch(fetchAllAvailabilities(site.offer!.offerId!));
        }
    }

    const editOnlyAvailableAvailabilities = async () => {
        setOpenEditAvailabilitiesDialog(false);
        if (selectedAvailabilitiesToEdit.length > 0) {
            await dispatch(editAvailabilities(selectedAvailabilitiesToEdit));
        }
        if (selectedAvailabilitiesToCreate.length > 0) {
            await dispatch(createAvailabilities(selectedAvailabilitiesToCreate));
        }
        setBookingComment('');
        setBookingDialogOpen(false);
        setIsSaving(false );
        dispatch(setStartDateRange(null));
        dispatch(setEndDateRange(null));
        setEndDateModified(false);
        setAvailability(defaultAvailability);
        dispatch(fetchAllAvailabilities(site.offer!.offerId!));
    }

    const handleDeleteAvailabilities = async () => {
        let temporaryAvailabilitiesIds: number[] = [];
        let startDateMidday = startDate?.add(12, 'hours');
        let endDateMidday = endDate?.add(12, 'hours');
        let temporaryAvailabilities: Availability[] = [];
        if (startDateMidday && endDateMidday && availabilities) {
            while (startDateMidday.isSameOrBefore(endDateMidday)) {
                temporaryAvailabilities = filterAvailabilities(startDateMidday, endDateMidday, temporaryAvailabilities);
                startDateMidday.add(1, 'days');
            }
        } else if (startDateMidday && availabilities) {
            temporaryAvailabilities = filterAvailabilities(startDateMidday, endDateMidday, temporaryAvailabilities);
        }
        temporaryAvailabilities.forEach(availability => {
            if (availability.availabilityStatus === availabilityStatus.BOOKED || availability.availabilityStatus === availabilityStatus.RESERVED) {
                areBookedOrReservedAvailabilitiesSelected = true;
            }
            if (availability.availabilityId && availability.availabilityStatus === availabilityStatus.AVAILABLE) {
                temporaryAvailabilitiesIds.push(availability.availabilityId)
            } else if (availability.availabilityId && availability.availabilityStatus === availabilityStatus.BOOKED_BY_HOST) {
                areBookedByHostAvailabilitiesSelected = true;
                temporaryAvailabilitiesIds.push(availability.availabilityId)
            }
        })
        if (!areBookedOrReservedAvailabilitiesSelected) {
            setSelectedAvailabilitiesIds(temporaryAvailabilitiesIds);
            setPopUpContent(vltAlertDialog.dialogTextForDeleteAvailabilities);
            setAgreeButtonText(vltAlertDialog.dialogAgreeDeleteButtonText);
            setOpenDeleteAvailabilitiesDialog(true);
        } else if (areBookedByHostAvailabilitiesSelected) {
            setSelectedAvailabilitiesIds(temporaryAvailabilitiesIds);
            setPopUpContent(vltAlertDialog.dialogTextForBookedByHostAvailabilities);
            setAgreeButtonText(vltAlertDialog.dialogAgreeDeleteButtonText);
            setOpenDeleteAvailabilitiesDialog(true);
        } else {
            setSelectedAvailabilitiesIds(temporaryAvailabilitiesIds);
            setPopUpContent(vltAlertDialog.dialogTextForAgreeDeleteOnlyAvailableAvailabilities);
            setAgreeButtonText(vltAlertDialog.dialogAgreeDeleteOnlyAvailableAvailabilitiesButtonText);
            setOpenDeleteAvailabilitiesDialog(true);
            areBookedOrReservedAvailabilitiesSelected = false;
        }
    }

    const deleteOnlyAvailableAvailabilities = async () => {
        setOpenDeleteAvailabilitiesDialog(false);
        await dispatch(deleteSelectedAvailabilities(selectedAvailabilitiesIds));
        dispatch(setStartDateRange(null));
        dispatch(setEndDateRange(null));
        setEndDateModified(false);
        dispatch(fetchAllAvailabilities(site.offer!.offerId!));
    }

    const filterAvailabilities = (startDateMidday: Moment | undefined, endDateMidday: Moment | undefined, temporaryAvailabilities: Availability[]): Availability[] => {
        let startDateTimestamp = startDateMidday?.utc().startOf('day').valueOf();
        availabilities.map(async (availability) => {
            if (availability.availabilityId && availability.datetime === startDateTimestamp && site.offer!.offerId === availability.offerId) {
                temporaryAvailabilities.push(availability)
            }
        });
        return temporaryAvailabilities;
    }
    const handleCloseVltAlertDialog = () => {
        setBookingComment('');
        setBookingDialogOpen(false);
        setIsSaving(false );
        setOpenDeleteAvailabilitiesDialog(false);
        setOpenEditAvailabilitiesDialog(false);
        dispatch(setStartDateRange(null));
        dispatch(setEndDateRange(null));
        setEndDateModified(false);
    }

    const [bookingDialogOpen, setBookingDialogOpen] = React.useState(false);
    const [bookingComment, setBookingComment] = React.useState('');

    const handleBookingClickOpen = () => {
        setBookingDialogOpen(true);
    };

    const handleBookingDialogClose = () => {
        setBookingDialogOpen(false);
        setBookingComment('');
    };

    const handleBookingSave = async () => {
        if (bookingComment) {
            setIsSaving(true);
            let temporaryCreatedAvailabilities: Availability[] = [];
            let temporaryEditedAvailabilities: Availability[] = [];
            let startDateMidday = startDate?.add(12, 'hours');
            let endDateMidday = endDate?.add(12, 'hours');

            if (startDateMidday && site.offer!.offerId && endDateMidday) {
                while (startDateMidday.isSameOrBefore(endDate)) {
                    let startDateTimestamp = startDateMidday.utc().startOf('day').valueOf();
                    let siteAvailabilities = availabilities.filter(availability => availability.offerId === site.offer!.offerId);
                    let found: boolean = false;

                    for (let siteAvailability of siteAvailabilities) {
                        if (siteAvailability.datetime === startDateTimestamp) {
                            if (
                                siteAvailability.availabilityStatus === availabilityStatus.BOOKED ||
                                siteAvailability.availabilityStatus === availabilityStatus.RESERVED
                            ) {
                                areBookedOrReservedAvailabilitiesSelected = true;
                                found = true;
                            } else if (siteAvailability.availabilityStatus === availabilityStatus.BOOKED_BY_HOST) {
                                areBookedByHostAvailabilitiesSelected = true;
                                temporaryEditedAvailabilities.push({
                                    ...siteAvailability,
                                    comment: bookingComment
                                });
                                found = true;
                            } else {
                                temporaryEditedAvailabilities.push({
                                    ...siteAvailability,
                                    availabilityStatus: availabilityStatus.BOOKED_BY_HOST,
                                    comment: bookingComment,
                                });
                                found = true;
                            }
                        }
                    }

                    if (!found) {
                        temporaryCreatedAvailabilities.push({
                            ...availability,
                            offerId: site.offer!.offerId,
                            datetime: startDateTimestamp,
                            availabilityStatus: availabilityStatus.BOOKED_BY_HOST,
                            comment: bookingComment,
                        });
                    }
                    startDateMidday.add(1, 'days');
                }
            }


            if (areBookedOrReservedAvailabilitiesSelected) {
                setSelectedAvailabilitiesToEdit(temporaryEditedAvailabilities);
                setSelectedAvailabilitiesToCreate(temporaryCreatedAvailabilities);
                setPopUpContent(vltAlertDialog.dialogTextForEditOnlyAvailableAvailabilities);
                setAgreeButtonText(vltAlertDialog.dialogAgreeEditAvailabilitiesButtonText);
                setOpenEditAvailabilitiesDialog(true);
            } else if (areBookedByHostAvailabilitiesSelected) {
                setSelectedAvailabilitiesToEdit(temporaryEditedAvailabilities);
                setSelectedAvailabilitiesToCreate(temporaryCreatedAvailabilities);
                setPopUpContent(vltAlertDialog.dialogTextForBookedByHostAvailabilitiesEdit);
                setAgreeButtonText(vltAlertDialog.dialogAgreeEditButtonText);
                setOpenEditAvailabilitiesDialog(true);
            } else {
                if (temporaryCreatedAvailabilities.length > 0) {
                    await dispatch(createAvailabilities(temporaryCreatedAvailabilities));
                }
                if (temporaryEditedAvailabilities.length > 0) {
                    await dispatch(editAvailabilities(temporaryEditedAvailabilities));
                }
                setIsSaving(false);
                setBookingComment('');
                setBookingDialogOpen(false);
                setAvailability(defaultAvailability);
                dispatch(setStartDateRange(null));
                dispatch(setEndDateRange(null));
                setEndDateModified(false);
                dispatch(fetchAllAvailabilities(site.offer!.offerId!));
            }
        } else {
            // Snackbar MUI alert no comment


        }
    };

    return (
        <form data-testid="availability-form" onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} my={4}>

                <Grid item xs={12} sm={12} md={3}>
                    <DatePickerVanlife
                        handleChange={handleStartDateChange}
                        value={startDate}
                        label={"Startdatum"}
                        name={"startDate"}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <DatePickerVanlife
                        handleChange={handleEndDateChange}
                        value={endDate}
                        name={"endDate"}
                        label={"Enddatum"}/>
                </Grid>

                <Grid item xs={12} sm={12} md={1.5}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        data-testid="book-availabilities"
                        onClick={handleBookingClickOpen}
                    >Buchen</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={1.5}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        data-testid="delete-availabilities"
                        onClick={handleDeleteAvailabilities}>Löschen</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={1.5}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!hasHostAddAvailabilities}
                        data-testid="submit-availabilities">Freigeben</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={1.5}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="input-price">Preis/Nacht</InputLabel>
                        <OutlinedInput required
                                       id="input-price"
                                       name="price"
                                       disabled={!hasHostAddAvailabilities}
                                       inputProps={{"data-testid": "price"}}
                                       value={availability.price === 0 ? "0" : availability.price}
                                       onChange={(e) => setAvailability({...availability, price: +e.target.value})}
                                       startAdornment={<InputAdornment position="start">CHF</InputAdornment>}
                                       label="Grundpreis"
                        /> </FormControl>
                </Grid>

                {openDeleteAvailabilitiesDialog &&
                    <VltAlertDialog
                        open={openDeleteAvailabilitiesDialog}
                        content={popupContent}
                        handleClose={handleCloseVltAlertDialog}
                        handleAgree={() => deleteOnlyAvailableAvailabilities()}
                        textAgreeButton={agreeButtonText}
                        textDisagreeButton={"Abbrechen"}/>}
                {openEditAvailabilitiesDialog &&
                    <VltAlertDialog
                        open={openEditAvailabilitiesDialog}
                        content={popupContent}
                        handleClose={handleCloseVltAlertDialog}
                        handleAgree={() => editOnlyAvailableAvailabilities()}
                        textAgreeButton={agreeButtonText}
                        textDisagreeButton={"Abbrechen"}/>}

                <Dialog open={bookingDialogOpen} onClose={handleBookingDialogClose}>
                    <DialogTitle>Buchung erfassen</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <br />
                            <TextField
                                helperText={bookingComment === '' ? 'Bitte geben Sie einen Kommentar ein.' : ' '}
                                id="bookingText"
                                multiline
                                required
                                error={bookingComment === ''}
                                minRows={3}
                                label="Weitere Angaben zur Buchung"
                                value={bookingComment}
                                onChange={(e) => setBookingComment(e.target.value)}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                            <Button onClick={handleBookingSave} color="secondary" variant="contained">
                                {isSaving ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <span>Speichern</span>
                                    )}
                                    </Button>

                        <Button onClick={handleBookingDialogClose} color="primary">
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </form>
    );
}

export default AvailabilityForm;


