import React from 'react';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import RegionsPageHeader from '../../components/vanliferComponents/Regions/RegionPageHeader';
import RegionsListing from '../../components/vanliferComponents/Regions/RegionsListing';

const RegionsPage = (): JSX.Element => {
    return (
        <Main colorInvert={true}>
            <RegionsPageHeader />
               <Container>
                <RegionsListing />
            </Container>
        </Main>
    );
};

export default RegionsPage;
