/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import imgCalendar from '../../assets/Images/calendar.jpg';
import {Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material";

const HostApplication = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
    <Box position={'relative'}>
      <Grid container>
        <Grid item xs={12} sm={6} data-aos={'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              fontWeight={700}
            >
              Du vermietest schon Stellplätze an freundliche Camper?
            </Typography>
          </Box>
          <Box marginBottom={2}>
          <Typography variant="h6" color={'text.secondary'} data-aos={'fade-up'}>
            Dann haben wir genau das richtige für Dich
          </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="body1"
              component="p"
              color="text.primary"
            >
              Wir wollen Dir helfen, die Vermietung von Stellplätzen möglichst einfach zu machen – für einen Zusatzverdienst
              ohne viel Aufwand. Wir bieten Dir dazu unseren digitalen Stellplatzkalender an, der Dir von überall
              übersichtlich zur Seite steht. Du kannst darin unkompliziert Deine Stellplätze und deren Belegungen verwalten.
              Und zwar absolut kostenlos!
            </Typography>
          </Box>
          <Button
            component={Link}
            to={'/register/host'}
            variant="contained"
            color="primary"
            size="large"
            fullWidth={!isMd}
          >
            Werde Gastgeber
          </Button>
        </Grid>
      </Grid>
      <Box
        component={'img'}
        src={imgCalendar}
        sx={{
          maxWidth: '500px',
          height: 'auto',
          position: 'absolute',
          bottom: '30px',
          right: 0,
          display: { xs: 'none', sm: 'block' },
        }}
      />
    </Box>
  );
};

export default HostApplication;
