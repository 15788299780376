import { PaletteMode } from '@mui/material';

export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light' as PaletteMode,
  primary: {
    light: '#b2bcc2', // light gray
    main: '#1B2934', // dark gray
    dark: '#0f171e', // Olive
  },
  secondary: {
    light: '#eef2b6', // light yellow
    main: '#CCDA10', // VLT Green
    dark: '#b5c20f'
  },
  error: {
    light: '#FFCDD2', // light red
    main: '#F44336', // red
    dark: '#B71C1C', // dark red
  },
  warning: {
    light: '#FFEB3B', // yellow
    main: '#FFC107', // amber
    dark: '#FFA000', // dark orange
  },
  info: {
    light: '#BBDEFB', // light blue
    main: '#2196F3', // blue
    dark: '#1565C0', // dark blue
  },
  success: {
    light: '#C8E6C9', // light green
    main: '#4CAF50', // green
    dark: '#388E3C', // dark green
  },
  text: {
    primary: '#0f171e',
    secondary: '#677788',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
  },
};


export const dark = {
  alternate: {
    main: '#1B2934',
    dark: '#111a21',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark' as PaletteMode,
  primary: {
    light: '#1B2934', // light yellow
    main: '#CCDA10', // VLT Green
    dark: '#b5c20f'
  },
  secondary: {
    light: '#b2bcc2', // light gray
    main: '#1B2934', // dark gray
    dark: '#0f171e', // Olive

  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
  },
};
