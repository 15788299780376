import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    Address,
    Agent, ApiError,
    Compound,
    Host,
    Person,
    Subscription,
    SubscriptionEntitlement,
    User
} from "../../services/restserver-openapi";
import {
    deleteUser,
    getAsyncSubscriptions,
    getAsyncUserEntitlements,
    getSpecificationId,
    getUser, toggleUserVerification
} from "../../utils/User/user-openapi-hooks";
import {getAmountOfSites, getAsyncMaxAllowedSitesWithoutAddon, getCompounds} from "../../utils/Host/offer";

export type agentUserDetailState = {
    selectedUser: User;
    specificationId: number;
    selectedHost: {
        compounds: Compound[],
        maxAllowedSitesWithoutAddon: string,
        totalOwnedSites: number,
        isVerified: boolean,
    },
    selectedAgent: {
        isVerified: boolean,
    }
    subscriptions: Subscription[],
    userEntitlements: SubscriptionEntitlement[],
    status: 'idle' | 'pending';
    error: boolean;
};

export const defaultUser: User = {
    userId: 0,
    person: {
        personId: 0,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        birthday: "",
        gender: Person.gender.OTHER,
        address: {
            line1: "",
            line2: "",
            city: "",
            zipCode: 0,
            state: "",
            country: Address.country.CH,
        }
    },
    userRole: User.userRole.HOST,
}

const agentUserDetailInitialState: agentUserDetailState = {
    selectedUser: defaultUser,
    specificationId: 0,
    status: 'idle',
    selectedHost: {
        compounds: [],
        maxAllowedSitesWithoutAddon: "",
        totalOwnedSites: 0,
        isVerified: false,
    },
    selectedAgent: {
        isVerified: false,
    },
    subscriptions: [],
    userEntitlements: [],
    error: false
};

export const getAsyncSpecificationId = createAsyncThunk(
    'agentUserDetail/getSpecificationId',
    async (inputObject: { userId: number, userRoleInput: User.userRole }) => {
        return await getSpecificationId(inputObject.userId, inputObject.userRoleInput);
    }
);

export const getUserDetails = createAsyncThunk(
    'agentUserDetail/getUserDetails',
    async (inputObject: { userSpecificationId: number, userRoleInput: string }) => {
        return await getUser(inputObject.userSpecificationId, inputObject.userRoleInput);
    }
);

export const getCompoundsforSelectedHost = createAsyncThunk(
    'agentUserDetail/getCompoundsforSelectedHost',
    async (inputObject: { hostId: number }) => {
        return await getCompounds(inputObject.hostId);
    }
);

export const getSubscriptionsForSelectedUser = createAsyncThunk(
    'agentUserDetail/getSubscriptionsForSelectedUser',
    async (inputObject: { userId: number }) => {
        return await getAsyncSubscriptions(inputObject.userId);
    }
);

export const getEntitlementsForSelectedUser = createAsyncThunk(
    'agentUserDetail/getEntitlementsForSelectedUser',
    async (inputObject: { userId: number }) => {
        return await getAsyncUserEntitlements(inputObject.userId);
    }
);

export const getTotalOwnedSitesForSelectedHost = createAsyncThunk(
    'agentUserDetail/getTotalOwnedSitesForSelectedHost',
    async (inputObject: { hostId: number }) => {
        return await getAmountOfSites(inputObject.hostId);
    }
);

export const getMaxAllowedSitesWithoutAddonForSelectedHost = createAsyncThunk(
    'agentUserDetail/getMaxAllowedSitesWithoutAddonForSelectedHost',
    async (inputObject: { hostId: number }) => {
        return await getAsyncMaxAllowedSitesWithoutAddon(inputObject.hostId);
    }
);

export const updateSelectedUserVerified = createAsyncThunk(
    'agentUserDetail/updateSelectedHostVerified',
    async (inputObject: { specificationId: number, user: User, userRole: User.userRole, newVerifiedValue: boolean }) => {
        try {
            return await toggleUserVerification(inputObject.user, inputObject.specificationId, inputObject.userRole, inputObject.newVerifiedValue);
        } catch (error) {
            throw new Error();
        }
    }
);

export const deleteSelectedUser = createAsyncThunk(
    'agentUserDetail/deleteSelectedUser',
    async (inputObject: { specificationId: number, userRole: User.userRole }) => {
        try {
            return await deleteUser(inputObject.specificationId, inputObject.userRole);
        } catch (error) {
            if (error instanceof ApiError) {
                throw new Error(error.body.message);
            }
            throw new Error("Unknown error occurred.");
        }
    }
);

const resetState = (state: agentUserDetailState) => {
    state.selectedUser = defaultUser;
    state.specificationId = 0;
    state.selectedHost = {
        compounds: [],
        maxAllowedSitesWithoutAddon: "",
        totalOwnedSites: 0,
        isVerified: false,
    }
    state.selectedAgent = {
        isVerified: false,
    }
    state.subscriptions = [];
    state.userEntitlements = [];
    state.status = 'idle';
    state.error = false;
}

export const agentUserDetailSlice = createSlice({
    name: 'agentUserDetail',
    initialState: agentUserDetailInitialState,
    reducers: {
        resetAgentUserDetail: resetState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetails.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getUserDetails.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getUserDetails.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.selectedUser = payload?.user!;
                        state.selectedHost.isVerified = (payload as Host).verified || false;
                        state.selectedAgent.isVerified = (payload as Agent).verified || false;
                    }
                } catch (err) {
                    if (err === "Person not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(getAsyncSpecificationId.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getAsyncSpecificationId.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getAsyncSpecificationId.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.specificationId = payload;
                    }
                } catch (err) {
                    if (err === "SpecificationId not Found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(getCompoundsforSelectedHost.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getCompoundsforSelectedHost.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getCompoundsforSelectedHost.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.selectedHost.compounds = payload;
                    }
                } catch (err) {
                    if (err === "Compounds not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(getSubscriptionsForSelectedUser.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getSubscriptionsForSelectedUser.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getSubscriptionsForSelectedUser.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.subscriptions = payload;
                    }
                } catch (err) {
                    if (err === "Subscriptions not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(getEntitlementsForSelectedUser.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getEntitlementsForSelectedUser.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getEntitlementsForSelectedUser.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.userEntitlements = payload;
                    }
                } catch (err) {
                    if (err === "Entitlements not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(getTotalOwnedSitesForSelectedHost.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getTotalOwnedSitesForSelectedHost.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getTotalOwnedSitesForSelectedHost.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.selectedHost.totalOwnedSites = payload;
                    }
                } catch (err) {
                    if (err === "Total owned sites not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(getMaxAllowedSitesWithoutAddonForSelectedHost.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getMaxAllowedSitesWithoutAddonForSelectedHost.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getMaxAllowedSitesWithoutAddonForSelectedHost.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.selectedHost.maxAllowedSitesWithoutAddon = payload;
                    }
                } catch (err) {
                    if (err === "Max allowed sites without addon not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(updateSelectedUserVerified.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(updateSelectedUserVerified.pending, (state) => {
                state.status = "pending";
            })
            .addCase(updateSelectedUserVerified.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.selectedHost.isVerified = payload.verified!;
                    }
                } catch (err) {
                    if (err === "Host not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(deleteSelectedUser.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(deleteSelectedUser.pending, (state) => {
                state.status = "pending";
            })
            .addCase(deleteSelectedUser.fulfilled, resetState)
    },
});

export const { resetAgentUserDetail } = agentUserDetailSlice.actions;

export const agentUserDetailSliceReducer = agentUserDetailSlice.reducer;


