/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Vanlifer } from '../models/Vanlifer';
import type { VanliferErrorResponse } from '../models/VanliferErrorResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserVanliferService {
    /**
     * Get a vanlifer by id
     * @param vanliferId
     * @returns Vanlifer OK
     * @throws ApiError
     */
    public static getVanlifer(
        vanliferId: number,
    ): CancelablePromise<Vanlifer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/vanlifer/{vanliferId}',
            path: {
                'vanliferId': vanliferId,
            },
        });
    }
    /**
     * Update an existing vanlifer by id
     * @param vanliferId
     * @param requestBody
     * @returns Vanlifer OK
     * @throws ApiError
     */
    public static updateVanlifer(
        vanliferId: number,
        requestBody: Vanlifer,
    ): CancelablePromise<Vanlifer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/vanlifer/{vanliferId}',
            path: {
                'vanliferId': vanliferId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a vanlifer
     * @param vanliferId
     * @returns VanliferErrorResponse OK
     * @throws ApiError
     */
    public static deleteVanlifer(
        vanliferId: number,
    ): CancelablePromise<VanliferErrorResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/vanlifer/{vanliferId}',
            path: {
                'vanliferId': vanliferId,
            },
        });
    }
    /**
     * Create a new vanlifer user
     * @param requestBody
     * @returns Vanlifer OK
     * @throws ApiError
     */
    public static createVanliferUser(
        requestBody: Vanlifer,
    ): CancelablePromise<Vanlifer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/vanlifer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
