import {Navigate, Outlet, useLocation} from 'react-router-dom';
import { useAppSelector } from '../hooks/hooks';
import React, {useEffect, useState} from "react";

interface PrivateRouteProps {
    children?: React.ReactNode;
    redirectPath?: string;
    roles: string[];
}

function PrivateRoute({ roles, children, redirectPath="/login" }: PrivateRouteProps) {
    const isAuthenticated = useAppSelector(state => state.login.isAuthenticated);
    const userRole = useAppSelector(state => state.login.userRoleLogin);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [shouldStoreFromPath, setShouldStoreFromPath] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (!isAuthenticated) {
            setShouldStoreFromPath(true);
            setShouldRedirect(true);
        } else if (userRole === undefined || !roles.includes(userRole)) {
            setShouldStoreFromPath(false);
            setShouldRedirect(true);
        } else {
            setShouldRedirect(false);
        }
    }, [isAuthenticated, roles, userRole]);

    if (shouldRedirect) {
        if (shouldStoreFromPath) {
            return <Navigate to={redirectPath} replace state={{ from: location }} />;
        } else {
            return <Navigate to={redirectPath} replace />;
        }
    }

    return children ? children : <Outlet />;
}

export default PrivateRoute;