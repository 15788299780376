/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
import type { Subscription } from './Subscription';
export type UserOverviewDto = {
    userId?: number;
    chargebeeId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    address?: Address;
    subscription?: Subscription;
    userRole?: UserOverviewDto.userRole;
};
export namespace UserOverviewDto {
    export enum userRole {
        HOST = 'HOST',
        AGENT = 'AGENT',
        VANLIFER = 'VANLIFER',
    }
}

