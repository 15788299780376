import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import React, {useCallback, useEffect, useState} from "react";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import {Alert, useMediaQuery, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import {User} from "../../services/restserver-openapi";
import {
    setUserDataAfterRegistration,
    setUserIdFromStorage,
    setUserRoleLogin
} from "../../store/navBar/loginSlice";
import {
    createUserAsync,
    getPersonPreferencesAsync,
    getUserAsync, getUserEntitlementsAsync, getUserSubscriptionsAsync,
    postPersonPreferencesAsync
} from "../../store/navBar/userSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {Link, useNavigate} from "react-router-dom";
import {PreferencesModel} from "../../services/dataModels/PreferencesModel";
import RegisterGoogleLogin from "./RegisterGoogleLogin";
import RegisterPersonalInformationForm from "./RegisterPersonalInformationForm";
import RegisterAddressForm from "./RegisterAddressForm";
import RegisterAGBForm from "./registerAGBForm";
import {asyncCheckKudos} from "../../store/Vanlifer/Gamification/KudosSlice";

type StepType = {
    label: string;
    description: string;
    component: JSX.Element;
};


interface RegisterStepperProps {
    additionalSteps?: StepType[];
    localUserData: User;
    setLocalUserData: (value: User) => void;
    localPreferences: PreferencesModel;
    setLocalPreferences: (value: PreferencesModel) => void;
}

const RegisterStepper:React.FC<RegisterStepperProps> = ({ additionalSteps, localUserData, localPreferences, setLocalUserData, setLocalPreferences }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const kudosLastCheckedAt = useAppSelector(state => state.kudos.kudosLastCheckedAt);

    const [activeStep, setActiveStep] = useState(0);
    const [disableNext, setDisableNext] = React.useState<boolean>(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const handleNext = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleBack = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setDisableNext(false);
    };

    const handleStep = (step: any) => (): void => {
        setActiveStep(step);
        setDisableNext(false);
    };

    const defaultSteps = [
        {
            label: 'Google Login',
            description:
                'Damit wir wissen, dass Du ein echter Mensch bist, benötigen wir für die Registrierung einen Google Account. Falls Du noch keinen hast, kannst Du Dir hier einen erstellen. Zu einem späteren Zeitpunkt werden wir noch weitere Möglichkeiten anbieten.',
            component: <RegisterGoogleLogin disableNext={setDisableNext} handleNext={handleNext} localUserData={localUserData} setLocalUserData={setLocalUserData}/>,
        },
        {
            label: 'Persönliche Informationen',
            description: `Bitte fülle das Formular sorgfältig aus. Korrekte Namens- und Adressdaten sind wichtig, damit Reisen verlässlich geplant werden können.`,
            component:<RegisterPersonalInformationForm disableNext={setDisableNext} handleNext={handleNext} localUserData={localUserData} setLocalUserData={setLocalUserData}/>,
        },
        {
            label: 'Rechnungsadresse',
            description: `Falls Du Reisen buchst und Unterkünfte anbietest benötigen wir auch eine Rechnungsadresse von dir.`,
            component:<RegisterAddressForm disableNext={setDisableNext} handleNext={handleNext} localUserData={localUserData} setLocalUserData={setLocalUserData}/>
        },
        {
            label: 'AGB & Datenschutz',
            description: `Bitte lies und bestätige unsere AGBs und Datenschutzbestimmungen.`,
            component:<RegisterAGBForm disableNext={setDisableNext} handleNext={handleNext} localPreferences={localPreferences} setLocalPreferences={setLocalPreferences}/>,
        }
    ];

    if (additionalSteps === undefined) {
        additionalSteps = [];
    }
    const steps = [...additionalSteps, ...defaultSteps]

    const navigateToDashboard = useCallback(() => {
        navigate("/");
    }, [navigate]);

    const saveUser = useCallback(async () =>{
        dispatch(createUserAsync(localUserData)).then(async (response) => {
            if (createUserAsync.fulfilled.match(response)) {
                if (response.payload) {
                    let returnedUser = response.payload.user
                    let returnedUserSpecificationId = response.payload.specificationId
                    if (returnedUser.userId && returnedUser.person?.personId) {
                        dispatch(setUserIdFromStorage({userId: returnedUser.userId}));
                        dispatch(setUserRoleLogin({startUserRole: returnedUser.userRole}));
                        dispatch(postPersonPreferencesAsync({
                            userId: returnedUser.userId,
                            preferences: localPreferences
                        }))

                        dispatch(setUserDataAfterRegistration({
                            userSpecificationId: returnedUserSpecificationId,
                            userRole: returnedUser.userRole,
                            userId: returnedUser.userId,
                        }));
                        await dispatch(getUserAsync({userSpecificationId: returnedUserSpecificationId, userRoleInput: returnedUser.userRole})).then(async (response) => {
                            if (getUserAsync.fulfilled.match(response)) {
                                if (response.payload && response.payload.user?.userId) {
                                    dispatch(getPersonPreferencesAsync(response.payload.user.userId));
                                    dispatch(getUserEntitlementsAsync());
                                    dispatch(getUserSubscriptionsAsync());
                                }
                            }
                        });
                        if (returnedUser.userRole === User.userRole.VANLIFER) {
                            dispatch(asyncCheckKudos({checkKudosSinceDate: kudosLastCheckedAt}));
                        }
                    }
                    navigateToDashboard();
                } else {
                    console.error("Error creating user")
                    setShowErrorMessage(true);
                }
            }
        });
    }, [dispatch, kudosLastCheckedAt, localPreferences, localUserData, navigateToDashboard]);

    useEffect(() => {
        if (activeStep === steps.length) {
            saveUser();
        }
    }, [activeStep, saveUser, steps.length]);

    return (
<Box width={1}>
    <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
            <Step
                key={step.label}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    width: '100%',
                    '& .MuiButtonBase-root': {
                        position: 'relative',
                        bgcolor:
                            activeStep === index ? 'primary.main' : 'alternate.main',
                        color: activeStep === index ? 'text.primary' : 'common.white',
                        height: theme.spacing(6),
                        padding: theme.spacing(0, 3),
                        zIndex: 1,
                    },
                    '& .MuiStepLabel-label.Mui-active': {
                        color: theme.palette.common.white,
                    },
                    '& .MuiSvgIcon-root.Mui-active': {
                        color: theme.palette.common.white,
                        '& .MuiStepIcon-text': {
                            fill: theme.palette.primary.main,
                        },
                    },
                }}
            >
                <React.Fragment>
                    <StepButton onClick={handleStep(index)}>
                        {isMd ? step.label : ''}
                    </StepButton>
                    {index === steps.length - 1 ? null : (
                        <Box
                            sx={{
                                width: 0,
                                height: 0,
                                borderTop: `${theme.spacing(3)} solid transparent`,
                                borderBottom: `${theme.spacing(3)} solid transparent`,
                                borderLeft: `${theme.spacing(2)} solid ${
                                    activeStep === index
                                        ? theme.palette.primary.main
                                        : theme.palette.alternate.main
                                }`,
                                transform: `translateX(${theme.spacing(0)})`,
                                zIndex: 2,
                            }}
                        />
                    )}
                </React.Fragment>
            </Step>
        ))}
    </Stepper>
    <div>
        {activeStep === steps.length ? (
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    Du hast Dich erfolgreich registriert und wirst in Kürze weitergeleitet.
                </Typography>
                {showErrorMessage &&
                    <Alert severity="error">
                        Hups! Irgendetwas ist schief gegangen. Versuche es später erneut.
                    </Alert>
                }

            </React.Fragment>
        ) : (
            <React.Fragment>
                <Box sx={{ mt: 2, mb: 1, p: 2 }}>
                    <Typography variant={'h5'} component={"h2"}>{steps[activeStep].label}</Typography>
                    <Typography>{steps[activeStep].description}</Typography>
                </Box>
                <Box marginBottom={2} padding={2}>
                    {steps[activeStep].component}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {activeStep !== 0 && (
                        <Button
                            color="primary"
                            variant={'outlined'}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Zurück
                        </Button>
                    )}
                    {activeStep === 0 && (
                        <Button
                            color="primary"
                            component={Link}
                            to={"/login"}
                            variant={'outlined'}
                            sx={{ mr: 1 }}
                        >
                            Zurück
                        </Button>

                    )}
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                        variant={'contained'}
                        onClick={handleNext}
                        disabled={disableNext}
                        sx={{ mr: 1 }}
                    >
                        {activeStep === steps.length - 1 ? 'Registrierung abschliessen' : 'Weiter'}
                    </Button>
                </Box>
            </React.Fragment>
        )}
    </div>
</Box>
);
}
export default RegisterStepper;