import {Compound, OfferImageService, Site} from "../../services/restserver-openapi";
import Compressor from "compressorjs";
import React from "react";

export interface ImageResizeOptions {
    maxWidth: number;
    maxHeight: number;
    quality: number;
}

export const handleImageResize = async (file: File, maxSizeInBytes = 1000000, retryFactor = 0.8): Promise<Blob | null> => {
    return new Promise((resolve) => {
        if (file.size <= maxSizeInBytes) {
            resolve(file);
            return;
        }

        const compressWithOptions = (options: ImageResizeOptions) => {
            new Compressor(file, {
                ...options,
                success(result) {
                    if (result.size < maxSizeInBytes) {
                        resolve(result);
                    } else {
                        const newOptions = {
                            maxWidth: options.maxWidth * retryFactor,
                            maxHeight: options.maxHeight * retryFactor,
                            quality: options.quality * retryFactor,
                        };

                        if (newOptions.quality > 0) {
                            compressWithOptions(newOptions);
                        } else {
                            console.error('Image compression failed');
                            resolve(null);
                        }
                    }
                },
                error(err) {
                    console.error('Error during image compression:', err);
                    resolve(null);
                },
            });
        };

        compressWithOptions({
            maxWidth: 1920,
            maxHeight: 1920,
            quality: 0.8,
        });
    });
};

export const fetchExistingImages = async (
    imageIds: number[],
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>,
    selectedFiles: File[],
) => {
    try {
        const existingImages = await Promise.all(
            imageIds.map(async (imageId) => {
                const imageUrl = `${process.env.REACT_APP_API_URL as string}/image/${imageId}`;
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                return new File([blob], `existing_image_${imageId}`, {type: blob.type});
            })
        );

        setSelectedFiles([...existingImages, ...selectedFiles]);
    } catch (error) {
    }
};

export const handleUpdateImageChange = (
    e: any,
    files: string[],
    setFiles: React.Dispatch<React.SetStateAction<string[]>>,
    selectedFiles: File[],
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>
) => {
    const images = e.target.files;
    let imagesSrc: string[] = [...files]; // Copy the existing selected files
    let filesSrc: File[] = [...selectedFiles];

    for (let i = 0; i < images.length; i++) {
        const newImageUrl = URL.createObjectURL(images[i]);
        imagesSrc.push(newImageUrl);
        filesSrc.push(images[i]);
    }

    setSelectedFiles(filesSrc);
    setFiles(imagesSrc);
};

async function prepareFilesForUpload(selectedFiles: File[]) {
    const newFiles = selectedFiles.filter((file) => !file.name.startsWith('existing_image_'));
    const resizedFiles = await Promise.all(
        newFiles.map(async (file) => {
            const resizedImage = await handleImageResize(file);
            return resizedImage ? new File([resizedImage], file.name, {type: resizedImage.type}) : null;
        })
    );

    return resizedFiles.filter((file) => file !== null) as File[];
}

export const handleImageUploadSite = async (
    site: Site,
    selectedFiles: File[],
) => {
    const validResizedFiles = await prepareFilesForUpload(selectedFiles)
    for (const file of validResizedFiles) {
        const formData = new FormData();
        formData.append('image', file, file.name);

        if (site.siteId === undefined) {
            return;
        }
        try {
            const imageObject = { image: file.slice(0, file.size, file.type) };
            await OfferImageService.addSiteImage(site.siteId, imageObject);
        } catch (error) {
            console.error(`Error uploading image to server: ${error}`);
        }
    }
};

export const handleImageUploadCompound = async (
    compound: Compound,
    selectedFiles: File[],
) => {
    const validResizedFiles = await prepareFilesForUpload(selectedFiles);


    for (const file of validResizedFiles) {
        const formData = new FormData();
        formData.append('image', file, file.name);

        if (compound.compoundId === undefined) {
            return;
        }
        try {
            const imageObject = { image: file.slice(0, file.size, file.type) };
            await OfferImageService.addCompoundImage(compound.compoundId, imageObject);
        } catch (error) {
            console.error(`Error uploading image to server: ${error}`);
        }
    }
};

export const handleImageDelete = (
    index: number,
    files: string[],
    setFiles: React.Dispatch<React.SetStateAction<string[]>>,
    selectedFiles: File[],
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>,
) => {
    const updatedFiles = [...files];
    const updatedSelectedFiles = [...selectedFiles];

    const fileNameToDelete = updatedSelectedFiles[index].name;

    if (fileNameToDelete.startsWith('existing_image_')) {
        const imageIdToDelete = Number(fileNameToDelete.replace(/^existing_image_/, ''));
        OfferImageService.deleteImage(imageIdToDelete)
            .then(() => {
                updatedFiles.splice(index, 1);
                updatedSelectedFiles.splice(index, 1);

                setFiles(updatedFiles);
                setSelectedFiles(updatedSelectedFiles);
            })
            .catch((error: any) => {
                console.error(`Error deleting image with ID ${imageIdToDelete} from server: ${error}`);
            });
    } else {
        updatedFiles.splice(index, 1);
        updatedSelectedFiles.splice(index, 1);

        setFiles(updatedFiles);
        setSelectedFiles(updatedSelectedFiles);
    }
};
