import React from 'react';
import {DatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

type DatePickerProp = {
    handleChange: (newValue: Moment | null) => void;
    value: Moment | null;
    label: string;
    name: string;
    minDate?: Moment | null;
    maxDate?: Moment | null;
}

export default function DatePickerVanlife({handleChange, value, name, label, minDate, maxDate}: DatePickerProp) {
    return (
        <LocalizationProvider adapterLocale="de" dateAdapter={AdapterMoment}>
        <DatePicker
            label={label}
            name={name}
            value={value ? moment(value) : null}
            onChange={handleChange}
            minDate={minDate ?? undefined}
            maxDate={maxDate ?? undefined}
            slotProps={{ textField: { fullWidth: true } }}
        />
        </LocalizationProvider>
    );
}
