
type ErrorHandlingProp = {
    errorMessage: String | null;
}

export function mapErrorMessages({errorMessage}:ErrorHandlingProp) {
    let translatedErrorMessage:string;
    if (errorMessage){
        switch (errorMessage){
            case 'Failed to fetch': translatedErrorMessage = 'Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.'; break;
            case 'Not found': translatedErrorMessage = 'Seite nicht gefunden.'; break;
            case 'No permission': translatedErrorMessage='Zugriff verboten'; break;
            case 'Invalid Id supplied': translatedErrorMessage="Etwas mit der Anfrage ist schiefgelaufen."; break;
            case 'Query' : translatedErrorMessage="Etwas mit der Anfrage ist schiefgelaufen."; break;
            case 'Conflict: Some availabilities are already reserved': translatedErrorMessage="Leider wurden einige Verfügbarkeiten in der Zwischenzeit von einem anderen Vanlifer reserviert. Bitte wähle eine andere Tour"; break;
            case 'No Content': translatedErrorMessage="Kein Inhalt"; break;
            default: translatedErrorMessage = errorMessage?.toString(); break;
        }
    }
    else {
        translatedErrorMessage = 'Fehler'
    }
    return translatedErrorMessage;
}
