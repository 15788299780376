import React, {useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

import Container from '../../Container';
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {fetchAllRegions} from "../../../store/Vanlifer/regionSlice";
import ImageIcon from "@mui/icons-material/Image";
import {createGeneralStyling} from "../../../theme/generalStyling";
import {Link} from "react-router-dom";

const RegionsListing = (): JSX.Element => {
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);

    const dispatch = useAppDispatch();
    const regions = useAppSelector(state => state.region.regions)

    useEffect(() => {
        dispatch(fetchAllRegions());
    }, [dispatch]);

    function trimDescription(text : string) {
        const charCount = 100;
        return text.length > charCount ? text.substring(0, charCount) + "..." : text;
    }
    return (
        <Container>
            <Box>
                <Grid container spacing={4}>
                    {regions.map((region, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box
                                component={Link}
                                to={`/vanlifer/regions/${region.regionId}`}
                                display={'block'}
                                width={1}
                                height={1}
                                sx={{
                                    textDecoration: 'none',
                                    transition: 'all .2s ease-in-out',
                                    '&:hover': {
                                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                                    },
                                }}
                            >
                                <Box
                                    component={Card}
                                    width={1}
                                    height={1}
                                    boxShadow={0}
                                    sx={{ bgcolor: 'transparent', backgroundImage: 'none' }}
                                >
                                    <CardMedia
                                        title={region.name}
                                        sx={{
                                            height: { xs: 300, md: 360 },
                                            position: 'relative',
                                            filter:
                                                theme.palette.mode === 'dark'
                                                    ? 'brightness(0.7)'
                                                    : 'none',
                                        }}
                                    >

                                        {
                                            region.imageIds && region.imageIds.length === 0 ? (
                                                <Box height="100%" sx={generalStyling.tourProposalCard.missingImage}>
                                                    <ImageIcon fontSize="large" color="disabled"/>
                                                    <Typography component={"article"} variant="body1" sx={{textAlign: 'center'}}>Kein Bild
                                                        vorhanden.</Typography>
                                                </Box>
                                            ) : (
                                                <img src={`${process.env.REACT_APP_API_URL as string}/image/${region.imageIds![0]}`}
                                                     alt={`Bild von der Region ${region.name}`}
                                                     style={{
                                                         width: '100%',
                                                         height: '100%',
                                                         objectFit: 'cover'
                                                     }}
                                                />
                                            )
                                        }
                                    </CardMedia>
                                    <Box
                                        width={'90%'}
                                        margin={'0 auto'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        boxShadow={3}
                                        bgcolor={'background.paper'}
                                        position={'relative'}
                                        zIndex={3}
                                        sx={{ transform: 'translateY(-30px)' }}
                                    >
                                        <Box component={CardContent} position={'relative'}>
                                            <Typography variant={'h6'} gutterBottom>
                                                {region.name}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                {trimDescription(region.description ? region.description : "")}
                                            </Typography>
                                        </Box>
                                        <Box flexGrow={1} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default RegionsListing;
