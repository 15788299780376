import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

export function TitleWithIcon({icon, title}: { icon: React.ReactNode, title: string }): React.ReactElement {
    return (
        <Typography variant="h5" sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{mr: 1, display: 'flex', alignItems: 'center'}} >{icon}</Box>
            {title}
        </Typography>
    );
}
