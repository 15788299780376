/* eslint-disable react/no-unescaped-entities */
import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Grid, MenuItem, TextField} from '@mui/material';
import {Address, User} from "../../services/restserver-openapi";
import {countryCodes, countryCodes as countries} from "../../constants/country_code";


interface RegisterAddressInformationFormProps {
    disableNext: (value: boolean) => void;
    handleNext: () => void;
    localUserData: User;
    setLocalUserData: (value: User) => void;
}

const profileStyling = {
    textfield: {
        width: '100%',
    },
};

const RegisterAddressInformationForm:React.FC<RegisterAddressInformationFormProps> = ({ disableNext, handleNext, localUserData, setLocalUserData }) => {
    const [localCountrySelected, setLocalCountrySelected] = useState<string>(
        countryCodes.find((country) => country.countryCode === localUserData.person?.address?.country)?.countryName || 'Schweiz'
    );

    const handleAddressChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (localUserData.person && localUserData.person.address) {
            setLocalUserData({
                ...localUserData,
                person: {
                    ...localUserData.person,
                    address: { ...localUserData.person.address, [property]: event.target.value }
                }
            });
        }
    };

    function getCountryEnumFromString(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let inputCountryEnum = Address.country.MN;
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].countryName === event.target.value) {
                inputCountryEnum = countries[i].countryCode as Address.country;
            }
        }
        return inputCountryEnum;
    }

    const validateForm = useCallback(() => {
        const address = localUserData.person?.address;
        return (address?.line1 || '') !== '' &&
            (address?.city || '') !== '' &&
            (address?.country || '') !== '' &&
            (address?.zipCode || 0) !== 0;
    }, [localUserData]);

// Modify your useEffect hook like this
    useEffect(() => {
        disableNext(!validateForm());
    }, [disableNext, localUserData, validateForm]);



    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Firma"
                        margin="dense"
                        sx={profileStyling.textfield}
                        onChange={handleAddressChange('company')}
                        value={localUserData.person?.address?.company}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Adresse Zeile 1"
                        required
                        margin="dense"
                        sx={profileStyling.textfield}
                        onChange={handleAddressChange('line1')}
                        value={localUserData.person?.address?.line1}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Adresse Zeile 2"
                        margin="dense"
                        sx={profileStyling.textfield}
                        onChange={handleAddressChange('line2')}
                        value={localUserData.person?.address?.line2}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={3}>
                    <TextField
                        label="PLZ"
                        margin="dense"
                        required
                        sx={profileStyling.textfield}
                        onChange={handleAddressChange('zipCode')}
                        value={localUserData.person?.address?.zipCode}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={5}>
                    <TextField
                        label="Wohnort"
                        margin="dense"
                        required
                        sx={profileStyling.textfield}
                        onChange={handleAddressChange('city')}
                        value={localUserData.person?.address?.city}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <TextField
                        label="Kanton/Bundesland/Staat"
                        margin="dense"
                        sx={profileStyling.textfield}
                        onChange={handleAddressChange('state')}
                        value={localUserData.person?.address?.state}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={12} md={12} xs={12}>
                    <TextField select id="select-country"
                               margin="dense"
                               sx={profileStyling.textfield}
                               label="Land" value={localCountrySelected}
                               onChange={(event) => {
                                   if (localUserData.person) {
                                       setLocalCountrySelected(event.target.value);
                                       setLocalUserData({
                                           ...localUserData,
                                           person: {
                                               ...localUserData.person,
                                               address: {
                                                   ...localUserData.person.address,
                                                   country: getCountryEnumFromString(event)
                                               }
                                           }
                                       });
                                   }

                               }} required>
                        {countries.map((country) => (
                            <MenuItem key={country.countryCode} value={country.countryName}>
                                {country.countryName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Box>

    );
};

export default RegisterAddressInformationForm;