import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User, UserOverviewDto, UserOverviewPage} from "../../services/restserver-openapi";
import {getAllUsers} from "../../utils/User/user-openapi-hooks";

interface UserOverviewState {
    tabNumber: number;
    userOverview: UserOverviewDto[];
    hasNextPage: boolean;
    nextPageNumber: number;
    totalEntries: number;
    pageSize: number;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: UserOverviewState = {
    tabNumber: 0,
    userOverview: [],
    hasNextPage: false,
    nextPageNumber: 0,
    totalEntries: 0,
    pageSize: 10,
    status: 'idle',
};

export const getUserOverviewAsync = createAsyncThunk(
    'userOverview/getUserOverview',
    async (params: { userRole: User.userRole, filter: string, pageNumber: number, pageSize: number, direction: 'ASC' | 'DESC', orderBy: string }) => {
        return getAllUsers(params.userRole, params.filter, params.pageNumber, params.pageSize, params.direction, params.orderBy);
    }
);

export const userOverviewSlice = createSlice({
    name: 'userOverview',
    initialState,
    reducers: {
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setTabNumber: (state, action: PayloadAction<number>) => {
            state.tabNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserOverviewAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserOverviewAsync.fulfilled, (state, action: PayloadAction<UserOverviewPage>) => {
                state.status = 'idle';
                state.userOverview = action.payload.userOverviewDTOs!;
                state.hasNextPage = action.payload.hasNextPage!;
                state.nextPageNumber = action.payload.nextPageNumber!;
                state.totalEntries = action.payload.totalEntries!;
            })
            .addCase(getUserOverviewAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const {setPageSize, setTabNumber} = userOverviewSlice.actions;

export const userOverviewSliceReducer = userOverviewSlice.reducer;