import { Theme, responsiveFontSizes } from '@mui/material';
import { createTheme, ComponentsOverrides } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';

const getTheme = (mode: string, themeToggler: () => void): Theme =>
  responsiveFontSizes(
    createTheme({
      palette: mode === 'light' ? light : dark,
      shadows: shadows(mode),
      typography: {
        h1: {
          fontFamily: 'bubblegum-sans',
          fontSize: 15,
          fontWeight: 500
        },
        h2: {
          fontFamily: 'bubblegum-sans',
        },
        h3: {
          fontFamily: 'bubblegum-sans',
        },
        h4: {
          fontFamily: 'bubblegum-sans',
        },
        h5: {
          fontFamily: 'bubblegum-sans',
        },
        h6: {
          fontFamily: 'bubblegum-sans',
        },
        body1: {
          fontFamily: 'Helvetica',
        },
        allVariants: {
          fontFamily: 'Helvetica'
        },
        button: {
          textTransform: 'none',
          fontWeight: 'medium' as React.CSSProperties['fontWeight'],
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              margin: 'normal',
            },
          } as ComponentsOverrides['MuiTextField'],
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            containedSecondary: mode === 'light' ? { color: '#1B2934' } : {},
          } as ComponentsOverrides['MuiButton'],
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          } as ComponentsOverrides['MuiInputBase'],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          } as ComponentsOverrides['MuiOutlinedInput'],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          } as ComponentsOverrides['MuiCard'],
        },
      },
      themeToggler,
    }),
  );

export default getTheme;
