/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Availability } from '../models/Availability';
import type { AvailabilityDetail } from '../models/AvailabilityDetail';
import type { StayBookingDetail } from '../models/StayBookingDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OfferAvailabilityService {
    /**
     * Get all availabilities
     * @param offerId
     * @returns AvailabilityDetail OK
     * @throws ApiError
     */
    public static getAvailabilities(
        offerId?: number,
    ): CancelablePromise<Array<AvailabilityDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/availabilities',
            query: {
                'offerId': offerId,
            },
        });
    }
    /**
     * Update existing availabilities by id.
     * @param requestBody
     * @returns AvailabilityDetail OK
     * @throws ApiError
     */
    public static updateAvailabilities(
        requestBody: Array<Availability>,
    ): CancelablePromise<Array<AvailabilityDetail>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/availabilities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add new availabilities without Ids.
     * @param requestBody
     * @returns AvailabilityDetail OK
     * @throws ApiError
     */
    public static addAvailabilities(
        requestBody: Array<Availability>,
    ): CancelablePromise<Array<AvailabilityDetail>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/availabilities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete availabilities by id. Has a strong dependency to CALENDARSERVICEAVAILABLE.
     * @param requestBody
     * @returns Availability OK
     * @throws ApiError
     */
    public static deleteAvailabilities(
        requestBody: Array<number>,
    ): CancelablePromise<Availability> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/availabilities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get an availability by id
     * @param id
     * @returns AvailabilityDetail OK
     * @throws ApiError
     */
    public static getAvailability(
        id: number,
    ): CancelablePromise<AvailabilityDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/availabilities/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Delete an availability by id. Has a strong dependency to CALENDARSERVICEAVAILABLE.
     * @param id
     * @returns Availability OK
     * @throws ApiError
     */
    public static deleteAvailability(
        id: number,
    ): CancelablePromise<Availability> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/availabilities/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get all booked availabilities by host within a date range
     * @param id
     * @param fromTimestamp The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @param toTimestamp The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @returns StayBookingDetail OK
     * @throws ApiError
     */
    public static getAvailabilitiesBookedByHost(
        id: number,
        fromTimestamp: number,
        toTimestamp: number,
    ): CancelablePromise<Array<StayBookingDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/availabilities/bookedByHost/{id}',
            path: {
                'id': id,
            },
            query: {
                'fromTimestamp': fromTimestamp,
                'toTimestamp': toTimestamp,
            },
        });
    }
}
