import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmbedReviewComponent from './EmbedReviewComponent';


const Testimonials = (): JSX.Element => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
            color: 'text.primary',
          }}
        >
          Unsere Vanlifer sind unsere grössten Fans
        </Typography>
        <Typography
          variant="body1"
          align={'center'}
          sx={{ color: 'text.primary' }}
        >
          Hier sind original Feedbacks aus unserer Vanlife-Community
        </Typography>
      </Box>
      <EmbedReviewComponent />
    </Box>
  );
};

export default Testimonials;
