import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getSizes} from "../../../utils/Host/offer";
import {Size} from "../../../services/restserver-openapi";

export  type SizesState = {
    sizes: Size[]
}

const initialState: SizesState = {
    sizes: [],
}

export const fetchAllSizes = createAsyncThunk(
    'site/fetchAllSizes',
    async () => {
        const response = await getSizes()
        return response
    })

export const sizesSlice = createSlice({
    name: 'sizes',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder
            .addCase(fetchAllSizes.fulfilled, (state, {payload}) => {
                state.sizes = payload;
            })
    })
})

export const sizesReducer = sizesSlice.reducer;