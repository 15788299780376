import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import {User} from "../../services/restserver-openapi";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {getCompoundsforSelectedHost, updateSelectedUserVerified} from "../../store/Agent/agentUserDetailSlice";
import {Box, Button, Grid} from "@mui/material";
import CompoundsListing from "../presentationalComponents/CompoundsListing";
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon from '@mui/icons-material/GppGood';

interface HostDetailsProps {
    user: User;
    hostId: number;
    totalSites: number;
    isVerified: boolean;
}

const HostDetails: React.FC<HostDetailsProps> = ({user, hostId, totalSites, isVerified}) => {
    const dispatch = useAppDispatch();
    const compounds = useAppSelector(state => state.agentUserDetail.selectedHost.compounds);
    useEffect(() => {
        dispatch(getCompoundsforSelectedHost({hostId}));
    }, [dispatch, hostId]);

    const handleLockHost = () => {
        dispatch(updateSelectedUserVerified({specificationId: hostId, user, userRole: User.userRole.HOST, newVerifiedValue: !isVerified}));
    }
    return (
        <Box>
            <Typography variant="h4" component="h2">
                Host Details
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontWeight="bold">Total Campingplätze (Inkl. Stv.):</Typography>
                            <Typography variant="body1" fontWeight="bold">Total Stellplätze:</Typography>
                            <Typography variant="body1" fontWeight="bold">Verifiziert?:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">{compounds.length}</Typography>
                            <Typography variant="body1">{totalSites}</Typography>
                            <Typography variant="body1">{isVerified ? 'Ja' : 'Nein'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={isVerified ? <GppGoodIcon /> : <GppBadIcon />} onClick={handleLockHost}>
                        {isVerified ? 'Host ist verifiziert und kann Subscription ändern' : 'Host kann Subscription nicht ändern'}
                    </Button>
                </Grid>
            </Grid>
            <Box>
                <CompoundsListing compounds={compounds} title="Alle Campingplätze des Hosts" hostId={hostId}/>
            </Box>
        </Box>
    );
};

export default HostDetails;