import React from 'react';
import {Compound} from "../../services/restserver-openapi";
import {Badge, BadgeProps, Box, Grid, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import ImageIcon from "@mui/icons-material/Image";
import CardContent from "@mui/material/CardContent";
import {createGeneralStyling} from "../../theme/generalStyling";
import {styled} from "@mui/system";

type CompoundsListingProp = {
    compounds: Compound[];
    title: string;
    hostId?: number;
}

const CompoundsListing = ({compounds, title, hostId}: CompoundsListingProp) => {
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 'auto',
            left: 'auto',
            top: -15,
        },
    }));
    return (
        <Box>
            <Typography variant="h5" component="h2" gutterBottom>{title}</Typography>
            <Grid container spacing={4}>
                {compounds.map((compound, i) => (
                    <Grid item xs={12} md={3} key={i}>
                        <Box
                            component={Link}
                            to={`/vanlifer/Campsite/${compound.compoundId}`}
                            display={'block'}
                            width={1}
                            height={1}
                            sx={{
                                textDecoration: 'none',
                                transition: 'all .2s ease-in-out',
                                '&:hover': {
                                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                                },
                            }}
                        >
                            <Box
                                component={Card}
                                width={1}
                                height={1}
                                boxShadow={0}
                                sx={{bgcolor: 'transparent', backgroundImage: 'none'}}
                            >
                                <CardMedia
                                    title={compound.name}
                                    sx={{
                                        height: {xs: 200, md: 260},
                                        position: 'relative',
                                        filter:
                                            theme.palette.mode === 'dark'
                                                ? 'brightness(0.7)'
                                                : 'none',
                                    }}
                                >

                                    {
                                        compound.imageIds && compound.imageIds.length === 0 ? (
                                            <Box height="100%" sx={generalStyling.tourProposalCard.missingImage}>
                                                <ImageIcon fontSize="large" color="disabled"/>
                                                <Typography component={"article"} variant="body1"
                                                            sx={{textAlign: 'center'}}>Kein Bild
                                                    vorhanden.</Typography>
                                            </Box>
                                        ) : (
                                            <img
                                                src={`${process.env.REACT_APP_API_URL as string}/image/${compound.imageIds![0]}`}
                                                alt={`Bild vom ${compound.name}`}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        )
                                    }
                                </CardMedia>
                                <Box
                                    width={'90%'}
                                    margin={'0 auto'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    boxShadow={3}
                                    bgcolor={'background.paper'}
                                    position={'relative'}
                                    zIndex={3}
                                    sx={{transform: 'translateY(-30px)'}}
                                >
                                    <Box component={CardContent} position={'relative'}>
                                        <Typography variant={'h6'} gutterBottom>
                                        {hostId && compound.hostId !== hostId ? (
                                            <StyledBadge color={theme.palette.mode === 'light' ? 'secondary' : 'primary'} badgeContent="Stellvertretung">
                                                {compound.name}
                                            </StyledBadge>
                                        ) : (
                                            compound.name
                                        )}
                                        </Typography>
                                        <Typography color="text.secondary">
                                            {compound.city}, {compound.country}
                                        </Typography>
                                    </Box>
                                    <Box flexGrow={1}/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

}

export default CompoundsListing;