/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TokenAuthorization = {
    userId?: number;
    identityToken?: string;
    userRole?: TokenAuthorization.userRole;
    userSpecificationId?: number;
};
export namespace TokenAuthorization {
    export enum userRole {
        HOST = 'HOST',
        AGENT = 'AGENT',
        VANLIFER = 'VANLIFER',
    }
}

