// upcomingStaysSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getStaysBetween} from "../../../utils/Vanlifer/tour";
import {getAvailabilitiesBookedByHost} from "../../../utils/Host/offer";
import {StayBookingDetail} from "../../../services/restserver-openapi/models/StayBookingDetail";

export type upcomingStaysState = {
    upcomingStays: StayBookingDetail[],
    loading: false | true,
    error: string | null,
}

const initialState : upcomingStaysState = {
    upcomingStays: [],
    loading: false,
    error: null,
};

export const fetchUpcomingStays = createAsyncThunk('upcomingStays/fetchUpcomingStays', async (hostId: number) => {
    try {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const toDate = new Date(currentDate);
        toDate.setDate(currentDate.getDate() + 14);
        toDate.setHours(23, 59, 59, 999);

        const stays = await getStaysBetween(currentDate.getTime(), toDate.getTime(), hostId);
        const availabilities = await getAvailabilitiesBookedByHost(hostId, currentDate.getTime(), toDate.getTime());

        return { upcomingStays: [...stays, ...availabilities] };
    } catch (error) {
        throw new Error('Error fetching upcoming stays');
    }
});

export const upcomingStaysSlice = createSlice({
    name: 'upcomingStays',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpcomingStays.fulfilled, (state, action) => {
                state.upcomingStays = action.payload.upcomingStays;
                state.loading = false;
            })
            .addCase(fetchUpcomingStays.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchUpcomingStays.rejected, (state, action) => {
                state.loading = false;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            });
    },
});

export const upcomingStaysReducer =  upcomingStaysSlice.reducer;
