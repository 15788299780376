import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Site} from "../../../services/restserver-openapi";
import {addSite, deleteSite, getAmountOfSites, getSites, updateSite, getAsyncMaxAllowedSitesWithoutAddon} from "../../../utils/Host/offer";
import {mapErrorMessages} from '../../../utils/errorHandling'

let errorMessage: string | null;
export type SiteState = {
    loading: boolean
    error: string | null
    sites: Site[],
    site: Site,
    totalOwnedSites: number, // The total number of sites owned by the host
    maxAllowedSitesWithoutAddon: string,
    failed: boolean
}

export const defaultSite: Site = {
    offer: {
        hostId: 0,
        name: "",
        description: ""
    },
    label: "",
    electricity: false,
    water: false,
    guestInformation: "",
    imageIds: []
}

const initialState: SiteState = {
    site: defaultSite,
    sites: [],
    totalOwnedSites: 0,
    maxAllowedSitesWithoutAddon: "",
    loading: true,
    error: null,
    failed: false
}

export const getTotalOwnedSites = createAsyncThunk(
    'site/getTotalOwnedSites',
    async () => {
        return getAmountOfSites();
    }

)

export const getMaxAllowedSitesWithoutAddon = createAsyncThunk(
    'site/getMaxAllowedSitesWithoutAddon',
    async () => {
        return getAsyncMaxAllowedSitesWithoutAddon();
    }
)

export const fetchAllSites = createAsyncThunk(
    'site/fetchAllSites',
    async (compoundId: number ) => {
        return getSites(compoundId);
    }
)

export const createSite = createAsyncThunk(
    'site/createSite',
    async (site: Site) => {
        return addSite(site);
    }
)

export const editSite = createAsyncThunk(
    'site/updateSite',
    async (site: Site) => {
        return updateSite(site);
    }
)

export const deleteSelectedSite = createAsyncThunk(
    'sire/deleteSite',
    async (siteId: number) => {
        return deleteSite(siteId);
    }
)


export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setSite: (state, {payload}: PayloadAction<Site>) => {
            state.site = payload
        },
        resetSiteErrorState: (state) => {
            state.error = null
        },
        deleteSiteState: (state) => {
            state.site = defaultSite;
            state.sites = [];
            state.loading = true;
            state.error = null;
            state.failed = false;
        },
        resetErrorStateSites: (state)=>{
            state.error=null;
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(fetchAllSites.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(fetchAllSites.fulfilled, (state, {payload}) => {
                state.sites = payload
                state.loading = false
            })
            .addCase(fetchAllSites.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(createSite.pending, (state, {payload}) => {
                state.loading = true;
            })
            .addCase(createSite.fulfilled, (state, {payload}) => {
                state.site = payload
            })
            .addCase(createSite.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(editSite.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(editSite.fulfilled, (state, {payload}) => {
                const index = state.sites.findIndex((item) => item.siteId === payload.siteId)
                if (index !== -1) {
                    state.sites = [...state.sites.slice(0, index), payload, ...state.sites.slice(index + 1)]
                }
                state.loading = false
                state.site = payload
            })
            .addCase(editSite.rejected, (state, action) => {
                if (action.payload) {
                    state.error = (action.payload as { message: string })?.message ?? 'Internal server error'
                } else if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(deleteSelectedSite.fulfilled, (state, {payload}) => {

            })
            .addCase(deleteSelectedSite.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(getTotalOwnedSites.pending, (state) => {
                state.loading = true
            })
            .addCase(getTotalOwnedSites.fulfilled, (state, {payload}) => {
                state.totalOwnedSites = payload
                state.loading = false
            })
            .addCase(getTotalOwnedSites.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(getMaxAllowedSitesWithoutAddon.pending, (state) => {
                state.loading = true
            })
            .addCase(getMaxAllowedSitesWithoutAddon.fulfilled, (state, {payload}) => {
                state.maxAllowedSitesWithoutAddon = payload
                state.loading = false
            })
            .addCase(getMaxAllowedSitesWithoutAddon.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
    })
})

export const {setSite, resetSiteErrorState, deleteSiteState,resetErrorStateSites} = siteSlice.actions

export const siteReducer = siteSlice.reducer
