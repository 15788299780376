import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Container from '../../../components/Container';
import {Link} from "react-router-dom";
import {useAppSelector} from "../../../hooks/hooks";
import {User} from "../../../services/restserver-openapi";
import Main from '../../../layouts/Main';
import ProfileHeader from '../../../components/profileComponents/ProfileHeader';

const allPages = [
    // {
    //     id: 'spezial',
    //     href: '/profil/spezial',
    //     title: 'Spezial',
    // },
    {
        id: 'personal',
        href: '/profil/personal',
        title: 'Persönliche Daten',
    },
    {
        id: 'notifications',
        href: '/profil/notifications',
        title: 'Benachrichtigungen',
    },
    {
        id: 'abos',
        href: '/profil/abos',
        title: 'Meine Subscription',
    },
    {
        id: 'support',
        href: '/profil/support',
        title: 'Hilfe & Support',
    },
];

interface Props {
    children: React.ReactNode;
}

const Page = ({ children }: Props): JSX.Element => {
    const userRoleLogin = useAppSelector((state) => state.login.userRoleLogin);
    const [pages, setPages] = useState(allPages);

    const [activeLink, setActiveLink] = useState('');
    useEffect(() => {
        setActiveLink(window && window.location ? window.location.pathname : '');
    }, []);

    const theme = useTheme();

    useEffect(() => {
        if (userRoleLogin === User.userRole.AGENT) {
            setPages(allPages.filter(page => page.id !== 'abos' && page.id !== 'spezial'));
        } else {
            setPages(allPages);
        }
    }, [userRoleLogin]);

    return (
      <Main>
          <ProfileHeader />
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Card sx={{ boxShadow: 0 }}>
                            <List
                                disablePadding
                                sx={{
                                    display: { xs: 'inline-flex', md: 'flex' },
                                    flexDirection: { xs: 'row', md: 'column' },
                                    overflow: 'auto',
                                    flexWrap: 'nowrap',
                                    width: '100%',
                                    paddingY: { xs: 3, md: 4 },
                                    paddingX: { xs: 4, md: 0 },
                                }}
                            >
                                {pages.map((item) => (
                                    <ListItem
                                        key={item.id}
                                        component={Link}
                                        to={item.href}
                                        disableGutters
                                        sx={{
                                            marginRight: { xs: 2, md: 0 },
                                            flex: 0,
                                            paddingX: { xs: 0, md: 3 },
                                            borderLeft: {
                                                xs: 'none',
                                                md: '10px solid transparent',
                                            },
                                            borderLeftColor: {
                                                md:
                                                    activeLink === item.href
                                                        ? theme.palette.secondary.light
                                                        : 'transparent',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            noWrap
                                            color={
                                                activeLink === item.href
                                                    ? 'text.primary'
                                                    : 'text.secondary'
                                            }
                                        >
                                            {item.title === "Spezial" && userRoleLogin === User.userRole.HOST ? "Campingplatz verwalten" : item.title === "Spezial" && userRoleLogin === User.userRole.VANLIFER ? "Auszeichnungen" : item.title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Card sx={{ boxShadow: 3, padding: 4 }}>{children}</Card>
                    </Grid>
                </Grid>
            </Container>
        </Main>
    );
};

export default Page;
