import React, {useEffect} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlternativeSingleSiteComponent from "./AlternativeSingleSiteComponent";
import {AlternativeSiteDetail} from "../../services/restserver-openapi";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchEditedTourDetails} from "../../store/Vanlifer/tourSlice";

type AlternativeStayProps = {
    alternativeSiteList: AlternativeSiteDetail[] | undefined;
    stayId: number
}
const AlternativeSitesComponent = ({alternativeSiteList, stayId}: AlternativeStayProps) => {
    const dispatch = useAppDispatch();
    const chosenTourId = useAppSelector(state => state.tour.choosedTourDetail.tourId);
    const [expanded, setExpanded] = React.useState<string | false>(false);

    useEffect(() => {
        setExpanded(false)
    }, [])


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleToggle = (chosenStayId: number | undefined) => (event: React.SyntheticEvent) => {
        dispatch(fetchEditedTourDetails({tourId: chosenTourId!, stayId: stayId, chosenSiteId: chosenStayId!}));
    };

    return (<div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography>
                    Dir gefällt der Campingplatz nicht? Wähle hier einen anderen aus...
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {alternativeSiteList?.map((alternativeStay: AlternativeSiteDetail) =>
                        <ListItem key={alternativeStay.siteId} onClick={handleToggle(alternativeStay.siteId)} button>
                            <AlternativeSingleSiteComponent alternativeSite={alternativeStay}/>
                        </ListItem>
                    )}
                </List>
            </AccordionDetails>
        </Accordion>
    </div>);
}

export default AlternativeSitesComponent;
