import React, {useMemo} from 'react';
import moment from "moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Box, useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    setEndDateRange,
    setStartDateRange
} from "../../store/Host/sitesManagement/availabilitySlice";
import './vltCalendar.css';
import {CalendarEvent, getEventsBase, getEventStyle} from '../../utils/Host/calendarUtil';
import {AvailabilityDetail} from "../../services/restserver-openapi";

require('moment/locale/de.js')

const localizer = momentLocalizer(moment);

interface ManagementCalendarProps {
    availabilities: AvailabilityDetail[];
}

const ManagementCalendar: React.FC<ManagementCalendarProps> = ({ availabilities }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const site = useAppSelector(state => state.site.site);
    
    function getEvents(): CalendarEvent[] {
        return getEventsBase(availabilities, [site], false);
    }

    let {views} = useMemo(
        () => ({
            views: {month: true, agenda: true},
        }), []
    );

    const onSelectRange = async (startDate: Date, endDate: Date) => {
        await dispatch(setStartDateRange(moment(startDate)));
        await dispatch(setEndDateRange(moment(endDate.setDate(endDate.getDate() - 1))));
    }

    return (
        <Box>
            <div>
                <Calendar events={getEvents()}
                          startAccessor="startDate"
                          endAccessor="endDate"
                          defaultDate={moment().toDate()}
                          localizer={localizer}
                          views={views}
                          selectable={true}
                          messages={{
                              previous: '<',
                              next: '>',
                              today: "Heute",
                              month: "Monat",
                              agenda: "Terminübersicht",
                          }}
                          style={{height: '500pt', color: theme.palette.text.primary}}
                          eventPropGetter={getEventStyle}
                          onSelectSlot={slotInfo => {
                              const start = slotInfo.start;
                              const end = slotInfo.end;
                              onSelectRange(start, end);
                          }}
                />
            </div>
        </Box>);
}

export default ManagementCalendar;