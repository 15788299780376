import React, {useEffect, useState} from 'react';
import {Buffer} from 'buffer';
import {Grid, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {StayDetail, TourDetail} from "../../services/restserver-openapi";
import {getTourDetail} from "../../utils/Vanlifer/tour";
import AdditionalInfoFormComponent from "../../components/vanliferComponents/AdditionalInfoFormComponent";
import StayCard from "../../components/vanliferComponents/StayCard";


function AdditionalInfoForm() {
    const { idsBase64 } = useParams();
    if (!idsBase64) {
        throw new Error('Keine Tour- und Aufenthalts-ID übergeben');
    }
    const decodedIds = Buffer.from(idsBase64, 'base64').toString('utf-8');
    const ids = decodedIds.split(':');
    const tourId = parseInt(ids[0]);
    const stayId = parseInt(ids[1]);

    const [tourDetail, setTourDetail] = useState<TourDetail | null>(null);
    const [currentStay, setCurrentStay] = useState<StayDetail | null>(null);

    useEffect(() => {
        getTourDetail(tourId)
            .then((data) => {
                setTourDetail(data);

                const foundStay = data?.stays?.find((stay: StayDetail) => stay.stayId === stayId) || null;
                setCurrentStay(foundStay);
            })
            .catch((error: any) => {
                console.error('Fehler beim Abrufen der Tourdetails:', error);
            });
    }, [tourId, stayId]);

    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }} sx={{minHeight: '900px'}} padding={5}>
            <Grid item md={8} >
                {currentStay && tourDetail ? (
                    <>
                        <AdditionalInfoFormComponent stay={currentStay} tour={tourDetail}/>
                    </>
                ) : (
                    <Typography component="p">Lade Formular...</Typography>
                )}

            </Grid>
            <Grid item md={4}>
                {currentStay && tourDetail ? (
                    <>
                        <StayCard stay={currentStay} tour={tourDetail}/>
                    </>
                ) : (
                    <Typography component="p">Lade Informationen...</Typography>
                )}

            </Grid>
        </Grid>
    );
}

export default AdditionalInfoForm;
