/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Subscription } from '../models/Subscription';
import type { SubscriptionEntitlement } from '../models/SubscriptionEntitlement';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubscriptionService {
    /**
     * Endpoint to receive updates from chargebee
     * @param key
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static handleWebhook(
        key: string,
        requestBody: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhook',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request a subscription intent, which is used by the frontend to create new subscriptions
     * @param planId
     * @param addOrRemove true if the planId should be added, false if it should be removed
     * @returns any OK
     * @throws ApiError
     */
    public static requestSubscriptionIntent(
        planId: string,
        addOrRemove: boolean,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/subscriptions/intent',
            query: {
                'planId': planId,
                'addOrRemove': addOrRemove,
            },
        });
    }
    /**
     * Request all subscriptions for the logged in user. Agent could also request subscriptions of other users by providing the userId
     * @param userId
     * @returns Subscription OK
     * @throws ApiError
     */
    public static getUserSubscriptions(
        userId?: number,
    ): CancelablePromise<Array<Subscription>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/user',
            query: {
                'userId': userId,
            },
        });
    }
    /**
     * Request all entitlements for the logged in user. Agent could also request entitlements of other users by providing the userId
     * @param userId
     * @returns SubscriptionEntitlement OK
     * @throws ApiError
     */
    public static getUserEntitlements(
        userId?: number,
    ): CancelablePromise<Array<SubscriptionEntitlement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/user/entitlements',
            query: {
                'userId': userId,
            },
        });
    }
    /**
     * Get the allowed amount of sites for the logged in host without the addon. An agent can also request the amount of sites for a host by providing the hostId
     * @param hostId
     * @returns string OK
     * @throws ApiError
     */
    public static getAllowedAmountOfSitesWithoutAddon(
        hostId?: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/user/entitlements/getAllowedAmountOfSitesWithoutAddon',
            query: {
                'hostId': hostId,
            },
        });
    }
    /**
     * Get Portal to manage Chargebee Subscriptions
     * @returns any OK
     * @throws ApiError
     */
    public static getPortal(): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/portal',
        });
    }
    /**
     * Request all entitlements for% a given compound
     * @param compoundId
     * @returns SubscriptionEntitlement OK
     * @throws ApiError
     */
    public static getCompoundEntitlements(
        compoundId: number,
    ): CancelablePromise<Array<SubscriptionEntitlement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/compound/{compoundId}/entitlements',
            path: {
                'compoundId': compoundId,
            },
        });
    }
}
