import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "@here/maps-api-for-javascript";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import moment, {Moment} from "moment";
import {Compound} from "../../services/restserver-openapi";
import {createCompound, defaultCompound, editCompound} from "../../store/Host/compoundsSlice";
import {countryCodes as countries} from "../../constants/country_code";
import UploadPictures from "../presentationalComponents/UploadPictures";
import TimePickerVanlife from "../presentationalComponents/TimePickerVanlife";
import {fetchAllRegions} from "../../store/Vanlifer/regionSlice";
import {
    fetchExistingImages,
    handleImageDelete,
    handleImageUploadCompound,
    handleUpdateImageChange
} from "../../utils/Host/offerImageUtil";
import {Save} from "@mui/icons-material";
import {addDraggableMarker} from "../../utils/hereMapUtils";

type CreateCompoundFormProps = {
    onBack: (compoundId?: number) => void
}

const CompoundFormComponent: React.FC<CreateCompoundFormProps> = ({onBack}) => {
    const dispatch = useAppDispatch();
    const HERE_MAP_ID = process.env.REACT_APP_HERE_MAP_ID!;
    const compound = useAppSelector(state => state.compounds.selectedCompound);
    const [compoundData, setCompoundData] = useState<Compound>(defaultCompound);

    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<H.Map | null>(null);
    const uiInstance = useRef<H.ui.UI | null>(null);
    const behaviorInstance = useRef<H.mapevents.Behavior | null>(null);

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<string[]>([]);
    const [loadingSave, setLoadingSave] = useState(false);


    const [arrivalTime, setArrivalTime] = React.useState<Moment | null>(
        moment(compound.arrivalTime, 'THH:mm')
    );
    const [departureTime, setDepartureTime] = React.useState<Moment | null>(
        moment(compound.departureTime, 'THH:mm')
    );

    const [countrySelected, setCountrySelected] = useState("Schweiz");
    const platform = useRef(new H.service.Platform({
        apikey: HERE_MAP_ID
    }));

    useEffect(() => {
        if (mapRef.current && !mapInstance.current) {
            const defaultLayers : any = platform.current.createDefaultLayers();
            const map = new H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map,
                {
                    center: {lat: 46.4757, lng: 8.1355},
                    zoom: 6,
                    pixelRatio: window.devicePixelRatio || 1
                }
            );
            uiInstance.current = H.ui.UI.createDefault(map, defaultLayers, 'de-DE');
            mapInstance.current = map;
            behaviorInstance.current = new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

            if (compound.coordinates?.x && compound.coordinates?.y) {
                addDraggableMarker(mapInstance.current, behaviorInstance.current, compound.coordinates.x, compound.coordinates.y, (lat, lng) => {
                    setCompoundData({...compoundData, coordinates: {x: lat, y: lng}})
                });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef.current]);

    useEffect(() => {
        dispatch(fetchAllRegions)
    }, [dispatch]);

    useEffect(() => {
        setCompoundData(compound)
        setCountrySelected(countries.find((country) => country.countryCode === compound.country)?.countryName ?? 'Schweiz')
    }, [compound]);

    const getCoordinatesFromAddress = () => {

                    const service = platform.current.getSearchService()

                    service.geocode({
                        q: `${compoundData.addressLine1}, ${compoundData.zipCode} ${compoundData.city}`
                    }, (result: any) => {
                        if (result.items.length > 0) {
                            const position = result.items[0].position;
                            setCompoundData({...compoundData, coordinates: {x: position.lat, y: position.lng}})
                            if (!mapInstance.current || !behaviorInstance.current) { return;}
                            addDraggableMarker(mapInstance.current, behaviorInstance.current, position.lat, position.lng, (lat, lng) => {
                                setCompoundData({...compoundData, coordinates: {x: lat, y: lng}})
                            });
                        }
                    }, (error) => {
                        console.error("Error fetching coordinates: ", error);
                    });
    }

    const handleArrivalTimeChange = (newValue: Moment | null) => {
        setArrivalTime(newValue);
        setCompoundData({...compoundData, arrivalTime: newValue?.format('HH:mm')})
    };

    const handleDepartureTimeChange = (newValue: Moment | null) => {
        setDepartureTime(newValue);
        setCompoundData({...compoundData, departureTime: newValue?.format('HH:mm')})
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (compoundData.compoundId) {
            setLoadingSave(true);
            await handleImageUploadCompound(compoundData, selectedFiles);
            setLoadingSave(false);
            dispatch(editCompound(compoundData));
            onBack(compoundData.compoundId);
        } else {
            await dispatch(createCompound(compoundData)).then(async (response) => {
                if (createCompound.fulfilled.match(response)) {
                    setCompoundData(response.payload);
                    await handleImageUploadCompound(response.payload, selectedFiles);
                    onBack(response.payload.compoundId);
                }
            });
        }
    };

    function getCountryEnumFromString(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let inputCountryEnum = Compound.country.MN;
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].countryName === event.target.value) {
                inputCountryEnum = countries[i].countryCode as Compound.country;
            }
        }
        return inputCountryEnum;
    }

    function handleCompoundCountryInput(event: ChangeEvent<HTMLTextAreaElement>) {
        setCountrySelected(event.target.value);
        let inputCountryEnum = getCountryEnumFromString(event);
        setCompoundData({...compoundData, country: inputCountryEnum});
    }

    useEffect(() => {
        if (compoundData.imageIds) {
            fetchExistingImages(compoundData.imageIds, setSelectedFiles, selectedFiles);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compoundData.imageIds]);


    const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateImageChange(e, files, setFiles, selectedFiles, setSelectedFiles);
    }

    const handleUploadDelete = (index: number) => {
        handleImageDelete(index, files, setFiles, selectedFiles, setSelectedFiles);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Typography variant="h5" component="h3">
                        Allgemeine Informationen
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Name"
                        fullWidth
                        value={compoundData.name}
                        onChange={(e) => setCompoundData({...compoundData, name: e.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Website"
                        fullWidth
                        value={compoundData.externalLink}
                        onChange={(e) => setCompoundData({...compoundData, externalLink: e.target.value})}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" component="h3">
                        Standort Informationen
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Adresse"
                        fullWidth
                        value={compoundData.addressLine1}
                        onChange={(e) => setCompoundData({...compoundData, addressLine1: e.target.value})}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    <TextField
                        type={"number"}
                        label="PLZ"
                        fullWidth
                        value={compoundData.zipCode}
                        onChange={(e) => setCompoundData({...compoundData, zipCode: Number(e.target.value)})}
                    />
                </Grid>
                <Grid item xs={8} md={6}>
                    <TextField
                        label="Stadt"
                        fullWidth
                        value={compoundData.city}
                        onChange={(e) => setCompoundData({...compoundData, city: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField select id="select-country"
                               fullWidth
                               label="Land" value={countrySelected}
                               onChange={handleCompoundCountryInput} required>
                        {countries.map((country) => (
                            <MenuItem key={country.countryCode} value={country.countryName}>
                                {country.countryName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <div ref={mapRef} style={{height: '400px'}} />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type={"number"}
                        label="Latitude"
                        fullWidth
                        value={compoundData.coordinates?.x}
                        onChange={(e) => setCompoundData({...compoundData, coordinates: {x: Number(e.target.value), y: compoundData.coordinates?.y}})}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type={"number"}
                        label="Longitude"
                        fullWidth
                        value={compoundData.coordinates?.y}
                        onChange={(e) => setCompoundData({...compoundData, coordinates: {x: compoundData.coordinates?.x, y: Number(e.target.value)}})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={getCoordinatesFromAddress}>
                        Koordinaten aus Adresse generieren
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h3">
                        Campingplatz Informationen
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TimePickerVanlife
                        fullWidth
                        handleChange={handleArrivalTimeChange}
                        value={arrivalTime}
                        label="Standard Check-In Zeit"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TimePickerVanlife
                        fullWidth
                        handleChange={handleDepartureTimeChange}
                        value={departureTime}
                        label="Standard Check-Out Zeit"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Gästeinformationen"
                        fullWidth
                        multiline
                        rows={4}
                        value={compoundData.guestInformation}
                        onChange={(e) => setCompoundData({...compoundData, guestInformation: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h4">
                        Ausstattung
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox checked={compoundData.wifiAvailable} onChange={(e) => setCompoundData({
                            ...compoundData,
                            wifiAvailable: e.target.checked
                        })}/>}
                        label="Wifi / WLAN"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.toilet}
                                onChange={(e) => setCompoundData({...compoundData, toilet: e.target.checked})}
                            />
                        }
                        label="WC"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.shop}
                                onChange={(e) => setCompoundData({...compoundData, shop: e.target.checked})}
                            />
                        }
                        label="Shop"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.shower}
                                onChange={(e) => setCompoundData({...compoundData, shower: e.target.checked})}
                            />
                        }
                        label="Dusche"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.sewerage}
                                onChange={(e) => setCompoundData({...compoundData, sewerage: e.target.checked})}
                            />
                        }
                        label="Abwasseranschluss"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.dogsAllowed}
                                onChange={(e) => setCompoundData({...compoundData, dogsAllowed: e.target.checked})}
                            />
                        }
                        label="Hunde erlaubt"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.familyFriendly}
                                onChange={(e) => setCompoundData({...compoundData, familyFriendly: e.target.checked})}
                            />
                        }
                        label="Familienfreundlich"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.inTheMountains}
                                onChange={(e) => setCompoundData({...compoundData, inTheMountains: e.target.checked})}
                            />
                        }
                        label="In den Bergen"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.byTheWater}
                                onChange={(e) => setCompoundData({...compoundData, byTheWater: e.target.checked})}
                            />
                        }
                        label="Am Wasser"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.culturalOfferings}
                                onChange={(e) => setCompoundData({
                                    ...compoundData,
                                    culturalOfferings: e.target.checked
                                })}
                            />
                        }
                        label="Kulturangebot"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compoundData.ecoFriendly}
                                onChange={(e) => setCompoundData({...compoundData, ecoFriendly: e.target.checked})}
                            />
                        }
                        label="Umweltfreundlicher Betrieb"
                    />

                </Grid>

                {/* Bilder */}
                <Grid item xs={12}>
                    <Typography variant="h5" component="h3">
                        Bilder
                    </Typography>
                    <UploadPictures handleChange={handleUploadChange} selectedFiles={selectedFiles} handleDelete={handleUploadDelete} />
                </Grid>

                {/* Submit CallToActionButton */}
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit" endIcon={loadingSave ? <CircularProgress /> : <Save  />}>
                        Speichern
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CompoundFormComponent;
