/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StayBookingDetail } from '../models/StayBookingDetail';
import type { TourDetail } from '../models/TourDetail';
import type { TourOverview } from '../models/TourOverview';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TourService {
    /**
     * Reserve a generated tour
     * @param tourId
     * @param sessionId
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static reserveTour(
        tourId: number,
        sessionId?: string,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tours/{tourId}/reserve',
            path: {
                'tourId': tourId,
            },
            query: {
                'sessionId': sessionId,
            },
        });
    }
    /**
     * Replace the site of a stay in a tour
     * @param tourId
     * @param stayId
     * @param newSiteId
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static replaceSite(
        tourId: number,
        stayId: number,
        newSiteId: number,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tours/{tourId}/mutations/stays/{stayId}/replace/site/{newSiteId}',
            path: {
                'tourId': tourId,
                'stayId': stayId,
                'newSiteId': newSiteId,
            },
        });
    }
    /**
     * Change duration of a stay in a tour
     * @param tourId
     * @param stayId
     * @param newFromDate The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @param newToDate The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static changeStayDuration(
        tourId: number,
        stayId: number,
        newFromDate: number,
        newToDate: number,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tours/{tourId}/mutations/stays/{stayId}/change/duration/{newFromDate}/{newToDate}',
            path: {
                'tourId': tourId,
                'stayId': stayId,
                'newFromDate': newFromDate,
                'newToDate': newToDate,
            },
        });
    }
    /**
     * Change additional information from a vanlifer of a stay in a tour
     * @param tourId
     * @param stayId
     * @param newArrivalTime
     * @param newComment
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static changeStayAdditionalInformation(
        tourId: number,
        stayId: number,
        newArrivalTime: string,
        newComment: string,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tours/{tourId}/mutations/stays/{stayId}/change/additional-information',
            path: {
                'tourId': tourId,
                'stayId': stayId,
            },
            query: {
                'newArrivalTime': newArrivalTime,
                'newComment': newComment,
            },
        });
    }
    /**
     * Cancel a booked tour
     * @param tourId
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static cancelTour(
        tourId: number,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tours/{tourId}/cancel',
            path: {
                'tourId': tourId,
            },
        });
    }
    /**
     * Book a reserved tour
     * @param tourId
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static bookTour(
        tourId: number,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tours/{tourId}/book',
            path: {
                'tourId': tourId,
            },
        });
    }
    /**
     * Get existing tours
     * @param sessionId
     * @param vanliferId
     * @param tourStatus
     * @returns TourOverview OK
     * @throws ApiError
     */
    public static getTours(
        sessionId?: string,
        vanliferId?: number,
        tourStatus?: 'GENERATED' | 'TAGGED' | 'RESERVED' | 'BOOKED' | 'CANCELED',
    ): CancelablePromise<Array<TourOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tours',
            query: {
                'sessionId': sessionId,
                'vanliferId': vanliferId,
                'tourStatus': tourStatus,
            },
        });
    }
    /**
     * Get a existing tour
     * @param tourId
     * @returns TourDetail OK
     * @throws ApiError
     */
    public static getTour(
        tourId: number,
    ): CancelablePromise<TourDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tours/{tourId}',
            path: {
                'tourId': tourId,
            },
        });
    }
    /**
     * Get stays between two timestamps
     * @param fromTimestamp The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @param toTimestamp The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @param hostId
     * @returns StayBookingDetail OK
     * @throws ApiError
     */
    public static getStaysBetween(
        fromTimestamp: number,
        toTimestamp: number,
        hostId: number,
    ): CancelablePromise<Array<StayBookingDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tours/stay/{fromTimestamp}/{toTimestamp}/{hostId}',
            query: {
                'fromTimestamp': fromTimestamp,
                'toTimestamp': toTimestamp,
                'hostId': hostId,
            },
        });
    }
    /**
     * Get generated tours by algorithm
     * @param regionId
     * @param fromDate The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @param toDate The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @param sessionId
     * @returns TourOverview OK
     * @throws ApiError
     */
    public static generateTours(
        regionId: number,
        fromDate: number,
        toDate: number,
        sessionId?: string,
    ): CancelablePromise<Array<TourOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tours/generate',
            query: {
                'sessionId': sessionId,
                'regionId': regionId,
                'fromDate': fromDate,
                'toDate': toDate,
            },
        });
    }
}
