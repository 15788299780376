import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AvailabilityDetail} from "../../../services/restserver-openapi";
import {Moment} from "moment/moment";
import {
    addAvailabilities,
    deleteAvailabilities,
    getAvailabilities,
    updateAvailabilities
} from "../../../utils/Host/offer";
import {mapErrorMessages} from "../../../utils/errorHandling";

let errorMessage: string | null;

export type AvailabilityState = {
    availabilities: AvailabilityDetail[],
    newavailabilities: AvailabilityDetail[],
    loading: false | true,
    error: string | null,
    startDateRange: Moment | null,
    endDateRange: Moment | null
}

export const defaultAvailability: AvailabilityDetail = {
    offerId: 1,
    datetime: 1,
    price: 0,
    availabilityStatus: AvailabilityDetail.availabilityStatus.AVAILABLE
}

const initialState: AvailabilityState = {
    availabilities: [],
    newavailabilities: [],
    loading: false,
    error: null,
    startDateRange: null,
    endDateRange: null
}


export const fetchAllAvailabilities = createAsyncThunk(
    'availability/fetchAllAvailabilities',
    async (offerId: number) => {
        const response = await getAvailabilities(offerId)
        return response
    }
)

export const createAvailabilities = createAsyncThunk(
    'availability/createAvailability',
    async (availabilities: AvailabilityDetail[]) => {
        const response = await addAvailabilities(availabilities)
        return response
    }
)


export const editAvailabilities = createAsyncThunk(
    'availability/updateAvailability',
    async (availabilities: AvailabilityDetail[]) => {
        const response = await updateAvailabilities(availabilities);
        return response
    }
)

export const deleteSelectedAvailabilities = createAsyncThunk(
    'availability/deleteAvailability',
    async (availabilityIds: number[]) => {
        const response = await deleteAvailabilities(availabilityIds);
        return response
    }
)

export const availabilitySlice = createSlice({
    name: 'avalilability',
    initialState,
    reducers: {
        setStartDateRange: (state, {payload}: PayloadAction<Moment | null>) => {
            state.startDateRange = payload;
        },
        setEndDateRange: (state, {payload}: PayloadAction<Moment | null>) => {
            state.endDateRange = payload;
        },

        resetAvailabilitiesErrorState: (state) => {
            state.error = null
        },
        resetAvailabilityState: (state) => {
            state.availabilities = [];
            state.newavailabilities = [];
            state.loading = false;
            state.error = null;
            state.startDateRange = null;
            state.endDateRange = null;
        },
        resetErrorStateAvailabilities: (state)=>{
            state.error=null;
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(fetchAllAvailabilities.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(fetchAllAvailabilities.fulfilled, (state, {payload}) => {
                if(payload===undefined){
                    errorMessage= "No Content";
                    state.error=mapErrorMessages({errorMessage})
                }else{
                    state.availabilities = payload;
                }
                state.loading = false
            })
            .addCase(fetchAllAvailabilities.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(createAvailabilities.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(createAvailabilities.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(createAvailabilities.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(editAvailabilities.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(editAvailabilities.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(editAvailabilities.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(deleteSelectedAvailabilities.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(deleteSelectedAvailabilities.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(deleteSelectedAvailabilities.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
    })
})

export const {
    setStartDateRange,
    setEndDateRange,
    resetAvailabilitiesErrorState,
    resetAvailabilityState,
    resetErrorStateAvailabilities
} = availabilitySlice.actions
export const availabilityReducer = availabilitySlice.reducer
