import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {PaletteMode} from "@mui/material";

const mock = [
  {
    title: 'Vanlife Free',
    price: '0 CHF',
    unit: 'pro Monat',
    features: [
      'Dein persönliches Vanlifer Profil',
      'Individuelle Reisevorschläge generieren (max. 24/Tag)',
      'Alle Details zu geplanten, aktuellen und vergangenen Reisen übersichtlich verwalten und abrufen',
      'Reiseerlebnisse bewerten und Reviews schreiben',
      'Möglichkeit zur Teilnahme an exklusiven Events (z.B. Workations)',
      'Infos mit allen Reviews zu Reiseregionen, Sights und Unterkünften',
      'Sammle Belohungen, Abzeichen und Travelpoints, um zur Vanlife Legende zu werden',
      'Bleib auf dem Laufenden mit unserem Newsletter'
    ],
    isHighlighted: true,
  },
  // {
  //   title: 'Vanlife Flex',
  //   price: 'ab 5 CHF',
  //   unit: 'pro Monat',
  //   features: [
  //     'Alle Funktionen aus dem "Free"-Abo',
  //     'Sofort buchbare Reisevorschläge erhalten',
  //     'Individuelle POI Vorschläge für Deine Reise erhalten',
  //     'Reisen planen und mit dem Editor auf Deine Wünsche anpassen',
  //     'Alle Reisen buchen und stornieren',
  //     'Support Chat & Telefon Hotline'
  //   ],
  //   isHighlighted: true,
  // },
  {
    title: 'Early Access',
    price: '25 CHF',
    unit: 'Einmalig bis zum Marktstart',
    features: [
      'Unterstütze uns und sei ein wichtiger Teil unserer Produktentwicklung. Deine Meinung zählt!',
      'Alle Features und Inhalte aus dem "Vanlife Free" Abo',
      'Von Anfang an mit dabei sein, unsere Open Travel Community mitgestalten und alle Funktionen unbegrenzt testen und nutzen',
      'Individuelle Reisevorschläge unbegrenzt generieren',
      'Nutze alle Funktionen unseres Reiseeditors um Deine Reise genau auf Deine Wünsche anzupassen',
      'Zugriff auf direkt reservierbare Angebote mit der Möglichkeit bis 48h vorher zu stornieren',
      'Direkter Kontakt zu uns via Whatsapp Chat & Telefon Hotline',
      'Nach offiziellem Marktstart erhältst du alle unsere Premiumfunktionen für 1 Jahr geschenkt'
    ],
    isHighlighted: true,
  },
];

const Pricing = (): JSX.Element => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Einfache und Flexibel Mitmachen
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Je nach Situation bieten wir Dir eine Auswahl passender Mitmach-Optionen.
        </Typography>
        <Typography
          variant="body1"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Mit einem kostenlosen "Vanlifer Free"-Abo wirst du ein Teil der Vanlife Commnity und sicherst Dir dauerhaften Zugriff auf Deine Reisen.
          <br/>
          Mit "Early Access" unterstützt Du uns mit einem einmaligen Betrag, nutzt und testest alle Funkionen und wirst durch Deine Meinung ein wichtiger Teil unseres Entwicklungsteams.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
              component={Card}
              height={1}
              display={'flex'}
              flexDirection={'column'}
              boxShadow={item.isHighlighted ? 4 : 0}
            >
              <CardContent
                sx={{
                  padding: 4,
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Typography variant={'h4'}>
                    <Box component={'span'} fontWeight={600}>
                      {item.title}
                    </Box>
                  </Typography>
                </Box>
                  <Box marginBottom={4} display={'flex'} alignItems={'baseline'} gap={'.5rem'}>
                    <Typography variant={'h6'} color={'primary'}>
                      <Box component={'span'} fontWeight={600}>
                        {item.price}
                      </Box>
                    </Typography>
                    <Typography variant={'body1'} color={'primary'}>
                      {item.unit}
                    </Typography>
                  </Box>

                <Grid container spacing={1}>
                  {item.features.map((feature, j) => (
                    <Grid item xs={12} key={j}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={feature} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <Box flexGrow={1} />
              <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                {/*<Button size={'large'} variant={'contained'}>*/}
                {/*  Kaufen*/}
                {/*</Button>*/}
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Pricing;
