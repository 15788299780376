import React from 'react';
import {Box, Grid, Paper, Typography, useTheme} from "@mui/material";
import {createGeneralStyling} from "../../../theme/generalStyling";
import tourservicetutorial4 from "../../../assets/Images/tourservice-tutorial4.jpg";
import tourservicetutorial5 from "../../../assets/Images/tourservice-tutorial5.jpg";
import tourservicetutorial6 from "../../../assets/Images/tourservice-tutorial6.jpg";

type paymentNoticeProps = {
    paymentNoticeText: string;
}

function PaymentNotice(props: paymentNoticeProps) {
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);

    function TourEditorScreenshots() {
        return <Grid container direction='row' display="flex" justifyContent="center" columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 6, xl: 6 }} rowSpacing={2}>
            <Grid item xs={12} sm={4}>
                <Box
                    height={{xs: '500px', sm: '350px', md: '500px'}} style={{
                    backgroundImage: `url(${tourservicetutorial4})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center 50%',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                }} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box height={{xs: '500px', sm: '350px', md: '500px'}} style={{
                    backgroundImage: `url(${tourservicetutorial5})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center 50%',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                }} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box height={{xs: '500px', sm: '350px', md: '500px'}} style={{
                    backgroundImage: `url(${tourservicetutorial6})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center 50%',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                }} />
            </Grid>
        </Grid>
    }

    return (
        <React.Fragment>
            <Grid container justifyContent="space-evenly" alignItems="flex-start">
                <Grid item xs={11} sm={8} md={7} lg={5}>
                    <Paper style={{...generalStyling.vltPaper, backgroundColor: `${theme.palette.secondary.light}`}}>
                        <Typography variant="h5"> Hallo... </Typography>
                        <Typography variant="body1">{props.paymentNoticeText}</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {TourEditorScreenshots()}
            </Grid>
        </React.Fragment>
    );
}

export default PaymentNotice;
