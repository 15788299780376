import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, IconButton} from "@mui/material";
import html2canvas from "html2canvas";
import {Share} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {setMapCaptureFinished, setShowStaticMap} from "../../../store/Vanlifer/tourSlice";
import Typography from "@mui/material/Typography";

type ShareTourProps = {
    elementRef: React.RefObject<HTMLDivElement>;
}

type ShareData = {
    title: string;
    text: string;
    url: string;
    files: File[];
};

export default function ShareTour(props: ShareTourProps) {
    const dispatch = useAppDispatch();
    const showStaticMap = useAppSelector(state => state.tour.showStaticMap);
    const mapCaptureFinished = useAppSelector(state => state.tour.mapCaptureFinished);
    const [shouldCapture, setShouldCapture] = useState(false);
    const shareIconRef = useRef<HTMLDivElement>(null);

    // Fallback for browsers that don't support Web Share API
    function downloadImageAsSharingFallback(blob: Blob) {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'image.png';
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const share = useCallback(async (shareData: ShareData, blob: Blob)=> {
        try {
            if (navigator.share) {
                try {
                    await navigator.share(shareData);
                } catch (error: any) {
                    if (error.name === 'ABORT_ERR') {
                        // Handle the abort error
                        // If the user aborts the share, the share should be completely aborted,
                        // and the download fallback also shouldn't happen
                    } else {
                        throw error;
                    }
                }
            } else {
                throw new Error('Web Share API not supported');
            }
        } catch (error) {
            downloadImageAsSharingFallback(blob);
        }
    }, []);

    const hideShareButton = useCallback(()=> {
        if (shareIconRef.current) {
            shareIconRef.current.style.visibility = 'hidden';
            shareIconRef.current.style.display = 'none';
        }
    }, []);

    const showShareButton = useCallback(()=> {
        if (shareIconRef.current) {
            shareIconRef.current.style.visibility = 'visible';
            shareIconRef.current.style.display = 'block';
        }
    }, []);

    const prepareShareDataAndShare = useCallback( async (blob: Blob)=> {
        const imageFile = new File([blob], 'image.png', {
            type: 'image/png',
        });

        const shareData: ShareData = {
            title: 'Deine Tour',
            text: 'Schau dir meine coole Tour von Vanlife Travel an!',
            url: 'https://poc1.vanlife-travel.ch',
            files: [imageFile],
        };

        await share(shareData, blob);
    }, [share]);

    const createImageAndShareIt = useCallback(async () => {
        if (!props.elementRef.current) {
            alert('Couldn\'t share: Error capturing element: No elementRef received')
            return;
        }

        try {
            hideShareButton();

            const canvas = await html2canvas(props.elementRef.current, {willReadFrequently: true} as any);

            canvas.toBlob(async (blob) => {
                if (blob) {
                    await prepareShareDataAndShare(blob);
                } else {
                    alert('Couldn\'t share: Error capturing element: No blob received')
                }
            }, 'image/png');

            showShareButton();
        } catch (error) {
            alert('Couldn\'t share: Error capturing element')
        }
    }, [hideShareButton, prepareShareDataAndShare, props.elementRef, showShareButton]);

    useEffect(() => {
        if (shouldCapture && showStaticMap && mapCaptureFinished) {
            setShouldCapture(false);
            createImageAndShareIt().then(() => {
                dispatch(setShowStaticMap(false));
            });
        }
    }, [createImageAndShareIt, dispatch, mapCaptureFinished, shouldCapture, showStaticMap]);

    const dispatchSharingActions = async () => {
        dispatch(setMapCaptureFinished(false));
        dispatch(setShowStaticMap(true));
        setShouldCapture(true);
    };

    return <Box ref={shareIconRef} onClick={dispatchSharingActions} style={{cursor: 'pointer'}}>
        <IconButton aria-label="share">
            <Share />
        </IconButton>
        <Typography onClick={dispatchSharingActions}>Teilen</Typography>
    </Box>

};