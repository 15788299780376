import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GamificationLevelService, UserStats} from "../../../services/restserver-openapi";
import {mapErrorMessages} from "../../../utils/errorHandling";

export type UserStatsState = {
    loading: boolean;
    userStats: UserStats | null;
    error: string | null;
};

const initialState: UserStatsState = {
    loading: false,
    userStats: null,
    error: null,
};

export const asyncGetUserStats = createAsyncThunk(
    'userStats/fetch', // Action type
    async () => {
        return GamificationLevelService.getUserLevelStats();
    }
);

export const userStatsSlice = createSlice({
    name: 'userStats',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(asyncGetUserStats.pending, (state) => {
                state.loading = true;
            })
            .addCase(asyncGetUserStats.fulfilled, (state, { payload }) => {
                state.userStats = payload;
                state.loading = false;
            })
            .addCase(asyncGetUserStats.rejected, (state, action) => {
                if (action.error.message) {
                    let errorMessage = action.error.message;
                    state.error = mapErrorMessages({ errorMessage });
                }
                state.loading = false;
            });
    },
});

// export const {} = userStatsSlice.actions;

export const userStatsReducer = userStatsSlice.reducer;