import React, {useMemo} from 'react';
import {useAppSelector} from "../../hooks/hooks";
import {Avatar, Box, Grid, Link, PaletteMode, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {User} from "../../services/restserver-openapi";
import { Main } from '../../layouts';
import Divider from "@mui/material/Divider";
import Page from './Page/Page';
import {paidContactInformation, freeContactInformation} from "../../constants/constants";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

function Support() {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const personId = useAppSelector((state) => state.user.user.person?.personId);
    const userId = useAppSelector((state) => state.login.userId);
    const userRoleRegister = useAppSelector((state) => state.login.userRoleLogin);
    const userSpecificationId = useAppSelector((state) => state.login.userSpecificationId);

    const bookingText = userRoleRegister === User.userRole.HOST ? "den kostenlosen Stellplatzkalender" : "Buchungen vornehmen";

    const roleCode = userRoleRegister === User.userRole.HOST ? "H" : userRoleRegister === User.userRole.VANLIFER ? "V" : userRoleRegister === User.userRole.AGENT ? "A" : "?";

    const userEntitlements = useAppSelector((state) => state.user.entitlements);
    const contactInformation = useMemo(() => {
        if (userEntitlements.some(entitlement => entitlement.featureId === 'showpaidsupportinformation' && entitlement.value === "true")) {
            return paidContactInformation;
        } else {
            return freeContactInformation;
        }
    }, [userEntitlements]);

    return (
        <Main>
            <Page>
                <Box>
                    <Typography variant="h6" gutterBottom fontWeight={700}>
                        Supportinformationen
                    </Typography>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                        Benötigst du Hilfe oder hast du Fragen? Dann kontaktiere uns. Wir helfen dir gerne weiter.
                    </Typography>
                    <Box paddingY={4}>
                        <Divider />
                    </Box>
                    <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography my={3} variant='body1'>Notiere Dir zunächst Deinen persönlichen den Support-Code, falls Du einmal Hilfe von unserem Support brauchst. Um Dir helfen zu können benötigen wir deinen Support-Code. Mit diesem Code erlaubst Du uns auf Deine Daten zuzugreifen, um alle Deine Anliegen zu bearbeiten.</Typography>
                    <Typography my={3} variant='body2' fontWeight={700} align='center'>Dein persönlicher Support-Code lautet: {userId}.{personId}.{roleCode}.{userSpecificationId}</Typography>
                    <Typography my={3} variant='body1'>Wir brauchen Deine Daten, um mit Dir Kontakt aufzunehmen, dich persönlich informieren zu können und auch, um dir unsere Services anbieten zu können. Nur mit deine korrekten Daten kannst du {bookingText} oder später auch andere Produkte/Services nutzen. Bitte pflege Deine Kontaktdaten und halte Sie aktuell.</Typography>
                </Grid>
            </Grid>
                    <Box>
                        <Typography variant="h6" gutterBottom fontWeight={700}>
                            Deine Kontaktmöglichkeiten
                        </Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent={'space-between'}
                        marginBottom={4}
                    >
                        {contactInformation.map((item, i) => (
                            <Link href={item.link} target="_blank" rel="noopener noreferrer" underline="none">

                            <Box
                                key={i}
                                component={ListItem}
                                disableGutters
                                width={'auto'}
                                padding={0}
                            >
                                <Box
                                    component={ListItemAvatar}
                                    minWidth={'auto !important'}
                                    marginRight={2}
                                >
                                    <Box
                                        component={Avatar}
                                        bgcolor={mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main}
                                        width={40}
                                        height={40}
                                    >
                                        {item.icon}
                                    </Box>
                                </Box>
                                    <ListItemText primary={item.label} secondary={item.value} />
                            </Box>
                            </Link>
                        ))}
                    </Box>
                </Box>
            </Page>
        </Main>
);
}

export default Support;
