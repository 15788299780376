import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    Address, Agent,
    Host,
    Person,
    Preferences,
    Subscription,
    SubscriptionEntitlement,
    User,
    Vanlifer
} from "../../services/restserver-openapi";
import {
    createUser,
    getAsyncPreferences, getAsyncSubscriptions,
    getAsyncUserEntitlements,
    getUser,
    putUser,
    setPreferences
} from "../../utils/User/user-openapi-hooks";
import {PreferencesModel} from "../../services/dataModels/PreferencesModel";

export type UserState = {
    hostId?: number;
    vanliferId?: number;
    agentId?: number;
    user: User;
    preferences: PreferencesModel;
    status: 'idle' | 'pending';
    userRole?: string;
    personIsCreated: boolean;
    personIsUpdated: boolean;
    error: boolean;
    userSpecificationId: number;
    entitlements: SubscriptionEntitlement[];
    subscriptions: Subscription[];
    isVerifiedHost: boolean;
    isVerifiedAgent: boolean;
};

export const defaultUser: User = {
    userId: 0,
    person: {
        personId: 0,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        birthday: "",
        gender: Person.gender.OTHER,
        address: {
            line1: "",
            line2: "",
            city: "",
            zipCode: 0,
            state: "",
            country: Address.country.CH,
        }
    },
    userRole: User.userRole.VANLIFER,
}

export const defaultPreferences: PreferencesModel = {
        preferencesId: 0,
        language: Preferences.language.CH,
        currency: Preferences.currency.CHF,
        acceptedAgb: false,
        acceptedSaveAndEdit: false,
        acceptedNotifications: false,
}

const initialState: UserState = {
    hostId: 0,
    vanliferId: 0,
    agentId: 0,
    user: defaultUser,
    preferences: defaultPreferences,
    status: "idle",
    userRole: "",
    personIsCreated: false,
    personIsUpdated: false,
    error: false,
    userSpecificationId: 0,
    entitlements: [],
    subscriptions: [],
    isVerifiedHost: false,
    isVerifiedAgent: false,
}

export const createUserAsync = createAsyncThunk(
    "register/registerAsync",
    async (user: User) => {
        return await createUser(user, user.userRole);
    });

export const getUserAsync = createAsyncThunk(
    "register/getUserAsync",
    async (inputObject: { userSpecificationId: number, userRoleInput: string }) => {
        return await getUser(inputObject.userSpecificationId, inputObject.userRoleInput);
    }
);

export const putUserAsync = createAsyncThunk(
    "register/putUserAsync",
    async (inputObject: { inputUser: User, specificationUserId: number, personId: number, userRole: string, verified: boolean}) => {
        return await putUser(inputObject.inputUser, inputObject.specificationUserId, inputObject.userRole, inputObject.verified);
    }
);

export const postPersonPreferencesAsync = createAsyncThunk(
    "register/postPersonPreferences",
    async (inputObject: {userId: number, preferences: PreferencesModel}) => {
        return await setPreferences(inputObject.userId, inputObject.preferences);
    }
)

export const getPersonPreferencesAsync = createAsyncThunk(
    "register/getPersonPreferences",
    async(userId: number)=>{
        return await getAsyncPreferences(userId);
    }
)

export const getUserEntitlementsAsync = createAsyncThunk(
    "register/getUserEntitlements",
    async () => {
        return await getAsyncUserEntitlements();
    }
)

export const getUserSubscriptionsAsync = createAsyncThunk(
    "register/getUserSubscription",
    async () => {
        return await getAsyncSubscriptions();
    }
)

export const userSlice = createSlice({
        name: 'register',
        initialState,
        reducers: {
            setPersonState: (state, action) => {
            },
            setUserRoleForRegister: (state, action) => {
                state.userRole = action.payload.registerUserRole;
                if (action.payload.registerUserRole === 'HOST') {
                    state.user.userRole = User.userRole.HOST;
                } else if (action.payload.registerUserRole === 'AGENT') {
                    state.user.userRole = User.userRole.AGENT;
                } else {
                    state.user.userRole = User.userRole.VANLIFER;
                }
            },
            setSpecificationIdRegister: (state, action) => {
                state.userSpecificationId = action.payload.userSpecificationId;
            },
            deleteRegisterState: (state) => {
                state.hostId = 0;
                state.vanliferId = 0;
                state.agentId = 0;
                state.user = defaultUser;
                state.preferences = defaultPreferences;
                state.status = "idle";
                state.userRole = "";
                state.personIsCreated = false;
                state.personIsUpdated = false;
                state.error = false;
                state.userSpecificationId = 0;
                state.entitlements = [];
                state.subscriptions = [];
                state.isVerifiedHost = false;
                state.isVerifiedAgent = false;
            }
        },
        extraReducers: (builder) => {
            builder
            .addCase(createUserAsync.pending, (state) => {
                state.status = "pending";
            })
            .addCase(createUserAsync.fulfilled, (state, {payload}) => {
                state.status = "idle";
                if (payload && payload?.user!.person) {
                    state.user.userId = payload?.user.userId;
                    state.user.person!.personId = payload?.user.person.personId;
                    state.user.person!.firstname = payload?.user.person.firstname;
                    state.user.person!.lastname = payload?.user.person.lastname;
                    state.user.person!.email = payload?.user.person.email;
                    state.user.person!.phone = payload?.user.person.phone;
                    state.user.person!.birthday = payload?.user.person.birthday;
                    state.user.person!.gender = payload?.user.person.gender;
                    state.personIsCreated = true;
                    state.userSpecificationId = payload.specificationId;

                    sessionStorage.setItem("userIsRegistered", "true");
                    sessionStorage.setItem("role", state.userRole!);
                    sessionStorage.setItem("userID", `${state.user.userId}`);
                    sessionStorage.setItem("userSpecificationID", `${state.userSpecificationId}`);
                    sessionStorage.setItem("isAuthenticated", "true");
                }
            })
            .addCase(putUserAsync.pending, (state) => {
                state.status = "pending";
            })
            .addCase(putUserAsync.fulfilled, (state, {payload}) => {
                state.status = "idle";
                if (payload && payload?.user!.person) {
                    state.user.userId = payload?.user.userId;
                    state.user.person!.personId = payload?.user.person.personId;
                    state.user.person!.firstname = payload?.user.person.firstname;
                    state.user.person!.lastname = payload?.user.person.lastname;
                    state.user.person!.email = payload?.user.person.email;
                    state.user.person!.phone = payload?.user.person.phone;
                    state.user.person!.birthday = payload?.user.person.birthday;
                    state.user.person!.gender = payload?.user.person.gender;
                    state.userSpecificationId = payload.specificationId;
                    state.personIsUpdated = true;
                }
            })
            .addCase(getUserAsync.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getUserAsync.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getUserAsync.fulfilled, (state, {payload}) => {
                try {
                    if (payload) {
                        state.user = payload?.user!;
                        sessionStorage.setItem("personId", String(state.user.person!.personId));
                        state.vanliferId = (payload as Vanlifer).vanliferId;
                        sessionStorage.setItem("vanliferId", String(state.vanliferId));
                        state.hostId = (payload as Host).hostId;
                        sessionStorage.setItem("hostId", String(state.hostId));
                        state.agentId = (payload as Agent).agentId;
                        sessionStorage.setItem("agentId", String(state.agentId));
                        state.isVerifiedHost =  (payload as Host).verified || false;
                        state.isVerifiedAgent = (payload as Agent).verified || false;
                    }
                } catch (err) {
                    if (err === "Person not found") {
                        state.error = true;
                    }
                }
                state.status = "idle";
            })
            .addCase(postPersonPreferencesAsync.pending, (state)=>{
                state.status = "pending";
            })
            .addCase(postPersonPreferencesAsync.fulfilled, (state, {payload})=>{
                state.preferences = {
                    ...payload,
                    acceptedAgb: payload.acceptedAgb as boolean,
                    acceptedSaveAndEdit: payload.acceptedSaveAndEdit as boolean,
                    acceptedNotifications: payload.acceptedNotifications as boolean
                };
                state.status = "idle";
            })
            .addCase(postPersonPreferencesAsync.rejected, (state, action)=>{
               /* if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }*/
                state.status = "idle";
            })
            .addCase(getPersonPreferencesAsync.pending, (state) =>{
                state.status = "pending";
            })
            .addCase(getPersonPreferencesAsync.fulfilled, (state, {payload})=>{
                if (typeof payload != 'undefined'){
                    state.preferences = {
                        ...payload,
                        acceptedAgb: payload.acceptedAgb as boolean,
                        acceptedSaveAndEdit: payload.acceptedSaveAndEdit as boolean,
                        acceptedNotifications: payload.acceptedNotifications as boolean
                    };
                }
                state.status = "idle";
            })
            .addCase(getPersonPreferencesAsync.rejected, (state)=>{

            })
            .addCase(getUserEntitlementsAsync.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getUserEntitlementsAsync.fulfilled, (state, {payload}) => {
                state.entitlements = payload;
                state.status = "idle";
            })
            .addCase(getUserEntitlementsAsync.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            .addCase(getUserSubscriptionsAsync.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getUserSubscriptionsAsync.fulfilled, (state, {payload}) => {
                state.subscriptions = payload;
                state.status = "idle";
            })
            .addCase(getUserSubscriptionsAsync.rejected, (state) => {
                state.status = "idle";
                state.error = true;
            })
            ;
        }
    }
);

export const {
    setPersonState,
    setUserRoleForRegister,
    setSpecificationIdRegister,
    deleteRegisterState,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
