import React, {useCallback, useEffect} from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import Main from '../layouts/Main';
import Container from '../components/Container';
import Header from '../components/landingpageComponents/Header';
import SchnelleReise from '../components/landingpageComponents/SchnelleReise';
import DeineReise from '../components/landingpageComponents/DeineReise';
import Advantages from '../components/landingpageComponents/Advantages';
import Pricing from '../components/landingpageComponents/Pricing';
import HostApplication from '../components/landingpageComponents/HostApplication';
import TeamAndContact from '../components/landingpageComponents/TeamAndContact';
// import Newsletter from '../components/landingpageComponents/Newsletter';
import Testimonials from '../components/landingpageComponents/Testimonials';
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../hooks/hooks";
import { User } from '../services/restserver-openapi';

function LandingPage() {
  const theme = useTheme();

    let navigate = useNavigate();

    const userRoleLogin = useAppSelector((state) => state.login.userRoleLogin);
    const isAuthenticated = useAppSelector((state) => state.login.isAuthenticated);
    const userIsRegistered = useAppSelector((state) => state.login.userIsRegistered);

    const checkRoleOfLogin = useCallback(()=> {
        if (userIsRegistered && userRoleLogin === User.userRole.HOST) {
            navigate("host/HostOverview");
        } else if (userIsRegistered && userRoleLogin === User.userRole.VANLIFER) {
            navigate("vanlifer/VanliferOverview");
        } else if (userIsRegistered && userRoleLogin === User.userRole.AGENT) {
            navigate("travelAgent/TravelAgentOverview");
        } else if (!userIsRegistered && isAuthenticated) {
            navigate("RegisterNewUser");
        } else {
            navigate("/");
        }
    }, [userIsRegistered, userRoleLogin, navigate, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            checkRoleOfLogin()
        }
    }, [checkRoleOfLogin, isAuthenticated]);


    return (
    <Main>
      <Header />
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
            <div id={'more-info'}>
                <Advantages />
            </div>
        </Container>
      </Box>
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <SchnelleReise />
        </Container>
      </Box>
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <Testimonials />
        </Container>
      </Box>
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      {/*<Box bgcolor={theme.palette.alternate}>*/}
      {/*  <Container>*/}
      {/*    <Newsletter />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <DeineReise />
        </Container>
      </Box>
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <Pricing />
        </Container>
      </Box>
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <TeamAndContact />
        </Container>
      </Box>
      <Divider sx={{marginTop:0, marginBottom:0}}/>
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <HostApplication />
        </Container>
      </Box>
    </Main>
  );
};

export default LandingPage;
