/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Compound } from '../models/Compound';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OfferCompoundService {
    /**
     * Get all compounds. Optional parameters hostId to get only compounds from a host and regionId to filter by region.
     * @param hostId
     * @param regionId
     * @returns Compound OK
     * @throws ApiError
     */
    public static getCompounds(
        hostId?: number,
        regionId?: number,
    ): CancelablePromise<Array<Compound>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compounds',
            query: {
                'hostId': hostId,
                'regionId': regionId,
            },
        });
    }
    /**
     * Update an existing compound by id
     * @param requestBody
     * @returns Compound OK
     * @throws ApiError
     */
    public static updateCompound(
        requestBody: Compound,
    ): CancelablePromise<Compound> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compounds',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add a new compound without Id
     * @param requestBody
     * @returns Compound OK
     * @throws ApiError
     */
    public static addCompound(
        requestBody: Compound,
    ): CancelablePromise<Compound> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compounds',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add a region to a compound with regionId
     * @param compoundId
     * @param regionId
     * @returns Compound OK
     * @throws ApiError
     */
    public static addRegion(
        compoundId: number,
        regionId: number,
    ): CancelablePromise<Compound> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compounds/{compoundId}/regions/{regionId}',
            path: {
                'compoundId': compoundId,
                'regionId': regionId,
            },
        });
    }
    /**
     * Remove a region from a compound with regionId
     * @param compoundId
     * @param regionId
     * @returns Compound OK
     * @throws ApiError
     */
    public static removeRegion(
        compoundId: number,
        regionId: number,
    ): CancelablePromise<Compound> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compounds/{compoundId}/regions/{regionId}',
            path: {
                'compoundId': compoundId,
                'regionId': regionId,
            },
        });
    }
    /**
     * Get a compound by id
     * @param id
     * @returns Compound OK
     * @throws ApiError
     */
    public static getCompound(
        id: number,
    ): CancelablePromise<Compound> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compounds/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Delete a compound by id
     * @param id
     * @returns Compound OK
     * @throws ApiError
     */
    public static deleteCompound(
        id: number,
    ): CancelablePromise<Compound> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compounds/{id}',
            path: {
                'id': id,
            },
        });
    }
}
