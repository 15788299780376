import {Box, Dialog, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

type OfferImagesProp = {
    imageIds: number[];
    altText?: string;
    showLargeImage?: boolean;
};

export default function OfferImages({ imageIds, altText, showLargeImage }: OfferImagesProp) {
    const [open, setOpen] = React.useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState<number | null>(null);

    const handleClickOpen = (index: number) => {
        setSelectedImageIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedImageIndex(null);
        setOpen(false);
    };

    const handleNext = () => {
        if (selectedImageIndex !== null) {
            const nextIndex = (selectedImageIndex + 1) % imageIds.length;
            setSelectedImageIndex(nextIndex);
        }
    };

    const handlePrevious = () => {
        if (selectedImageIndex !== null) {
            const previousIndex = (selectedImageIndex - 1 + imageIds.length) % imageIds.length;
            setSelectedImageIndex(previousIndex);
        }
    };

    return (
        <Box>
            {showLargeImage && imageIds.length > 0 && (
                <ImageListItem onClick={() => handleClickOpen(0)} sx={{ cursor: "pointer" }}>
                <img
                    src={`${process.env.REACT_APP_API_URL as string}/image/${imageIds[0]}`}
                    alt={altText}
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
                </ImageListItem>
            )}
            <ImageList
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: showLargeImage ? "repeat(auto-fill,minmax(125px,0.5fr)) !important" : "repeat(auto-fill,minmax(250px,1fr)) !important",
                    gridAutoColumns: showLargeImage ? "minmax(125px, 0.5fr)" : "minmax(250px, 1fr)",
                }}
            >
                {imageIds.map((imageId, index) => {
                    if (showLargeImage && index === 0) {
                        return null;
                    }

                    return (
                        <ImageListItem key={imageId} onClick={() => handleClickOpen(index)} sx={{ cursor: "pointer" }}>
                            <img src={`${process.env.REACT_APP_API_URL as string}/image/${imageId}`} alt={altText} />
                        </ImageListItem>
                    );
                })}
            </ImageList>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{altText}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {selectedImageIndex !== null && (
                            <img
                                src={`${process.env.REACT_APP_API_URL as string}/image/${imageIds[selectedImageIndex]}`}
                                alt={altText}
                                style={{ width: "99%"}}
                            />
                    )}
                </DialogContent>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                    <IconButton onClick={handlePrevious}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton onClick={handleNext}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Dialog>
        </Box>
    );
}
