import {Button, Typography} from '@mui/material';
import React from 'react';
import {useAppDispatch} from "../../hooks/hooks";
import {resetTourComponentState} from "../../store/Vanlifer/vanliferComponentsSlice";
import {resetTours} from "../../store/Vanlifer/tourSlice";
import BookingTourOverviewComponent from "./BookingTourOverview";
import Box from "@mui/material/Box";

export default function BookingConfirmationComponent() {
    const dispatch = useAppDispatch();

    function onBackClick() {
        dispatch(resetTours());
        dispatch(resetTourComponentState());
    }

    return (
        <Box sx={{border: '10px solid #CCDA10', borderRadius: '6px', margin: '1rem'}}>
            <Box sx={{padding: '1rem'}}>
                <Typography variant="h4">Buchungsbestätigung</Typography>
                <Typography>Vielen Dank für deine Buchung! Bald gehts los</Typography>
            </Box>
            <BookingTourOverviewComponent disableEditing={true} />
            <Button sx={{width: '100px', marginBottom: '1rem', marginLeft: '1rem'}} variant="contained" type="submit"
                    color="primary" onClick={onBackClick}>
                Zurück
            </Button>
        </Box>
    );
}
