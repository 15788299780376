import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getGamificationKudos} from "../../../utils/Vanlifer/gamification";


export type KudosState = {
    loading: boolean;
    kudosMessages: string[];
    kudosLastCheckedAt: number;
    error: string | null;
}

const initialState: KudosState = {
    loading: false,
    kudosMessages: [],
    kudosLastCheckedAt: Date.now(),
    error: null,
}


export const asyncCheckKudos = createAsyncThunk(
    'kudos/getGamificationKudos',
    async (inputObject: {checkKudosSinceDate: number}) => {
        return getGamificationKudos(inputObject.checkKudosSinceDate);
    }
);

export const kudosSlice = createSlice({
    name: 'gamification',
    initialState,
    reducers: {
        setKudos: (state, {payload}: PayloadAction<string[]>) => {
            state.kudosMessages = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncCheckKudos.pending, (state) => {
                state.loading = true;
            })
            .addCase(asyncCheckKudos.fulfilled, (state, {payload}) => {
                state.loading = false;
                state.kudosLastCheckedAt = Date.now();
                state.kudosMessages = payload;
            })
            .addCase(asyncCheckKudos.rejected, (state) => {
                state.loading = false;
            })
    }
});

export const {
    setKudos
} = kudosSlice.actions;

export const kudosReducer = kudosSlice.reducer;