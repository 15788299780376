import {Agent, Host, User, Vanlifer} from "../restserver-openapi";

export function mappingHostModelToDto(userModel: User, specificationUserId: number = 0, hostIsVerified: boolean): Host {
    return {
        "hostId": specificationUserId,
        "user": userModel,
        "verified": hostIsVerified,
    };
}

export function mappingVanliferModelToDto(userModel: User, specificationUserId: number = 0): Vanlifer {
    return {
        "vanliferId": specificationUserId,
        "user": userModel,
    };
}

export function mappingAgentModelToDto(userModel: User, specificationUserId: number = 0, agentIsVerified: boolean): Agent {
    return {
        "agentId": specificationUserId,
        "user": userModel,
        "verified": agentIsVerified,
    };
}

export function remappingModel(inputModel: Host | Vanlifer | Agent): any {
    let outputModel;
    if ('hostId' in inputModel) {
        outputModel = {user: inputModel?.user, specificationId: inputModel.hostId}
    } else if ('vanliferId' in inputModel) {
        outputModel = {user: inputModel?.user, specificationId: inputModel.vanliferId}
    } else if ('agentId' in inputModel) {
        outputModel = {user: inputModel?.user, specificationId: inputModel.agentId}
    } else {
        outputModel = outputModel = {user: inputModel?.user, specificationId: 0}
    }
    return outputModel;
}
