import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Moment} from "moment/moment";

export type SearchFormData = {
    startDate: Moment | null,
    endDate: Moment | null,
    regionId: number | undefined,
}

export type componetState = {
    showPaymentComponent: boolean,
    showEditorComponent: boolean,
    searchFormData: SearchFormData | undefined,
    showBookedComponent: boolean,
}

const initialState: componetState = {
    showPaymentComponent: false,
    showEditorComponent: false,
    searchFormData: undefined,
    showBookedComponent: false,
}

export const vanliferComponentsSlice = createSlice({
    name: 'vanliferComponents',
    initialState,
    reducers: {
        setPaymentComponent: (state, {payload}: PayloadAction<boolean>) => {
            state.showPaymentComponent = payload;
        },
        setEditComponent: (state, {payload}: PayloadAction<boolean>) => {
            state.showEditorComponent = payload;
        },
        setBookedComponent: (state, {payload}: PayloadAction<boolean>) => {
            state.showBookedComponent = payload;
        },
        resetTourComponentState: (state) => {
            state.showPaymentComponent = false;
            state.showEditorComponent = false;
            state.showBookedComponent = false;
        },
        setSearchFormData: (state, {payload}: PayloadAction<SearchFormData | undefined>) => {
            state.searchFormData = payload
        }
    },
})

export const {
    setPaymentComponent,
    setEditComponent,
    setBookedComponent,
    resetTourComponentState,
    setSearchFormData
} = vanliferComponentsSlice.actions
export const vanliferComponentsReducer = vanliferComponentsSlice.reducer
