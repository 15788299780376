import * as React from 'react';
import {useEffect} from "react";
import {OfferReviewService} from "../../../services/restserver-openapi";
import Rating from "@mui/material/Rating";

interface SiteRatingProps {
    siteId: number;
    userRating: number|null;
}

export function SiteRating({siteId, userRating}: SiteRatingProps): React.ReactElement {
    let [rating, setRating] = React.useState<number | null>(null);

    useEffect(() => {
        const fetchRating = async () => {
            let fetchedRating = await OfferReviewService.getAverageSiteRating(siteId);
            setRating(fetchedRating);
        }

        fetchRating();
        // Include userRating as dependency to re-render the component when the userRating changes
    }, [siteId, userRating]);

    if (!rating) return <div></div>;

    return <Rating value={rating} precision={0.5} readOnly/>;
}
