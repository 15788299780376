import * as React from 'react';
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

interface SeparatorProps {
    children: React.ReactNode;
    index: number;
    numberOfElements: number;
}

export function Separator({children, index, numberOfElements}: SeparatorProps): React.ReactElement {
    return (
        <TimelineSeparator>
            {children}
            {index !== numberOfElements - 1 && <TimelineConnector sx={{height: '30px'}}/>}
        </TimelineSeparator>
    );
}
