import {OpenAPI, TourService} from "../../services/restserver-openapi";
import {UserVanliferService} from "../../services/restserver-openapi";

export function setTokenTourInApi(identityToken: string) {
    OpenAPI.TOKEN = identityToken;
}
export const getToursFromUser = (sessionId?: string, vanliferId?: number, tourStatus?: 'BOOKED' | 'CANCELED') =>
    TourService.getTours(sessionId, vanliferId, tourStatus);

export const getGeneratedTours = (fromDate: number, toDate: number, regionId: number) =>
    TourService.generateTours(regionId, fromDate, toDate, undefined);

export const reserveTour = (tourId: number)=>
    TourService.reserveTour(tourId);

export const exchangeStay = (tourId: number, stayId: number, siteId: number)=>
    TourService.replaceSite( tourId, stayId, siteId);

export const changeStayDuration = (tourId: number, stayId: number, newFromDate: number, newToDate: number)=>
    TourService.changeStayDuration(tourId, stayId, newFromDate, newToDate );

export const postBookTour = (tourId: number)=>{
    return TourService.bookTour(tourId);
}

export const changeStayAdditionalInformation = (tourId: number, stayId: number, arrivalTime: string, comment: string)=>
    TourService.changeStayAdditionalInformation(tourId, stayId, arrivalTime, comment);


export const getTourDetail = (tourId: number)=>{
    return TourService.getTour(tourId);
}

export const getStaysBetween = async (fromTimestamp: number, toTimestamp: number, hostId: number) => {
    return TourService.getStaysBetween(fromTimestamp, toTimestamp, hostId);
};

export const getVanliferNameByTourId = async (tourId: number) => {
    try {
        const tour = await TourService.getTour(tourId);
        const x = await UserVanliferService.getVanlifer(tour.vanliferId ?? -1);
        if (x.user && x.user.person) {
            return x.user.person?.firstname + " " + x.user.person?.lastname;
        }
        return "";
        } catch (error) {
        // Handle any errors here
        console.error(error);
        return "";
    }
};

export const cancelTour = (tourId: number) => {
    return TourService.cancelTour(tourId);
}