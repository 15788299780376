/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Size } from '../models/Size';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OfferSizeService {
    /**
     * Get all sizes
     * @returns Size OK
     * @throws ApiError
     */
    public static getSizes(): CancelablePromise<Array<Size>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sizes',
        });
    }
    /**
     * Get a size by id
     * @param id
     * @returns Size OK
     * @throws ApiError
     */
    public static getSize(
        id: number,
    ): CancelablePromise<Size> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sizes/{id}',
            path: {
                'id': id,
            },
        });
    }
}
