import React, {useEffect, useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Fab} from "@mui/material";

interface ScrollToBottomFabProps {
    scrollToElementRef: React.RefObject<HTMLElement>;
}

export default function ScrollToBottomFab({scrollToElementRef}: ScrollToBottomFabProps) {
    const [isButtonVisible, setButtonVisible] = useState<boolean>(true);

    useEffect(() => {
        function handleScroll() {
            const isScrolledToBottom =
                window.innerHeight + window.scrollY + 100>= document.body.offsetHeight;

            setButtonVisible(!isScrolledToBottom);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function scrollToBottom() {
        if (scrollToElementRef.current) {
            scrollToElementRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end"
            });
        }
    }

    return isButtonVisible ? (
        <Fab
            sx={{ position: "fixed", bottom: "2rem", right: "2rem" }}
            color={"primary"}
            onClick={scrollToBottom}
        >
            <KeyboardArrowDownIcon />
        </Fab>
    ) : null;
}