/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState, useCallback} from 'react';
import Box from '@mui/material/Box';
import {GoogleLogin} from "@react-oauth/google";
import {UserToken} from "../../utils/User/UserToken";
import {jwtDecode} from "jwt-decode";
import {setTokenInApi} from "../../utils/User/user-openapi-hooks";
import {loginUser, removeUserFromState, setUserState} from "../../store/navBar/loginSlice";
import {useAppDispatch} from "../../hooks/hooks";
import { Alert } from '@mui/material';
import {deleteRegisterState} from "../../store/navBar/userSlice";
import {resetAvailabilityState} from "../../store/Host/sitesManagement/availabilitySlice";
import {resetTourComponentState} from "../../store/Vanlifer/vanliferComponentsSlice";
import {resetTours} from "../../store/Vanlifer/tourSlice";
import Button from "@mui/material/Button";
import {User} from "../../services/restserver-openapi";


interface RegisterGoogleLoginProps {
    disableNext: (value: boolean) => void;
    handleNext: () => void;
    localUserData: User;
    setLocalUserData: (value: User) => void;
}

const RegisterGoogleLogin:React.FC<RegisterGoogleLoginProps> = ({ disableNext, handleNext, localUserData, setLocalUserData }) => {
    const dispatch = useAppDispatch();
    const [showErrorAccountAlreadyExists, setShowErrorAccountAlreadyExists] = useState(false);


    const handleCallbackResponse = async (response: any) => {
        const identityToken: string = response.credential
        const userObject: UserToken = jwtDecode(identityToken);
        setTokenInApi(identityToken);
        dispatch(setUserState({
            userName: userObject.name,
            token: identityToken,
            picture: userObject.picture,
            loginFirstname: userObject.given_name,
            loginLastname: userObject.family_name,
            loginEmail: userObject.email
        }));
        const loginResult = await dispatch(loginUser(identityToken));
        if (loginResult.payload) {
            setShowErrorAccountAlreadyExists(true);
        } else {
            setLocalUserData({
                ...localUserData,
                "person": {
                    ...localUserData.person,
                    "firstname": userObject.given_name,
                    "lastname": userObject.family_name,
                    "email": userObject.email,
                }
            });
            disableNext(false);
            handleNext();
        }
    }

    const handleSignOut = useCallback(() => {
        setShowErrorAccountAlreadyExists(false);
        dispatch(removeUserFromState());
        dispatch(deleteRegisterState());
        dispatch(resetAvailabilityState());
        dispatch(resetTourComponentState());
        dispatch(resetTours());
        sessionStorage.clear();
    }, [dispatch]);

    useEffect(() => {
        handleSignOut();
        disableNext(true);
    }, [handleSignOut, disableNext]);

    return (
        <Box>
            <Box marginBottom={1}>
                <GoogleLogin onSuccess={(credentialResponse) => {
                    handleCallbackResponse(credentialResponse)
                }} onError={() => {}}/>
            </Box>
            {showErrorAccountAlreadyExists &&
                <Alert
                    severity="error"
                    action={<Button color="inherit" size="small" onClick={handleSignOut}>Logout und mit anderem Google Account erneut versuchen.</Button>}
                >
                    Mit diesem Google Account existiert bereits einen Account.
                </Alert>
            }
        </Box>

);
};

export default RegisterGoogleLogin;