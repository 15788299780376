/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {GoogleLogin} from "@react-oauth/google";
import {UserToken} from "../../utils/User/UserToken";
import {jwtDecode} from "jwt-decode";
import {setTokenInApi} from "../../utils/User/user-openapi-hooks";
import {loginUser, setUserState} from "../../store/navBar/loginSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Alert} from "@mui/material";
import Button from "@mui/material/Button";



const Form = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userHasAccount = useAppSelector((state) => state.login.userIsRegistered);
    const [showWarningNoAccountFound, setShowWarningNoAccountFound] = useState(false)
    const location = useLocation();
    const from = location.state?.from || '/';

    useEffect(() => {
        if (userHasAccount) {
            navigate(from);
        }
    }, [navigate, userHasAccount]);


    const handleCallbackResponse = async (response: any) => {
        const identityToken: string = response.credential
        const userObject: UserToken = jwtDecode(identityToken);
        setTokenInApi(identityToken);
        dispatch(setUserState({
            userName: userObject.name,
            token: identityToken,
            picture: userObject.picture,
            loginFirstname: userObject.given_name,
            loginLastname: userObject.family_name,
            loginEmail: userObject.email
        }));
        const loginResult = await dispatch(loginUser(identityToken));
        if (!loginResult.payload) {
            setShowWarningNoAccountFound(true);
        }
    }

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'text.secondary'}
                >
                    Login
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    Willkommen
                </Typography>
                <Typography color="text.secondary">
                    Logge dich ein.
                </Typography>
            </Box>
            <Box marginBottom={4}>
                <GoogleLogin onSuccess={(credentialResponse) => {
                    handleCallbackResponse(credentialResponse)
                }} onError={() => {}}/>

            </Box>
            {showWarningNoAccountFound &&
                <Alert
                    severity="warning"
                    action={<Button component={Link} color="inherit" size="small" to={"/register/vanlifer"}>Registrieren</Button>}
                >
                    Es existiert noch keinen Account mit diesem Google Login.
                </Alert>
            }

            <Box>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >
                    Noch keinen Account? <Link to="/register/vanlifer">Registrieren</Link>
                </Typography>
                <Typography variant={"body2"} color={"text.secondary"}>
                    Möchtest du Gastgeber werden? <Link to="/register/host">Weitere Informationen</Link>
                </Typography>
            </Box>

        </Box>
    );
};

export default Form;