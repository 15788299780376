import {Card, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import React from 'react';
import hostCampsitePicture from "../../assets/Images/hostCampsite.jpg";
import hostCalendarPicture from "../../assets/Images/calendar.jpg";
import hostProfilePicture from "../../assets/Images/contact.jpg";
import hostGuestsPicture from "../../assets/Images/campingGuests.jpg";
// import VisitorBarChartComponent from "../../components/hostComponents/VisitorBarChartComponent";
import ArrivalComponent from "../../components/hostComponents/ArrivalComponent";
import HostWelcomeHeader from '../../components/hostComponents/HostWelcomeHeader';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Container from '../../components/Container';
import { useTheme } from '@mui/material/styles';
import Main from '../../layouts/Main';

import Advantages from '../../components/landingpageComponents/Advantages';

export const textFragments = {

    title1: "Halte Deine Kontaktdaten aktuell",
    text1: "Du bist interessiert an einer von uns generierten Tour? Gebe dazu ein wohin du reisen möchtest und in welchem Zeitraum. Die Reise gefällt dir noch nicht ganz? Kein Problem - Du hast die Möglichkeit deine Route individuell anzupassen. Nachdem du mit deiner zusammengestellten Reise zufrieden bist, kannst du die Reise buchen und losfahren. Wenn Du mit Deiner Reise zufrieden bist, kannst du die Reise reservieren und losfahren.",

    title2: "Pflege Dein Angebot für Deine Besucher",
    text2: "Unser Angebot ist vollkommen kostenlos und Du behältst dabei zu jeder Zeit die volle Kontrolle darüber, wann und zu welchem Preis zu deine Stellplätze anbieten möchtest.",

    title3: "Nutze Deinen kostenlosen Stellplatzkalender",
    text3: "Unser Angebot ist vollkommen kostenlos und Du behältst dabei zu jeder Zeit die volle Kontrolle darüber, wann und zu welchem Preis zu deine Stellplätze anbieten möchtest.",

    title4: "Begrüsse Deine Gäste von überall (verfügbar ab 2024)",
    text4: "Wir schaffen eine Win-Win-Situation für Reisende und Anbieter. Unsere zukünftige Online-Plattform verbindet die Flexibilität einer Individualreise mit den Vorzügen einer Pauschalreise und wird damit die Art und Weise verändern, wie zukünftig solche Reisen geplant und gebucht werden.",
}

function Overview() {
    const theme = useTheme();

    return (
      <Main>
          <HostWelcomeHeader />
          <Box bgcolor={theme.palette.alternate}>
             <ArrivalComponent  />
          </Box>
      </Main>
    );
}

export default Overview;
