import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeaderImage from '../../../assets/Images/fedor-1PmVgNnU-unsplash.jpg';
import Container from '../../Container';

const RegionPageHeader = (): JSX.Element => {
    useEffect(() => {
        const jarallaxInit = async () => {
            const jarallaxElems = document.querySelectorAll('.jarallax');
            if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
                return;
            }

            const { jarallax } = await import('jarallax');
            jarallax(jarallaxElems, { speed: 0.2 });
        };

        jarallaxInit();
    });

    return (
        <Box
            className={'jarallax'}
            data-jarallax
            data-speed="0.2"
            position={'relative'}
            minHeight={{ xs: 400, sm: 500, md: 600 }}
            display={'flex'}
            alignItems={'center'}
            id="agency__portfolio-item--js-scroll"
        >
            <Box
                className={'jarallax-img'}
                sx={{
                    position: 'absolute',
                    objectFit: 'cover',
                    fontFamily: 'object-fit: cover;',
                    top: "80px",
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${HeaderImage})`,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: 1,
                    height: 1,
                    background: alpha('#161c2d', 0.4),
                    zIndex: 1,
                }}
            />
            <Container position={'relative'} zIndex={2}>
                <Box>
                    <Typography
                        variant="h2"
                        gutterBottom
                        sx={{
                            fontWeight: 900,
                            color: 'common.white',
                            textTransform: 'uppercase',
                        }}
                    >
                        Regionen
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        color="text.primary"
                        sx={{
                            color: 'common.white',
                        }}
                    >
                        Entdecke die Vielfalt unserer Campingregionen für dein nächstes Van-Abenteuer
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default RegionPageHeader;
