import TimelineItem from '@mui/lab/TimelineItem';
import * as React from 'react';
import {OppositeContent} from "./OppositeContent";
import Skeleton from "@mui/material/Skeleton";
import {Content} from "./Content";
import {Separator} from "./Separator";
import TimelineFrame from "./TimelineFrame";

interface TimelineSkeletonProps {
    numberOfElements: number;
}

export function TimelineSkeleton({numberOfElements}: TimelineSkeletonProps): React.ReactElement {
    return (
        <TimelineFrame>
            {Array.from({length: numberOfElements}).map((_, index) =>
                {
                    return (
                        <TimelineItem key={index}>
                            <OppositeContent>
                                <Skeleton width={80}/>
                            </OppositeContent>

                            <Separator index={index} numberOfElements={numberOfElements}>
                                <Skeleton variant="circular" width={36} height={36} sx={{my: '11.5px'}} />
                            </Separator>

                            <Content>
                                <Skeleton width="80%"/>
                            </Content>
                        </TimelineItem>
                    );
                })
            }
        </TimelineFrame>
    );
}