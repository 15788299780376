import * as React from 'react';
import {useEffect} from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useAppDispatch, useAppSelector} from '../hooks/hooks'
import {Box, Stack, useMediaQuery} from "@mui/material";
import {
    removeUserFromState, setStateCookieDrawer,
} from "../store/navBar/loginSlice";
import VanlifeLogo from "./presentationalComponents/VanlifeLogo";
import {Link, useNavigate} from "react-router-dom";
import VanlifeTitle from "./presentationalComponents/VanlifeTitle";
import {deleteRegisterState} from "../store/navBar/userSlice";
import {resetErrorStateSites} from "../store/Host/sitesManagement/siteSlice";
import {resetAvailabilityState, resetErrorStateAvailabilities} from "../store/Host/sitesManagement/availabilitySlice";
import {resetTourComponentState} from "../store/Vanlifer/vanliferComponentsSlice";
import {resetErrorStateTours, resetTours} from "../store/Vanlifer/tourSlice";
import CompoundSwitcher from "./hostComponents/CompoundSwitcher";
import Typography from "@mui/material/Typography";
import {User} from "../services/restserver-openapi";
import ThemeModeToggler from './ThemeModeToggler';
import {agentPages, hostPages, vanliferPages} from "../constants/navigation";
import CookieDrawer from "./homeComponents/CookieDrawer";
import {Login} from "@mui/icons-material";
import Cookies from "js-cookie";

const settings = ['Logout'];

function ResponsiveAppBar() {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const userPicture = useAppSelector((state) => state.login.picture);
    const isAuthenticated = useAppSelector((state) => state.login.isAuthenticated);
    const userIsRegistered = useAppSelector((state) => state.login.userIsRegistered);
    const userRole = useAppSelector(state => state.login.userRoleLogin);
    const isMobile = useMediaQuery('(max-width: 1200px)');
    const isSmallerThanSx = useMediaQuery('(max-width: 370px)');
    const pagesList = isAuthenticated && userRole === User.userRole.HOST ?  hostPages : isAuthenticated && userRole === User.userRole.VANLIFER ? vanliferPages: isAuthenticated && userRole === User.userRole.AGENT ? agentPages : [];

    useEffect(() => {
        if (Cookies.get("globalCookie") !== "cookieSetTrue") {
            dispatch(setStateCookieDrawer({openCookieDrawer: true}));
        }
        dispatch(resetErrorStateTours());
        dispatch(resetErrorStateAvailabilities());
        dispatch(resetErrorStateSites());
        dispatch(resetTourComponentState());
    }, [dispatch]);

    function handleSignOut() {
        dispatch(removeUserFromState());
        dispatch(deleteRegisterState());
        dispatch(resetAvailabilityState());
        dispatch(resetTourComponentState());
        dispatch(resetTours());
        sessionStorage.clear();
        navigate("/");
    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function handleClickedSetting(selectedSetting: string) {
        if (selectedSetting === "Logout") {
            handleSignOut();
        }
        handleCloseUserMenu();
    }

    return (
        <AppBar position="static">
            <Toolbar disableGutters sx={{backgroundColor: 'primary.main', px: 2}}>
                <VanlifeLogo xs='none' md='none' lg='flex' />
                <VanlifeTitle xs='none' md='none' lg='flex' />
                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'none', lg: 'flex'}}}>

                </Box>

                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex', lg: 'none'}}}>
                    {isAuthenticated && userIsRegistered &&(
                        <React.Fragment>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="secondary">
                                <MenuIcon color="secondary"/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top', horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'block', lg: 'none'},
                                }}
                            >
                                {pagesList.map((page) => (
                                    <MenuItem key={page.href}
                                            component={Link}
                                            to={page.href}>
                                        <Typography textAlign={"center"}>{page.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </React.Fragment>
                    )}
                </Box>

                <Box sx={{flexGrow: 1}}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <VanlifeLogo xs={isSmallerThanSx ? 'none' : 'flex'} md='flex' lg='none' />
                        <VanlifeTitle xs='flex' md='flex' lg='none' />
                    </Stack>
                </Box>

                <Stack direction='row' spacing={2} sx={{flexGrow: 100000, display: {xs: 'none', md: 'none', lg: 'flex'}}}>
                    {isAuthenticated &&(
                        <React.Fragment>
                            {pagesList.map((page) => (
                                <Button
                                    variant={"text"}
                                    color={"secondary"}
                                    key={page.href}
                                    component={Link}
                                    to={page.href}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </React.Fragment>
                    )}
                </Stack>

                <Box sx={{ flexGrow: 0 }}>

                    {!isAuthenticated ? (
                        <>
                            {!isMobile && (<ThemeModeToggler />)}
                            <Button
                                variant={"text"}
                                color={"secondary"}
                                key="login"
                                component={Link}
                                to="/login"
                                endIcon={<Login/>}
                            >
                            Login
                            </Button>
                        </>
                    ) : (
                        <React.Fragment>
                            {userRole === User.userRole.HOST && !isMobile && (
                                   <CompoundSwitcher />)}
                            {!isMobile && (<ThemeModeToggler />)}
                            <Tooltip title="Öffne Nutzer Menu">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    {userIsRegistered &&(<Avatar src={userPicture}/>)}
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                    <Menu
                        sx={{mt: '45px', display: {xs: 'block', md: 'block'}}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{vertical: 'top', horizontal: 'right',}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right',}}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={() => handleClickedSetting(setting)}>
                                <Button variant={"text"} color={"primary"} fullWidth>{setting}</Button>
                            </MenuItem>
                        ))}
                        {userRole === User.userRole.HOST && isMobile &&(
                            <MenuItem key="CompoundSwitcher">
                                <CompoundSwitcher isMenuButton={true} />
                            </MenuItem>
                        )}
                        {isMobile && (<MenuItem key="ThemeModeToggler">
                            <ThemeModeToggler isMenuButton={true} />
                        </MenuItem>)}
                    </Menu>
                </Box>
            </Toolbar>
            <CookieDrawer />
        </AppBar>
    );
}

export default ResponsiveAppBar;
