import React from 'react';
import Box from '@mui/material/Box';

interface Props {
  children: React.ReactNode;
  colorInvert?: boolean;
  bgcolor?: string;
}

const Main = ({
  children,
  colorInvert = false,
  bgcolor = 'transparent',
}: Props): JSX.Element => {

  return (
    <Box>
      <main>
        {children}
      </main>
    </Box>
  );
};

export default Main;
