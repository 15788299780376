import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Compound, SubscriptionEntitlement} from '../../services/restserver-openapi';
import {addCompound, getCompoundEntitlements, getCompounds, updateCompound} from "../../utils/Host/offer";

export type CompoundState = {
    loading: boolean;
    error: string | null;
    compounds: Compound[];
    selectedCompound: Compound;
    selectedCompoundEntitlements: SubscriptionEntitlement[];
};

export const defaultCompound: Compound = {
    compoundId: 0,
    hostId: 0,
    regions: [],
    arrivalTime: "",
    departureTime: "",
    wifiAvailable: false,
    name: '',
    guestInformation: '',
    toilet: false,
    shop: false,
    shower: false,
    sewerage: false,
    dogsAllowed: false,
    familyFriendly: false,
    inTheMountains: false,
    byTheWater: false,
    culturalOfferings: false,
    ecoFriendly: false,
    coordinates: undefined,
    externalLink: '',
    addressLine1: '',
    zipCode: 0,
    city: '',
    imageIds: [],
    country: Compound.country.CH
}

const initialState: CompoundState = {
    loading: false,
    error: null,
    compounds: [],
    selectedCompound: defaultCompound,
    selectedCompoundEntitlements: [],
};

export const fetchCompounds = createAsyncThunk(
    'compounds/fetchCompounds',
    async (hostId: number) => {
        return getCompounds(hostId);
});

export const fetchCompoundEntitlements = createAsyncThunk(
    'compounds/fetchCompoundEntitlements',
    async (compoundId: number) => {
        return getCompoundEntitlements(compoundId);
});

export const editCompound = createAsyncThunk(
    'compounds/editCompound',
    async (compound: Compound) => {
        return updateCompound(compound);
});

export const createCompound = createAsyncThunk(
    'compounds/createCompound',
    async (compound: Compound) => {
        return addCompound(compound);
});

const compoundsSlice = createSlice({
    name: 'compounds',
    initialState,
    reducers: {
        setSelectedCompound: (state, action: PayloadAction<Compound>) => {
            state.selectedCompound = action.payload;
            sessionStorage.setItem("selectedCompound", JSON.stringify(state.selectedCompound));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompounds.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCompounds.fulfilled, (state, action: PayloadAction<Compound[]>) => {
                state.loading = false;
                state.error = null;
                state.compounds = action.payload;
                if (action.payload.length > 0) {
                    const selectedCompoundRAW = sessionStorage.getItem("selectedCompound");
                    if (selectedCompoundRAW) {
                        const selectedCompound:Compound = JSON.parse(selectedCompoundRAW);
                        const compound = action.payload.find(compound => compound.name === selectedCompound.name);
                        if (compound) {
                            state.selectedCompound = compound;
                        } else {
                            state.selectedCompound = action.payload[0];
                            sessionStorage.setItem("selectedCompound", JSON.stringify(state.selectedCompound))
                        }
                    } else {
                        state.selectedCompound = action.payload[0];
                        sessionStorage.setItem("selectedCompound", JSON.stringify(state.selectedCompound))
                    }
                }
            })
            .addCase(fetchCompounds.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ? action.error.message : 'An error occurred';
            })
            .addCase(fetchCompoundEntitlements.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCompoundEntitlements.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.loading = false;
                state.error = null;
                state.selectedCompoundEntitlements = action.payload;
            })
            .addCase(editCompound.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editCompound.fulfilled, (state, action: PayloadAction<Compound>) => {
                state.loading = false;
                state.error = null;
                state.selectedCompound = action.payload;
            })
            .addCase(editCompound.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ? action.error.message : 'An error occurred';
            })
            .addCase(createCompound.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCompound.fulfilled, (state, action: PayloadAction<Compound>) => {
                state.loading = false;
                state.error = null;
                state.selectedCompound = action.payload;
            })
            .addCase(createCompound.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ? action.error.message : 'An error occurred';
            });
    },
});

export const { setSelectedCompound } = compoundsSlice.actions;
export const compoundsReducer = compoundsSlice.reducer;
