import React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import {TourOverview} from "../../services/restserver-openapi";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import { formatAsSwissDate } from '../../utils/dateUtil';
import { useNavigate } from 'react-router-dom';

interface BookedTourCardProp {
    tour: TourOverview;
}

function BookedTourCard({tour}: BookedTourCardProp) {
    const navigate = useNavigate();

    const { earliestDate, latestDate } = getTourDateRange(tour);

    let bookedAt: string;
    if (tour.statusDate) {
        bookedAt = formatAsSwissDate(tour.statusDate);
    } else {
        bookedAt = 'Kein Datum'
    }

    function getTourDateRange(tour: TourOverview): { earliestDate: number | undefined; latestDate: number | undefined } {
        let earliestDate: number | undefined;
        let latestDate: number | undefined;

        if (tour.stays && tour.stays.length > 0) {
            for (const stay of tour.stays) {
                if (stay.fromDate !== undefined) {
                    if (earliestDate === undefined || stay.fromDate < earliestDate) {
                        earliestDate = stay.fromDate;
                    }
                }

                if (stay.toDate !== undefined) {
                    if (latestDate === undefined || stay.toDate > latestDate) {
                        latestDate = stay.toDate;
                    }
                }
            }
        }

        return { earliestDate, latestDate };
    }

    return (
            <Card sx={{ cursor: 'pointer' }} elevation={2} onClick={() => navigate(`/vanlifer/Tour/${tour.tourId}`)}>
                <CardContent>
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                        <SearchIcon sx={{mr: '6px'}}/><Typography variant="h5">{tour.name}</Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                        {formatAsSwissDate(earliestDate!)} - {formatAsSwissDate(latestDate!)}
                    </Typography>
                </CardContent>
                <CardContent sx={{pt: 0}}>
                    <Typography variant="body2">
                        Gebucht am {bookedAt}
                    </Typography>
                    <Typography variant="body2">
                        Preis: {tour.price?.toFixed(2)} CHF
                    </Typography>
                    <Typography variant="body2">
                        Tour ID: {tour.tourId}
                    </Typography>
                </CardContent>
            </Card>
    );
}

export default BookedTourCard;