import {Theme} from "@mui/material";

export const createGeneralStyling = (theme: Theme) => ({
    vltPicture: {
        borderRadius: 16, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', width: "100%"
    },
    vltPaper: {
        borderRadius: 5,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '10px',
    },
    vltCard: {
        borderRadius: 5,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    },
    ecoFriendly: {
        color: '#13ab1a'
    },
    rewardTimeline: {
        level: {
            backgroundColor: '#da8610'
        },
        rank: {
            backgroundColor: '#facc00'
        }
    },
    tourProposalCard: {
        missingImage: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light,
            padding: '1rem'
        },
        image: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light,
        }
    }
});