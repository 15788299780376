import {useAppSelector} from "../../hooks/hooks";
import {Box, Card, CardContent, CardMedia, CircularProgress, Grid, useMediaQuery, useTheme} from "@mui/material";
import HereMapTourComponent from "./HereMapTourComponent";
import TourDetailsSummary from "./TourDetailsSummary";
import TourSiteProposal from "./TourSiteProposal";
import React, {useRef} from "react";
import ShareTour from "../presentationalComponents/sharing/ShareTour";
import {BookingTourOverviewSkeleton} from "./BookingTourOverviewSkeleton";

type BookingTourOverviewProp = {
    disableEditing: boolean;
}

export default function BookingTourOverviewComponent(props: BookingTourOverviewProp) {
    const theme = useTheme();
    const choosedTourDetail = useAppSelector(state => state.tour.choosedTourDetail);
    const waypoints = useAppSelector(state => state.tour.generatedCoordinates);
    const loading = useAppSelector(state => state.tour.loading);
    const HERE_MAP_ID = process.env.REACT_APP_HERE_MAP_ID!;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const heightForSmallDevices = '600px';
    const elementRef = useRef<HTMLDivElement>(null);
    const HereCardContent = (): React.ReactElement => {
        return <React.Fragment>
            <CardMedia>
                <HereMapTourComponent
                    apiKey={HERE_MAP_ID}
                    waypoints={waypoints!}
                    height={isSmallScreen? '300px' : '600px'}
                    editorSite={true}
                    tourId={choosedTourDetail.tourId!}
                />
            </CardMedia>
            <CardContent>
                <Grid container direction="row"
                      justifyContent="space-evenly"
                      alignItems="center">
                    <Grid item xs={2} >
                        <ShareTour elementRef={elementRef}/>
                    </Grid>
                    <Grid item xs={8} container alignItems="center" justifyContent="center">
                        <Grid item>
                            <TourDetailsSummary />
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={2} >
                    </Grid>
                </Grid>
            </CardContent>
        </React.Fragment>
    }

    if (loading) {
        return <BookingTourOverviewSkeleton />
    }

    return (
        waypoints !== null && choosedTourDetail !== null
            ? isSmallScreen
                ? <Grid container display={"flex"} justifyContent="space-around">
                    <Grid item xs={11} sm={11.5} md={6} xl={6}>
                        <Box style={{
                            width: '100%',
                            position: !isSmallScreen ? 'fixed' : 'relative',
                            left: !isSmallScreen ? '57%' : '0',
                        }}>
                            <Card ref={elementRef}
                                  style={{
                                    backgroundColor: theme.palette.primary.light,
                                    border: '1.5px solid #cccccc',
                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
                                    borderRadius: '10px'
                                }}>
                                <HereCardContent />
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                        <TourSiteProposal disableEditing={props.disableEditing}/>
                    </Grid>
                </Grid>
                : <Grid container display={"flex"} justifyContent="space-around">
                    <Grid item xs={12} sm={12} md={6.8} xl={6.8}>
                        <TourSiteProposal disableEditing={props.disableEditing}/>
                    </Grid>
                    <Grid item xs={11} sm={11} md={5.2} xl={5.2}>
                        <Box style={{
                            width: '40%',
                            position: !isSmallScreen ? 'fixed' : 'relative',
                            left: !isSmallScreen ? '57%' : '0',
                        }}>
                            <Card ref={elementRef} style={{
                                backgroundColor: theme.palette.primary.light,
                                border: '1px solid #cccccc',
                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
                                borderRadius: '5px'
                            }}>
                                <HereCardContent />
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            : <CircularProgress />
    );
}