import {Theme} from "@mui/material";

export const createVanliferOverviewStyling = (theme: Theme) => ({
    gridContainer: {
        backgroundSize: 'cover',
        backgroundPosition: 'center 75%',
        fontSize: '3rem',
        backgroundClip: 'content-box',
        justifyContent: "center",
        alignItems: "center"
    },
    gridItem: {
        display: 'flex', alignItems: 'center', justifyContent: "center", padding: '16px',
    },
    cardLabel: {
        top: '10%',
        left: '8%',
        position: 'absolute',
        zIndex: 2,
        backgroundColor: theme.palette.primary.light,
        borderRadius: 2,
        paddingLeft: 1,
        paddingRight: 2,
        border: `1px solid ${theme.palette.primary.dark}`,
        color: '#000',
        fontWeight: 'bold',

    }
});
