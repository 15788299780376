/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GamificationKudosService {
    /**
     * Get the kudos messages of all rewards of a user since a given date
     * @param checkKudosSinceDate The datetime is in UTC as milliseconds at midnight (example: 02.06.2023 -> 1685664000000), to create a timestamp in this format use https://www.timestamp-converter.com
     * @returns string OK
     * @throws ApiError
     */
    public static getKudos(
        checkKudosSinceDate: number,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gamification/user/kudos/{checkKudosSinceDate}',
            path: {
                'checkKudosSinceDate': checkKudosSinceDate,
            },
        });
    }
}
