import * as React from 'react';
import TimelineContent from "@mui/lab/TimelineContent";

interface ContentProps {
    children: React.ReactNode;
}

export function Content({children}: ContentProps): React.ReactElement {
    return (
        <TimelineContent sx={{mt: '11.5px'}}>
            {children}
        </TimelineContent>
    );
}
