import React from 'react';

import {Card, CardContent, CardMedia, Grid, Box, Typography, useTheme} from '@mui/material';
import {createGeneralStyling} from "../../theme/generalStyling"
import {StayDetail, TourDetail} from "../../services/restserver-openapi";
import moment from "moment/moment";
import ImageIcon from "@mui/icons-material/Image";

type StayCardProp = {
    stay: StayDetail,
    tour: TourDetail
}

function StayCard(props: StayCardProp) {
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);

    const compoundHasPicture = (props.stay.stayCompound?.imageIds?.length ?? 0) > 0;
    const compoundPictureUrl = compoundHasPicture && props.stay.stayCompound?.imageIds ? `${process.env.REACT_APP_API_URL as string}/image/${props.stay.stayCompound?.imageIds[0] ?? ''}` : '';

    let price = props.stay.price ? props.stay.price.toFixed(2) + " CHF" : 'Keine Information';
    return (
        <Card sx={{ ...generalStyling.vltCard, border: `1px solid ${theme.palette.primary.light}`, }}>
                <CardMedia>
                    {compoundHasPicture ? (<CardMedia
                        component="img"
                        src={compoundPictureUrl}
                        alt={props.stay.stayCompound?.name}
                        sx={{ height: '100%' }}
                    />) : ( <Box sx={generalStyling.tourProposalCard.missingImage}>
                            <ImageIcon fontSize="large" color="disabled"/>
                            <Typography component={"article"} variant="body1" sx={{textAlign: 'center'}}>Kein Bild
                                vorhanden.</Typography>
                        </Box>
                    )}
                </CardMedia>
                <CardContent>
                    <Grid container my={1} rowSpacing={0.1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography color="text.secondary">
                                Anreisedatum: {moment(props.stay.fromDate).format('DD.MM.YYYY')}
                            </Typography>
                            <Typography color="text.secondary">
                                Abreisedatum: {moment(props.stay.toDate).format('DD.MM.YYYY')}
                            </Typography>
                            <Typography color="text.secondary">
                                Stellplatz: {props.stay.staySite?.label}
                            </Typography>
                            <Typography color="text.secondary">
                                Vor Ort zu bezahlen: {price}
                            </Typography>
                        </Grid>
                        {props.stay.stayCompound?.guestInformation ? (
                            <>
                                <Grid item xs={12} sm={12} md={12} marginTop={2}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Allgemeine Informationen vom Host
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" marginTop={-1}>
                                        { props.stay.stayCompound?.guestInformation}
                                    </Typography>
                                </Grid>
                        </>
                            ) : (<></>)}
                        {props.stay.staySite?.guestInformation ? (
                            <>
                                <Grid item xs={12} sm={12} md={12} marginTop={2}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Informationen zu deinem Stellplatz {props.stay.staySite?.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" marginTop={-1}>
                                        {props.stay.staySite?.guestInformation}
                                    </Typography>
                                </Grid>
                            </>
                            ) : (<></>)}
                    </Grid>
                </CardContent>
        </Card>
    );
}

export default StayCard;
