import React, { useEffect } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CircularProgress, PaletteMode,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { fetchUpcomingStays } from '../../store/Host/sitesManagement/upcomingStays';
import Alert from '@mui/material/Alert';
import Container from '../Container';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

function ArrivalComponent() {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const dispatch = useAppDispatch();
  const hostId = useAppSelector((state) => state.login.userSpecificationId);
  const person = useAppSelector((state) => state.user.user.person);
  const compoundId = useAppSelector(state => state.compounds.selectedCompound?.compoundId);
  const upcomingStays = useAppSelector(
    (state) => state.upcomingStays.upcomingStays,
  );
  const loading = useAppSelector((state) => state.upcomingStays.loading);

  useEffect(() => {
    if (hostId && hostId !== 0) {
      dispatch(fetchUpcomingStays(hostId));
    }
  }, [dispatch, hostId]);

  const convertTimeToMinutes = (timeString: string): number => {
    const [hours, minutes] = timeString.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  };

  const sortedStays = upcomingStays.slice().filter(s => s.compoundId === compoundId).sort((a, b) => {
    const dateA = new Date(a.fromDate!).setHours(0, 0, 0, 0);
    const dateB = new Date(b.fromDate!).setHours(0, 0, 0, 0);

    if (dateA !== dateB) {
      return dateA - dateB;
    }

    const timeA = convertTimeToMinutes(a.arrivalTime!);
    const timeB = convertTimeToMinutes(b.arrivalTime!);
    return timeA - timeB;
  });


  function formatStayDate(timestamp: number): string {
    const date = new Date(timestamp);
    const now = new Date();

    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const rtf = new Intl.RelativeTimeFormat('de-CH', { numeric: 'auto' });

    const timeDifference = date.getTime() - now.getTime();
    const diffInDays = timeDifference / (1000 * 60 * 60 * 24);

    if (diffInDays < 0) {
      return date.toLocaleDateString('de-CH');
    } else if (diffInDays >= 0 && diffInDays <= 1) {
      return rtf.format(diffInDays, 'day');
    } else {
      return date.toLocaleDateString('de-CH');
    }
  }

  const isToday = (date: Date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate();
  };

  return (
    <Container>
      <Box marginBottom={2}>
        <Typography
          variant="h3"
          color="text.primary"
          sx={{ fontWeight: 700 }}
        >
          Begrüsse Deine{' '}
          <Typography
            color={'text.primary'}
            component={'span'}
            variant={'inherit'}
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                0.3,
              )} 0%)`,
            }}
          >
            nächsten Gäste:
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h6" component="p" color="text.secondary">
          In den nächsten 14 Tagen darfst Du folgende Gäste bei Dir begrüssen. Wir freuen uns mit Dir!
        </Typography>
      </Box>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 200,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {sortedStays.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }}>
                <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Stellplatz
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Vanlifer
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Ankunftsdatum
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Ankunftszeit
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Abreisedatum
                      </Typography>
                    </TableCell>
                    <TableCell width={500}>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Bemerkungen
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedStays.map((stay, index) => (
                    <TableRow key={index}
                    // Alternate color          sx={{'&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(2n)': { bgcolor: 'alternate.main' }, }}
                    >
                      <TableCell component="th" scope="row" style={{
                        fontWeight: isToday(new Date(stay.fromDate!)) ? 'bold' : 'normal',
                        gridColumn: 'span 1',
                      }}>{stay.siteLabel}</TableCell>
                      <TableCell style={{
                        fontWeight: isToday(new Date(stay.fromDate!)) ? 'bold' : 'normal',
                        gridColumn: 'span 1',
                      }}>{stay.vanliferName}</TableCell>
                      <TableCell style={{
                        fontWeight: isToday(new Date(stay.fromDate!)) ? 'bold' : 'normal',
                        gridColumn: 'span 1',
                      }}>{formatStayDate(stay.fromDate!)}</TableCell>
                      <TableCell style={{
                        fontWeight: isToday(new Date(stay.fromDate!)) ? 'bold' : 'normal',
                        gridColumn: 'span 1',
                      }}>{stay.arrivalTime}</TableCell>
                      <TableCell style={{
                        fontWeight: isToday(new Date(stay.fromDate!)) ? 'bold' : 'normal',
                        gridColumn: 'span 1',
                      }}>{formatStayDate(stay.toDate!)}</TableCell>
                      <TableCell width="1000"
                                 style={{ fontWeight: isToday(new Date(stay.fromDate!)) ? 'bold' : 'normal' }}>{stay.comment}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Paper style={{ padding: 16, textAlign: 'center' }}>
              {compoundId ? (
                <Alert severity="info">
                  <Typography variant="subtitle1">Es gibt keine bevorstehenden Besuche für den
                    ausgewählten Campingplatz.</Typography>
                </Alert>
              ) : (
                <Alert severity="warning">
                  <Typography variant="subtitle1">Du hast noch keinen Campingplatz ausgewählt.</Typography>
                </Alert>
              )}
            </Paper>
          )}
        </div>
      )}
    </Container>
  );
}

export default ArrivalComponent;
