import React, {useState} from 'react';
import {Button, Grid, TextField, Typography} from '@mui/material';
import TimePickerVanlife from '../presentationalComponents/TimePickerVanlife';
import moment, {Moment} from "moment";
import {StayDetail, TourDetail} from "../../services/restserver-openapi";
import {asyncChangeStayAdditionalInformation} from "../../store/Vanlifer/tourSlice";
import {useAppDispatch} from "../../hooks/hooks";

type AdditionalInfoFormComponentProps = {
    stay: StayDetail,
    tour: TourDetail
}
function AdditionalInfoFormComponent(props: AdditionalInfoFormComponentProps) {
    const [arrivalTime, setArrivalTime] = useState<Moment | null>(
        moment((props.stay.arrivalTime || (props.stay.stayCompound?.arrivalTime || 'T15:00:00')), 'THH:mm:ss')
    );
    const [comment, setComment] = useState(props.stay.comment || '');
    const [successMessage, setSuccessMessage] = useState('');

    const dispatch = useAppDispatch();

    const handleArrivalTimeChange = (newValue: Moment | null) => {
        setArrivalTime(newValue);
    };
    const handleCommentChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setComment(event.target.value);
    };

    const handleSave = () => {
        if (props.tour.tourId && props.stay.stayId && arrivalTime) {
            const stayAdditionalInformation = {
                tourId: props.tour.tourId,
                stayId: props.stay.stayId,
                newArrivalTime: arrivalTime?.format('HH:mm:ss'),
                newComment: comment
            }
            dispatch(asyncChangeStayAdditionalInformation(stayAdditionalInformation));

            setSuccessMessage('Save was successful!');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000); // Clear the message after 3 seconds
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} mb={4}>
                <Typography variant="h4" component="h1">
                    Dein kommender Aufenthalt bei {props.stay.stayCompound?.name} steht an
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Bitte fülle das folgende Formular aus, um dem Gastgeber weitere Informationen für deinen Aufenthalt mitzuteilen. Dies hilft dem Gastgeber, sich auf deine Ankunft vorzubereiten.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Du kannst deine voraussichtliche Ankunftszeit und eventuelle Bemerkungen eintragen. Bemerkungen sind Informationen, die du dem Gastgeber mitteilen willst. Falls der Gastgeber weiterführende Informationen wünscht, kannst du diese ebenfalls bei den Bemerkungen hinterlegen. <b>Denke daran, das Formular mit dem "An den Gastgeber schicken"-Knopf zu übermitteln</b>
                </Typography>
                <Typography variant="body1" gutterBottom >
                    Sollten sich deine Pläne ändern und du kurzfristig eine neue Ankunftszeit planst, bitten wird dich das Formular erneut über denselben Link erneut zu öffnen und aktualisieren. Somit ist der Gastgeber immer auf dem neuesten Stand.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <TimePickerVanlife
                    handleChange={handleArrivalTimeChange}
                    value={arrivalTime}
                    label="Ankunftszeit"
                    helperText="Bitte gib hier deine voraussichtliche Ankunftszeit an"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField
                    margin="dense"
                    fullWidth
                    label="Bemerkungen"
                    multiline
                    rows={4}
                    value={comment}
                    onChange={handleCommentChange}
                    helperText="Hier kannst du Bemerkungen hinterlassen, die deinem Gastgeber hilfreich sein könnten. Das können spezielle Anforderungen sein, die du während deines Aufenthalts hast, oder weiterführende Informationen, die der Gastgeber wünscht. Wenn du keine besonderen Anmerkungen hast, lass dieses Feld einfach frei"
                />
            </Grid>
            <Grid container spacing={2}>
                <Grid item >
                    <Button variant="contained" color='primary' type="submit" sx={{ width: '300px', float: 'left'}} onClick={handleSave}>An den Gastgeber schicken</Button>
                </Grid>
            </Grid>
            {successMessage && <Grid item xs={12} sm={12} md={12} paddingTop={3}>
                <Typography variant="body1" style={{ color: 'green' }}>
                    {successMessage}
                </Typography>
            </Grid>
            }

        </Grid>
    );
}

export default AdditionalInfoFormComponent;
