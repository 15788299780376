/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Site } from '../models/Site';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OfferSiteService {
    /**
     * Get all sites or filter by compoundId
     * @param compoundId
     * @returns Site OK
     * @throws ApiError
     */
    public static getSites(
        compoundId?: number,
    ): CancelablePromise<Array<Site>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites',
            query: {
                'compoundId': compoundId,
            },
        });
    }
    /**
     * Update an existing site by Id
     * @param requestBody
     * @returns Site OK
     * @throws ApiError
     */
    public static updateSite(
        requestBody: Site,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sites',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add a new site without Id
     * @param requestBody
     * @returns Site OK
     * @throws ApiError
     */
    public static addSite(
        requestBody: Site,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sites',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a site by Id. Optional parameter hostId to get only sites from a host.
     * @param id
     * @returns Site OK
     * @throws ApiError
     */
    public static getSite(
        id: number,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Delete a site by Id
     * @param id
     * @returns Site OK
     * @throws ApiError
     */
    public static deleteSite(
        id: number,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sites/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get amount of owned site for current logged in host. An agent can also request the amount of sites for a host by providing the hostId.
     * @param hostId
     * @returns number OK
     * @throws ApiError
     */
    public static getAmountOfSites(
        hostId?: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/amount',
            query: {
                'hostId': hostId,
            },
        });
    }
}
