import React, {useRef, useState} from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/system";
import ImageList from "@mui/material/ImageList";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const InputStyled = styled("input")({
    display: "none",
});

interface UploadPicturesProps {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDelete: (index: number) => void;
    selectedFiles: File[];
}

export default function UploadPictures({
                                           handleChange,
                                           handleDelete,
                                           selectedFiles,
                                       }: UploadPicturesProps) {
    const fileInputField = useRef<HTMLInputElement>(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    const openDeleteConfirmation = (index: number) => {
        setDeleteIndex(index);
        setDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
        setDeleteIndex(null);
        setDeleteConfirmationOpen(false);
    };

    const confirmDelete = () => {
        if (deleteIndex !== null) {
            handleDelete(deleteIndex);
        }
        closeDeleteConfirmation();
    };

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={2}>
                <label htmlFor="icon-button-file">
                    <InputStyled
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        multiple
                        onChange={handleChange}
                        ref={fileInputField}
                    />
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => fileInputField.current?.click()}>
                        <PhotoCamera />
                    </IconButton>
                </label>
                <ImageList sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fill,minmax(150px,1fr)) !important",
                    gridAutoColumns: "minmax(150px, 1fr)",
                }}>
                    {selectedFiles.map((file, index) => (
                        <ImageListItem key={index}>
                            <img
                                src={URL.createObjectURL(file)}
                                alt={`file preview ${index}`}
                                style={{ width: "100%", height: "100%" }}
                            />
                            <ImageListItemBar
                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        onClick={() => openDeleteConfirmation(index)}
                                        sx={{ color: 'white' }}
                                        aria-label="delete image"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>

                <Dialog open={deleteConfirmationOpen} onClose={closeDeleteConfirmation}>
                    <DialogTitle>Bild Löschen</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            Möchtest du dieses Bild wirklich löschen?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteConfirmation} color="primary" variant="outlined">
                            Abbrechen
                        </Button>
                        <Button onClick={confirmDelete} color="secondary" variant="contained">
                            Löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </>
    );
}
