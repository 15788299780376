import {createSlice} from "@reduxjs/toolkit";

export const campingManagementSlice = createSlice({
        name: 'campingManagement',
        initialState: {
            campingName: "",
            arrivalTime: "",
            departureTime: "",
            wifiAvailable: false,
            supermarket: false,
            restaurant: false,
            latrineEmptying: false,
        },
        reducers: {

        }
    }
);

// export const { } = campingManagementSlice.actions;
export const campingManagementReducer = campingManagementSlice.reducer;
