import {
    OpenAPI, SubscriptionService,
    TokenAuthorization,
    User, UserAgentService,
    UserHostService,
    UserLoginService,
    UserPreferencesService, UserService,
    UserVanliferService
} from '../../services/restserver-openapi';
import {
    mappingAgentModelToDto,
    mappingHostModelToDto,
    mappingVanliferModelToDto,
    remappingModel
} from "../../services/dataModels/UserModel";
import {mappingPreferencesModelToDto, PreferencesModel} from "../../services/dataModels/PreferencesModel";

const {getAuthorization} = UserLoginService;
const {getUserSpecificationId} = UserService;
const {createHostUser, updateHost, getHost, deleteHost} = UserHostService;
const {createVanliferUser, getVanlifer, updateVanlifer, deleteVanlifer} = UserVanliferService;
const {createAgentUser, updateAgent, getAgent, deleteAgent} = UserAgentService;
const {updatePreferences, getPreferences} = UserPreferencesService;
const {getUserEntitlements, getUserSubscriptions} = SubscriptionService;


export function setTokenInApi(identityToken: string) {
    OpenAPI.TOKEN = identityToken;
}

export const getUserToken = async (token: string) => {
    try {
        const token : TokenAuthorization = await getAuthorization();
        return token;
    } catch (error) {
        throw new Error();
    }
}


export const createUser = async (inputUser: User, userRole: string | undefined) => {
    try {
        if (userRole === 'HOST') {
            const inputHost = mappingHostModelToDto(inputUser, 0, false);
            const response = await createHostUser(inputHost);
            return remappingModel(response);
        } else if (userRole === 'VANLIFER') {
            const inputVanlifer = mappingVanliferModelToDto(inputUser);
            const response = await createVanliferUser(inputVanlifer);
            return remappingModel(response);
        } else if (userRole === 'AGENT') {
            const inputAgent = mappingAgentModelToDto(inputUser, 0, false);
            const response = await createAgentUser(inputAgent);
            return remappingModel(response);
        }
    } catch (error) {
        throw new Error();
    }
}

export const putUser = async (inputUser: User, specificationUserId: number, userRole: string, verified: boolean = false) => {
    try {
        if (userRole === User.userRole.HOST) {
            const inputHost = mappingHostModelToDto(inputUser, specificationUserId, verified);
            const response = await updateHost(specificationUserId, inputHost);
            const remapptModel = remappingModel(response);
            return remapptModel;
        } else if (userRole === User.userRole.VANLIFER) {
            const inputVanlifer = mappingVanliferModelToDto(inputUser, specificationUserId);
            const response = await updateVanlifer(specificationUserId, inputVanlifer);
            const remapptModel = remappingModel(response);
            return remapptModel;
        } else {
            const inputAgent = mappingAgentModelToDto(inputUser, specificationUserId, verified);
            const response = await updateAgent(specificationUserId, inputAgent);
            const remapptModel = remappingModel(response);
            return remapptModel;
        }
    } catch (error) {
        throw new Error();
    }
}

export const deleteUser = async (specificationUserId: number, userRole: string) => {
    try {
        if (userRole === User.userRole.HOST) {
            return deleteHost(specificationUserId);
        } else if (userRole === User.userRole.VANLIFER) {
            return deleteVanlifer(specificationUserId);
        } else {
            return deleteAgent(specificationUserId);
        }
    } catch (error) {
        throw new Error();
    }
}

export const toggleUserVerification = async (inputUser: User, specificationId: number, userRole: string, newVerifiedValue: boolean) => {
    try {
        if (userRole === User.userRole.HOST) {
            const inputHost = mappingHostModelToDto(inputUser, specificationId, newVerifiedValue);
            return updateHost(specificationId, inputHost);
        } else if (userRole === User.userRole.AGENT) {
            const inputAgent = mappingAgentModelToDto(inputUser, specificationId, newVerifiedValue);
            return updateAgent(specificationId, inputAgent);
        } else {
            return undefined;
        }
    } catch (error) {
        throw new Error();
    }
}


export const getUser = async (specificationUserId: number, userRole: string) => {
    try {
        if (userRole === User.userRole.HOST) {
            return getHost(specificationUserId);
        } else if (userRole === User.userRole.VANLIFER) {
            return getVanlifer(specificationUserId);
        } else if (userRole === User.userRole.AGENT) {
            return getAgent(specificationUserId);
        } else {
            return undefined;
        }
    } catch (err) {
        throw new Error("Person not found");
    }
}

export const setPreferences = async (userId: number, inputPreferences: PreferencesModel) => {
    const mappedPreferences = mappingPreferencesModelToDto(userId, inputPreferences);
    try {
       return  updatePreferences(userId, mappedPreferences);
    } catch {
        throw new Error("Cannot add Preferences");
    }
}

export const getAsyncPreferences = async (userId: number) => {
    try {
        return getPreferences(userId);
    } catch {
        throw new Error("Preferences not Found");
    }
}

export const getAsyncUserEntitlements = async (userId: number|undefined = undefined) => {
    try {
        return getUserEntitlements(userId);
    } catch {
        throw new Error("Entitlements not Found");
    }
}

export const getAsyncSubscriptions = async (userId: number|undefined = undefined) => {
    try {
        return getUserSubscriptions(userId);
    } catch {
        throw new Error("Subscription not Found");
    }
}

export const getSpecificationId = async (userId: number, userRoleInput: User.userRole) => {
    try {
        return getUserSpecificationId(userId, userRoleInput);
    }   catch(err) {
        throw new Error("SpecificationId not Found");
    }
}

export const getAllUsers = async (userRole: User.userRole, filter: string, pageNumber: number, pageSize: number, direction: "ASC" | "DESC", orderBy: string) => {
    try {
        return UserService.getUserOverview(userRole, filter, pageNumber, pageSize, direction, orderBy);
    } catch {
        throw new Error("Users not Found");
    }
}