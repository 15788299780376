/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Agent } from '../models/Agent';
import type { VanliferErrorResponse } from '../models/VanliferErrorResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserAgentService {
    /**
     * Get an agent by id
     * @param agentId
     * @returns Agent OK
     * @throws ApiError
     */
    public static getAgent(
        agentId: number,
    ): CancelablePromise<Agent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/agents/{agentId}',
            path: {
                'agentId': agentId,
            },
        });
    }
    /**
     * Update an existing agent by id
     * @param agentId
     * @param requestBody
     * @returns Agent OK
     * @throws ApiError
     */
    public static updateAgent(
        agentId: number,
        requestBody: Agent,
    ): CancelablePromise<Agent> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/agents/{agentId}',
            path: {
                'agentId': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an agent
     * @param agentId
     * @returns VanliferErrorResponse OK
     * @throws ApiError
     */
    public static deleteAgent(
        agentId: number,
    ): CancelablePromise<VanliferErrorResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/agents/{agentId}',
            path: {
                'agentId': agentId,
            },
        });
    }
    /**
     * Create a new agent user
     * @param requestBody
     * @returns Agent OK
     * @throws ApiError
     */
    public static createAgentUser(
        requestBody: Agent,
    ): CancelablePromise<Agent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/agents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
