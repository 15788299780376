import React, {FormEventHandler, useState} from 'react';
import {Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    postPersonPreferencesAsync,
} from "../../store/navBar/userSlice";
import {welcomeRegisterPage} from "../../constants/textConstant";
import {PreferencesModel} from "../../services/dataModels/PreferencesModel";
import {Main} from "../../layouts";
import Page from "./Page/Page";
import Divider from "@mui/material/Divider";

export default function NotificationPreferencesEditor() {
    const dispatch = useAppDispatch();
    const storedPreferences = useAppSelector(state => state.user.preferences);
    const userId = useAppSelector(state => state.login.userId);
    const [localPreferences, setLocalPreferences] = useState<PreferencesModel>(storedPreferences ? storedPreferences :
        {
            "acceptedAgb": true,
            "acceptedSaveAndEdit": true,
            "acceptedNotifications": false,
        }
    )
    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        if (userId) {
            dispatch(postPersonPreferencesAsync({userId, preferences: localPreferences}));
        }
    };

    const handleCheckboxFeature = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "acceptedNotifications") {
            setLocalPreferences({ ...localPreferences, acceptedNotifications: !localPreferences.acceptedNotifications });
        }
    }

    return (
        <Main>
            <Page>
                <Box>

        <form style={{ flexGrow: '0' }} onSubmit={handleSubmit}>
            <FormControl>
                <Typography variant="h6" gutterBottom fontWeight={700}>
                    Einwilligung zur Benachrichtigung
                </Typography>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                    Die Vanlife Travel GmbH verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir verwenden Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von Ihnen angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie gerne zusätzlich über unsere Produkte und Dienstleistungen sowie andere Inhalte, die für Sie von Interesse sein könnten, informieren. Wenn Sie damit einverstanden sind, aktivieren Sie bitte das folgende Kontrollkästchen.
                </Typography>
                <Box paddingY={4}>
                    <Divider />
                </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel 
                                control={<Checkbox checked={localPreferences.acceptedNotifications} onChange={handleCheckboxFeature}
                                    name="acceptedNotifications" sx={{ fontWeight: 'bold' }}></Checkbox>}
                                label={<Typography variant="body2" sx={{ fontWeight: 'bold' }}>{welcomeRegisterPage.formControlNewsletter}</Typography>} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography my={3} variant='subtitle2' color={'text.secondary'}>{welcomeRegisterPage.agbInfos}</Typography>
                        </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item >
                                <Button variant="contained" color='primary' type="submit" sx={{ width: '100px', float: 'left'}}>Speichern</Button>
                            </Grid>
                        </Grid>
            </FormControl>
        </form>
                </Box>
            </Page>
        </Main>
    );
}
