import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import TimelineDot from "@mui/lab/TimelineDot";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import StarIcon from "@mui/icons-material/Star";
import TimelineItem from "@mui/lab/TimelineItem";
import {HistoryEntry} from "../../../../services/restserver-openapi";
import Typography from "@mui/material/Typography";
import {OppositeContent} from './OppositeContent';
import {Separator} from './Separator';
import {Content} from "./Content";
import Button from "@mui/material/Button";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import TimelineFrame from "./TimelineFrame";
import {createGeneralStyling} from "../../../../theme/generalStyling";

interface RewardTimelineProps {
    history: HistoryEntry[];
}

export function RewardTimeline({history}: RewardTimelineProps): React.ReactElement {
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);

    const layoutIsMedium = useMediaQuery(theme.breakpoints.down("md"));
    const [entriesShown, setEntriesShown] = useState<number>(layoutIsMedium ? 4 : 6);
    const [initialRender, setInitialRender] = useState(true);
    const scrollToRef = useRef<HTMLButtonElement>(null);

    function handleShowMoreClick() {
        setEntriesShown(entriesShown + 5);
    }

    useEffect(() => {
        if (!initialRender) {
            // Function to check if an element is in the user's view
            const isInView = (element: any) => {
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            };

            // Scroll to the last added item in the timeline
            if (scrollToRef.current && !isInView(scrollToRef.current)) {
                scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'end'});
            }
        } else {
            // Set initial render flag to false after the first render
            setInitialRender(false);
        }
    }, [entriesShown, initialRender]);

    const renderIcon = (type: string) => {
        switch (type) {
            case "badge":
                return (
                    <TimelineDot color="secondary">
                        <EmojiEventsIcon/>
                    </TimelineDot>
                );
            case "xp":
                return (
                    <TimelineDot color="primary">
                        <ControlPointIcon/>
                    </TimelineDot>
                );
            case "level":
                return (
                    <TimelineDot sx={generalStyling.rewardTimeline.level}>
                        <ArrowUpwardIcon/>
                    </TimelineDot>
                );
            case "rank":
                return (
                    <TimelineDot sx={generalStyling.rewardTimeline.rank}>
                        <StarIcon/>
                    </TimelineDot>
                );
            default:
                return <TimelineDot/>;
        }
    }

    const renderTimelineElement = (element: HistoryEntry, index: number) => {
        if (history === null) {
            console.error('History is null');
            return;
        }

        const date = new Date(element.date as string);
        const localeDateString = date.toLocaleDateString('de-CH', {
            year: 'numeric',
            month: 'numeric',
            day: '2-digit',
        });

        return (
            <TimelineItem key={index}>
                <OppositeContent>
                    {localeDateString}
                </OppositeContent>

                <Separator index={index} numberOfElements={history.length}>
                    {renderIcon(element.type as string)}
                </Separator>

                <Content>
                    <Typography variant="body1">{element.description}</Typography>
                    {element.type !== 'level' && element.type !== 'rank' &&
                        <Typography variant="body2">({element.action})</Typography>}
                </Content>
            </TimelineItem>
        );
    }

    return (
        <>
            <TimelineFrame>
                {[history.slice(0, entriesShown).map((element: HistoryEntry, index: number) => renderTimelineElement(element, index))]}
            </TimelineFrame>
            {entriesShown < history.length &&
                <Box p={3} paddingTop={0}>
                    <Button fullWidth variant="outlined" onClick={handleShowMoreClick}>
                        Mehr anzeigen
                    </Button>
                </Box>
            }
            <Box ref={scrollToRef}/>
        </>
    );
}