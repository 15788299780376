/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Preferences } from '../models/Preferences';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserPreferencesService {
    /**
     * Get the preferences of a person
     * @param userId
     * @returns Preferences OK
     * @throws ApiError
     */
    public static getPreferences(
        userId: number,
    ): CancelablePromise<Preferences> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{userId}/preferences',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Update the preferences of a person
     * @param userId
     * @param requestBody
     * @returns Preferences OK
     * @throws ApiError
     */
    public static updatePreferences(
        userId: number,
        requestBody: Preferences,
    ): CancelablePromise<Preferences> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{userId}/preferences',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
