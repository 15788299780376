/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserOverviewPage } from '../models/UserOverviewPage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
    /**
     * Get all Users for Overview. Paginated, Filterable and Orderable
     * @param userRole
     * @param filter
     * @param pageNumber
     * @param pageSize
     * @param direction
     * @param orderBy
     * @returns UserOverviewPage OK
     * @throws ApiError
     */
    public static getUserOverview(
        userRole: 'HOST' | 'AGENT' | 'VANLIFER',
        filter: string,
        pageNumber: number,
        pageSize: number,
        direction: 'ASC' | 'DESC',
        orderBy: string,
    ): CancelablePromise<UserOverviewPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            query: {
                'userRole': userRole,
                'filter': filter,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'direction': direction,
                'orderBy': orderBy,
            },
        });
    }
    /**
     * Get userSpecificationId by UserId
     * @param userId
     * @param userRole
     * @returns number OK
     * @throws ApiError
     */
    public static getUserSpecificationId(
        userId: number,
        userRole: 'HOST' | 'AGENT' | 'VANLIFER',
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'userRole': userRole,
            },
        });
    }
}
