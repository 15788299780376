export const loginRegisterHome = {
    typography1: "Wir bieten individuelle Reisevorschläge nach deinem Geschmack an. Hast du Lust, deinen eigenen Reisevorschlag für dich zusammenstellen zu lassen? Ja dann nichts wie los!"
}

////////////////////
// VANLIFER TEXTE //
////////////////////

// Vorteile //
export const txtBenefitsForVanlifer = {
    head1: "Reiseplanung Easy-Peasy",
    text1: "Vergiss aufwendige Ferienplanungen! Hier hast du eine komplette Reise von Anfang bis Ende. " +
        "Du bekommst nicht nur einzelne Übernachtungen, sondern vollständige Reiserouten, die du mit nur " +
        "wenigen Klicks easy-peasy individuell gestalten kannst. Egal, ob du ein Van-Vagabund " +
        "oder ein rollender Träumer bist - Genieße dein rundum sorglos Reisepaket.",
    head2: "Du sparst sehr viel Zeit",
    text2: "Die mühsame Suche nach freien Stellplätzen nehmen wir dir direkt ab. " +
        "So kannst du dich auf das Wesentliche konzentrieren. Dadurch gewinnst du Zeit " +
        "für dein VanLife-Abenteuer und vergisst diesmal nicht wieder die T-Shirts...",
    head3: "Deine perfekt individuelle Reiseroute",
    text3: "Du bekommst einzigartige und vorgefertigte Reiserouten von uns, die du individuell nach Belieben " +
        "und Wünschen anpassen kannst. Einfach zu bedienen. Gestalte deine Reise so, wie sie dir gefällt und wo " +
        "die Entdeckerlust dich hinführt.",
    head4: "Jederzeit buchen und losfahren",
    text4: "Buche deine Reise, wann immer dich das Reisefieber packt. Egal ob bei Sonnenaufgang oder Mitternacht.\n" +
        "Unsere buchbaren Angebote garantieren dir jederzeit maximale Freiheit. Schnapp dir jetzt dein Abenteuer ganz," +
        " nach dem Motto: Voll gepackt mit tollen Sachen, die das Leben schöner machen, hinein ins VanLife Feeling. " +
        "Lass dich gehen, schalt einfach ab, genieß die Leichtigkeit dabei - und das an 365 Tagen im Jahr.\n"
}

// How it works? //
export const txtHowItWorksForVanlifer = {
    head1: "Deine Reise, deine Regeln - Langweilige Routenplanung war gestern!",
    text1: "Endlich mal frei! Du willst mit deinem Van ins Abenteuer losziehen, aber oh nein, die Planung ist echt " +
        "nervig. Jeder Stellplatz muss einzeln angefragt werden und die meisten sind schon voll oder es antwortet " +
        "niemand. Und da fängt schon der erste Stress an. Zu Hause bleiben? Auch keine Option.",
    text2: "Don’t worry - be happy. Wir haben eine supercoole Lösung für dich! " +
        "Unseren unwiderstehlichen Reiseplaner, den es so noch nirgends gibt. Damit wird die Planung deines Camping-Abenteuers " +
        "mega easy!",
    text3: "Unser Angebot macht Schluss mit langwierigem Suchen und Anfragen - stattdessen gibt es hier eine fertige Route " +
        "inklusive freien Stellplätzen, die dir viel Zeit einspart beim Planen deiner Reise.",
    text4: "Dahinter steckt ein intelligenter Algorithmus, der die schönsten Stellplätze einer Region nimmt und sie zu deiner " +
        "perfekten Route mixt. Es ist wie bei einem richtig gut gemixten Cocktail - der reinhaut. Doch das ist nicht " +
        "alles, jetzt kommt der Hammer: Du kannst die Route ganz nach deinem Geschmack pimpen. Hier ein bisschen länger " +
        "bleiben oder den Stellplatz tauschen. Alles digital, alles innerhalb von Sekunden. Alles mit einem Klick " +
        "(na ja, je nachdem wie viele Sonderwünsche du hast).!"
}

export const welcomeRegisterPage = {
    welcomeHost: "Du hast einen Stellplatz und möchtest diesen bei Vanlife Travel anbieten? Dann fülle das Formular aus und wir werde und schnellstmöglich bei dir melden.",
    welcomeVanlifer: "Hast du Lust auf eine spontane Reise? Registriere dich jetzt für den Tourengenerator und erhalte Reisevorschläge nach deinen Wünschen!",
    agbNewsletter:"Die Vanlife Travel GmbH verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir verwenden Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von Ihnen angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie gerne zusätzlich über unsere Produkte und Dienstleistungen sowie andere Inhalte, die für Sie von Interesse sein könnten, informieren. Wenn Sie damit einverstanden sind, aktivieren Sie bitte das folgende Kontrollkästchen.:",
    formControlNewsletter: "Ich stimme zu, Benachrichtigungen von Vanlife Travel GmbH zu erhalten.",
    agbPrivacy: "Um Ihnen die gewünschten Inhalte bereitzustellen, müssen wir Ihre persönlichen Daten speichern und verarbeiten. Wenn Sie damit einverstanden sind, dass wir Ihre persönlichen Daten für diesen Zweck speichern, aktivieren Sie bitte das folgende Kontrollkästchen.",
    fromControlPrivacy: "Ich stimme der Speicherung und Verarbeitung meiner persönlichen Daten durch Vanlife Travel GmbH zu.",
    agbInfos: "Sie können diese Benachrichtigungen jederzeit abbestellen. Weitere Informationen zum Abbestellen, zu unseren Datenschutzverfahren und dazu, wie wir Ihre Privatsphäre schützen und respektieren, finden Sie in unserer Datenschutzrichtlinie.",
    formControlAgb: "Ich habe die AGB gelesen und akzeptiere diese."
}

export const hostRegisterComponent = {
    thanksHostRegister: "Vielen Dank fürs Registrieren, wir werden uns baldmöglichst in Verbindung setzen."
}

export const feePaymentComponent={
    feeText: "Für diese Dienstleistung benötigt man ein Abo."
}

export const vltAlertDialog = {
    dialogTextForAgreeDeleteOnlyAvailableAvailabilities: "Verfügbarkeiten, die schon gebucht oder reserviert sind, können nicht mehr gesperrt werden.",
    dialogTextForBookedByHostAvailabilities: "Wollen Sie wirklich Host-Buchungen löschen? Dies kann nicht rückgängig gemacht werden.",
    dialogTextForBookedByHostAvailabilitiesEdit: "Wollen Sie wirklich Host-Buchungen bearbeiten? Dies kann nicht rückgängig gemacht werden.",
    dialogTextForDeleteAvailabilities: "Achtung. Die ausgewählten Verfügbarkeiten deines Stellplatzes werden endgültig gelöscht und kein Reisender kann an diesen Tagen Deinen Stellplatz buchen. Dies kann nicht rückgängig gemacht werden.",
    dialogAgreeDeleteButtonText: "Ja, Verfügbarkeiten löschen",
    dialogAgreeEditButtonText: "Ja, Verfügbarkeiten bearbeiten",
    dialogAgreeDeleteOnlyAvailableAvailabilitiesButtonText: "Nur freie Verfügbarkeiten löschen.",
    dialogTextForEditOnlyAvailableAvailabilities: "Verfügbarkeiten, die schon gebucht oder reserviert sind, können nicht mehr bearbeitet werden.",
    dialogAgreeEditAvailabilitiesButtonText: "Nur freie Verfügbarkeiten bearbeiten.",
    dialogTextForDeleteStayInTour: "Bei gleichem Anreise- und Abreisedatum wird der Aufenthalt auf diesem Stellplatz gelöscht. Dies kann nicht rückgängig gemacht werden. Möchtest du trotzdem fortfahren?"
}
export const cookiesText = {
    textForCookies: "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer ",
}

export const tourEditorText={
    textForTourEdotor: "Hier findest du Details der ausgewählten Tour. Du kannst diese noch anpassen."
}

export const textFragments = {
    title1: "Plane deine persönliche Reiseroute",
    text1: "Du bist interessiert an einer von uns generierten Tour? Gebe dazu ein wohin du reisen möchtest und in welchem Zeitraum. Die Reise gefällt dir noch nicht ganz? Kein Problem - Du hast die Möglichkeit deine Route individuell anzupassen. Nachdem du mit deiner zusammengestellten Reise zufrieden bist, kannst du die Reise buchen und losfahren. Wenn Du mit Deiner Reise zufrieden bist, kannst du die Reise reservieren und losfahren.",

    title2: "Verwalte Deine Stellplätze",
    text2: "Unser Stellplatzkalender bietet Dir die Möglichkeit, deinen Gästen eine angenehmere Reiseplanung zu ermöglichen. Unser Angebot ist vollkommen kostenlos und Du behältst dabei zu jeder Zeit die volle Kontrolle darüber, wann und zu welchem Preis zu deine Stellplätze anbieten möchtest. Die Nutzung des Stellplatzkalenders ist kinderleicht und sowohl mit PC, Laptop, Tablet oder Mobiltelefon möglich.",

    title3: "Wachse nachhaltig mit uns",
    text3: "Nachdem du mit deiner zusammengestellten Reise zufrieden bist, kannst du die Reise buchen und losfahren",

    title4: "Lerne uns kennen",
    text4: "Wir schaffen eine Win-Win-Situation für Reisende und Anbieter. Unsere zukünftige Online-Plattform verbindet die Flexibilität einer Individualreise mit den Vorzügen einer Pauschalreise und wird damit die Art und Weise verändern, wie zukünftig solche Reisen geplant und gebucht werden.",
}