import {
    setIsAuthenticated,
    setLoginEmailFromStorage,
    setSpecificationIdLogin,
    setUserIdFromStorage, setUserIsRegistered, setUserRoleLogin
} from "../store/navBar/loginSlice";
import {
    getPersonPreferencesAsync,
    getUserAsync,
    getUserEntitlementsAsync,
    getUserSubscriptionsAsync
} from "../store/navBar/userSlice";
import {asyncCheckKudos} from "../store/Vanlifer/Gamification/KudosSlice";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./hooks";
import { User } from "../services/restserver-openapi";

export const useUserLogin = () => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.login.token);
    const userRoleLogin = useAppSelector(state => state.login.userRoleLogin);
    const userSpecificationId = useAppSelector(state => state.login.userSpecificationId);
    const tokenFromStorage = sessionStorage.getItem("token");
    const roleFromStorage = sessionStorage.getItem("role");
    const registerFromStorage = sessionStorage.getItem("userIsRegistered");
    const pictureFromStorage = sessionStorage.getItem("userPicture");
    const emailFromStorage = sessionStorage.getItem("loginEmail");
    const userIdFromStorage = sessionStorage.getItem("userID");
    const userSpecificationIdFromStorage = sessionStorage.getItem("userSpecificationID")
    const kudosLastCheckedAt = useAppSelector(state => state.kudos.kudosLastCheckedAt);

    async function tryLogIn()  {
        if (userRoleLogin && userSpecificationId !== 0) {
            dispatch(setSpecificationIdLogin({userSpecificationId: userSpecificationId}));
            await dispatch(getUserAsync({userSpecificationId, userRoleInput: userRoleLogin})).then(async (response) => {
                if (getUserAsync.fulfilled.match(response)) {
                    if (response.payload && response.payload.user?.userId) {
                        dispatch(getPersonPreferencesAsync(response.payload.user.userId));
                        dispatch(getUserEntitlementsAsync());
                        dispatch(getUserSubscriptionsAsync());
                    }
                }
            });
            if (userRoleLogin === User.userRole.VANLIFER) {
                dispatch(asyncCheckKudos({checkKudosSinceDate: kudosLastCheckedAt}));
            }
        }
    }

    useEffect(() => {
        if (tokenFromStorage && roleFromStorage) {
            dispatch(setUserRoleLogin({startUserRole: roleFromStorage}));
            if (registerFromStorage === "true" && token !== tokenFromStorage) {
                dispatch(setUserIsRegistered({
                    userIsRegistered: true,
                    picture: pictureFromStorage,
                    token: tokenFromStorage
                }));
            } else if (registerFromStorage) {
                dispatch(setUserIsRegistered({userIsRegistered: true, picture: pictureFromStorage, token: token}));
            }
            dispatch(setIsAuthenticated({isAuthenticated: true}));
            dispatch(setLoginEmailFromStorage({loginEmail: emailFromStorage}));
            dispatch(setSpecificationIdLogin({userSpecificationId: userSpecificationIdFromStorage}));
            dispatch(setUserIdFromStorage({userId: userIdFromStorage}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenFromStorage, token]);

    useEffect(() => {
        tryLogIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRoleLogin, userSpecificationId])

};