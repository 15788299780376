import {useAppSelector} from "../hooks/hooks";
import React, {useEffect} from "react";
import Alert from "@mui/material/Alert";
import {Snackbar, useTheme} from "@mui/material";

export default function Kudos() {
    const theme = useTheme();
    const kudosMessages = useAppSelector(state => state.kudos.kudosMessages);

    const [open, setOpen] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState("");

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        if (kudosMessages.length > 0) {
            setOpen(true);
            setSnackbarText(kudosMessages[0]);
        }

    }, [kudosMessages]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={8000}
            onClose={handleClose}>
            <Alert
                onClose={handleClose}
                sx={{ width: '100%', backgroundColor: theme.palette.secondary.main }}>
                {snackbarText}
            </Alert>
        </Snackbar>
    );
}