import * as React from 'react';
import {Global} from '@emotion/react';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {setStateCookieDrawer} from "../../store/navBar/loginSlice";
import {Button, Grid, useTheme} from "@mui/material";
import {cookiesText} from "../../constants/textConstant"
import Cookies from "js-cookie";
import {urlPrivacyPDF} from "../../constants/constants";

export default function CookieDrawer() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const openCookieDrawer = useAppSelector(state => state.login.openCookieDrawer);

    const toggleDrawer = (newOpen: boolean) => () => {
        dispatch(setStateCookieDrawer({openCookieDrawer: newOpen}));
    };

    const handleAcceptance = () =>{
        Cookies.set('globalCookie', 'cookieSetTrue', { expires: 365 });
        dispatch(setStateCookieDrawer({openCookieDrawer: false}));
    }

    return (
        <div>
            <Global styles={{height: `35%`, overflow: 'visible'}}/>
            <SwipeableDrawer
                anchor="bottom"
                open={openCookieDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={12} lg={12} sx={{marginTop: '32px', marginLeft: '32px'}}>
                        <Typography variant="h5">Cookies</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} sx={{marginTop: '20px', marginX: '32px'}}>
                        <Typography variant="body1">{cookiesText.textForCookies}
                            <a href={urlPrivacyPDF} target="_blank" rel="noreferrer"
                               style={{color: theme.palette.secondary.main}}>
                                Datenschutzerklärung.</a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        marginY: '20px',
                        marginX: '32px'
                    }}>
                        <Button color={"primary"} variant="contained" onClick={handleAcceptance}>Alle Akzeptieren</Button>
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </div>
    );
}
