import React from 'react';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {Moment} from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

type DatePickerProp = {
    handleChange: (newValue: Moment | null) => void;
    value: Moment | null;
    label: string;
    helperText?: string;
    fullWidth?: boolean;
}

const TimePickerVanlife = ({handleChange, value, label, helperText, fullWidth}: DatePickerProp) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
        <TimePicker
            label={label}
            value={value}
            onChange={handleChange}
        />
        </LocalizationProvider>
    );
}

export default TimePickerVanlife;