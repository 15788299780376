import React, { useState } from 'react';
import {
  Alert, Button, Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  PaletteMode,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CompoundComponent from './CompoundComponent';
import CompoundFormComponent from './CompoundFormComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { defaultCompound, fetchCompounds, setSelectedCompound } from '../../store/Host/compoundsSlice';
import { Compound } from '../../services/restserver-openapi';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { EcoFriendlyIcon } from '../vanliferComponents/EcoFriendlyIcon';
import { Main } from '../../layouts';
import Container from '../Container';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContentText from '@mui/material/DialogContentText';
import {
  deleteSelectedSite,
  fetchAllSites,
  getTotalOwnedSites,
  setSite,
} from '../../store/Host/sitesManagement/siteSlice';

function CompoundManagementComponent() {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const compound = useAppSelector(state => state.compounds.selectedCompound);
  const hostId = useAppSelector(state => state.login.userSpecificationId);
  const compoundBelongsToHost = Number(compound?.hostId) === Number(hostId);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("")

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  }
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleEditCompound = () => {
    setErrorSnackbarMessage("Vergiss nicht zu speichern!");
    setOpenErrorSnackbar(true);
    setEditMode((prev) => !prev);
  };

  const handleAddCompound = () => {
    setErrorSnackbarMessage("Du hast einen neuen Betrieb erstellt!");
    setOpenErrorSnackbar(true);
    dispatch(setSelectedCompound({ ...defaultCompound, hostId: hostId }));
    setEditMode(true);
  };

  const handleDeleteCompound = () => {
    setErrorSnackbarMessage("Löschen eines Compounds muss noch implementiert werden!");
    setOpenErrorSnackbar(true);
    handleCloseDeleteDialog();
  };

  const onBack = async (compoundId = 0) => {
    setEditMode((prev) => !prev);
    if (hostId) {
      await dispatch(fetchCompounds(hostId)).unwrap().then((payload) => {
        if (payload) {
          dispatch(setSelectedCompound(payload.find((compound: Compound) => compound.compoundId === compoundId) || payload[0]));
        }
      });
    }
  };

  const DeleteDialog = () => {
    return (
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>
          {"Betrieb löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchtest du den Betrieb "{compound.name}" wirklich löschen? Alle Daten werden vollständig gelöscht und diese Aktion kann nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="outlined">
            Abbrechen
          </Button>
          <Button onClick={handleDeleteCompound} color="primary" autoFocus variant="contained">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const EcoFriendly = () => {
    if (compound?.ecoFriendly) {
      return (
        <Typography sx={{ color: '#13ab1a' }}>
          {/* Text here */}
          <EcoFriendlyIcon sx={{ ml: 1, position: 'relative', top: '6px' }} />
          Eco
        </Typography>
      );
    }

    return null;
  };

  if (!editMode && compound.compoundId === 0) {
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item> <IconButton color="primary" aria-label="add compound"
                                    onClick={handleAddCompound}>
              <AddCircleIcon sx={{ fontSize: 30 }} />
            </IconButton></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Alert severity="info">
            Erfasse einen Betrieb!
          </Alert>
        </Grid>
      </Grid>
    );
  }

  const HeaderContent = () => (
    <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'text.secondary'}
      >
        Betriebsdaten
      </Typography>
      <Box marginBottom={2}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontWeight: 700,
          }}
        >
          <Typography
            color={'text.primary'}
            component={'span'}
            variant={'inherit'}
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                0.3,
              )} 0%)`,
            }}
          >
            {compound.name}
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h6" component="p" color="text.secondary">
          Hier pflegst Du alle Daten zu Deinem Betrieb. Wenn Du einen anderen Betrieb bearbeiten möchtest, dann verwende den Wechsler oben rechts neben deinem
          Profilbild. Unterkünfte erfasst und bearbeitest Du unter Verfügbarkeiten.
        </Typography>
      </Box>
    </Box>
  );


  return (
    <Main>
      <Snackbar open={openErrorSnackbar} autoHideDuration={6000}
                onClose={() => setOpenErrorSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="info">
          {errorSnackbarMessage}
        </Alert>
      </Snackbar>
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
          >
            <Container>
              <HeaderContent />
            </Container>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Typography variant="h4" component="h2" gutterBottom>
                Erfasste Daten:
              </Typography>
            </Grid>
            {!editMode ? (
              <Grid item xs={12} sm={2}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Tooltip
                      title={!compoundBelongsToHost ? 'Du kannst diesen Campingplatz nicht bearbeiten, da er nicht dir gehört' : ''}
                      arrow>
                            <span>
                                <IconButton color="primary" aria-label="edit compound"
                                            onClick={handleEditCompound}
                                            disabled={!compoundBelongsToHost}>
                            <EditIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                            </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" aria-label="delete compound"
                                onClick={handleOpenDeleteDialog}>
                      <DeleteIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <DeleteDialog/>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" aria-label="add compound"
                                onClick={handleAddCompound}>
                      <AddCircleIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

            ) : (
              <Grid item xs={12} sm={6}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <IconButton color="primary" aria-label="close form" onClick={() => onBack(compound.compoundId)}>
                      <CancelIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              {editMode ? (<CompoundFormComponent onBack={onBack} />) : (<CompoundComponent />)}
            </Grid>
          </Grid>
        </Box>
      </Container>

    </Main>
  );
}

export default CompoundManagementComponent;