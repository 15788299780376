import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const RegisterHostInformation = () => {
  return (
    <Box>
      <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Werde Teil einer Open Travel Community</Typography>
      <ul>
        <li>Lass dich von Reisenden finden. Bei uns werden individuelle Reisen geplant und gerne möchten wir, dass auch
          Dein
          Betrieb Teil einer erlebnisreichen Reise wird.
        </li>
        <li>Verwalte Dein Profil mit Bildern & Texten für Deinen Betrieb z.B. für Deinen Hof, deinen Campingplatz oder
          deine kleine Pension.
        </li>
        <li>Entscheide selbst, ob Dein Angebot direkt reserviert werden kann oder ob Du offen bist für eine spontane
          Anreise.
        </li>
        <li>Nutze unsere kompakte Übersichtsseite, um schnell über bevorstehende Anreisen und Deine Gäste informiert zu
          sein
        </li>
        <li>Lass Deinen Gästen automatisch erste Infos zum Aufenthalt zukommen und stell Ihnen Fragen z.B. zur
          geplanten Anreisezeit.
        </li>
      </ul>
      <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Verwalte Deine Buchungen</Typography>
      <ul>
        <li><strong>Kostenlos für max. 5 Unterkünfte</strong> (und das bleibt auch so!)</li>
        <li>Lege für Deine Unterkünfte mehrere individuelle Kalender an z.B. pro Zimmer oder Stellplatz, jeweils mit
          Bildern & Texten
        </li>
        <li>Erfasse neue Buchungen und verwalte sie jederzeit über Deinen PC, Laptop, Tablet oder Dein Handy</li>
        <li>Richte Stellvertretungen für deine Buchungsverwaltung ein, falls Du selbst mal nicht kannst oder willst</li>
      </ul>
      <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Weitere Infos und Funktionen auf Wunsch</Typography>
      <Typography variant="body1">
        Es ist schwer alle Informationen auf eine Webseite zu bringen. Falls Du weitere Infos brauchst oder ganz
        allgemein eine Frage hast, dann schreib uns einfach eine {' '}
        <Link to="support@vanlife-travel.ch" onClick={(e) => {
          window.location.href = 'mailto:support@vanlife-travel.ch';
          e.preventDefault();
        }}
              title="support@vanlife-travel.ch"> Email</Link>
        .
      </Typography>
    </Box>
  );
};

export default RegisterHostInformation;