import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getUserToken, setTokenInApi} from "../../utils/User/user-openapi-hooks";
import {setTokenOfferInApi} from "../../utils/Host/offer";
import {setTokenTourInApi} from "../../utils/Vanlifer/tour";
import {User} from "../../services/restserver-openapi";
import {setTokenGamificationInApi} from "../../utils/Vanlifer/gamification";

export const loginUser = createAsyncThunk(
    "login/loginAsync",
    async (identityToken: string) => {
        const response = await getUserToken(identityToken);
        return response;
    });

export type UserState = {
    userId?: number,
    userName?: string,
    token: string,
    picture: string,
    loginFirstname: string,
    loginLastname: string,
    loginEmail: string,
    isAuthenticated?: boolean,
    status?: string,
    userRoleLogin?: User.userRole,
    userIsRegistered: boolean,
    userSpecificationId: number,
    openLoginDialog: boolean,
    openCookieDrawer: boolean,
}

const initialState: UserState = {
    userId: 0,
    userName: "",
    token: "",
    picture: "",
    loginFirstname: "",
    loginLastname: "",
    loginEmail: "",
    isAuthenticated: false,
    status: "idle",
    userRoleLogin: User.userRole.VANLIFER,
    userIsRegistered: false,
    userSpecificationId: 0,
    openLoginDialog: false,
    openCookieDrawer: false,
}

function setTokensInApis(identityToken: string) {
    setTokenInApi(identityToken);
    setTokenOfferInApi(identityToken);
    setTokenTourInApi(identityToken);
    setTokenGamificationInApi(identityToken);
}

export const loginSlice = createSlice({
        name: 'login',
        initialState,
        reducers: {
            setUserState: (state, action) => {
                state.userName = action.payload.userName;
                state.token = action.payload.token;
                sessionStorage.setItem("token", state.token);
                state.picture = action.payload.picture;
                sessionStorage.setItem("userPicture", state.picture);
                state.loginFirstname = action.payload.loginFirstname;
                state.loginLastname = action.payload.loginLastname;
                state.loginEmail = action.payload.loginEmail;
                sessionStorage.setItem("loginEmail", state.loginEmail);
                setTokensInApis(state.token);

            },
            setIsAuthenticated: (state, action) => {
                state.isAuthenticated = action.payload.isAuthenticated;
            },
            setUserRoleLogin: (state, action) => {
                state.userRoleLogin = action.payload.startUserRole;
            },
            setUserIsRegistered: (state, action) => {
                state.userIsRegistered = action.payload.userIsRegistered;
                sessionStorage.setItem("userIsRegistered", action.payload.userIsRegistered);
                state.picture = action.payload.picture;
                state.token = action.payload.token;
                sessionStorage.setItem("token", state.token);
                setTokensInApis(state.token);
            },
            setLoginEmailFromStorage: (state, action)=>{
                state.loginEmail = action.payload.loginEmail;
            },
            setSpecificationIdLogin: (state, action) => {
                state.userSpecificationId = action.payload.userSpecificationId;
                sessionStorage.setItem("userSpecificationID", `${state.userSpecificationId}`);
            },
            setUserDataAfterRegistration: (state, action) => {
                state.userRoleLogin = action.payload.userRole;
                state.userId = action.payload.userId;
                state.userSpecificationId = action.payload.userSpecificationId;
                state.userIsRegistered = true;
                sessionStorage.setItem("userIsRegistered", "true");
                sessionStorage.setItem("role", state.userRoleLogin!);
                sessionStorage.setItem("userID", `${state.userId}`);
                sessionStorage.setItem("userSpecificationID", `${state.userSpecificationId}`);
            },
            setUserIdFromStorage: (state, action) => {
                state.userId = action.payload.userId;
            },
            setOpenLoginDialogState: (state, action) => {
                state.openLoginDialog = !state.openLoginDialog;
            },
            removeUserFromState: (state) => {
                state.userId = 0;
                state.userName = "";
                state.token = "";
                state.picture = "";
                state.loginFirstname = "";
                state.loginLastname = "";
                state.loginEmail = "";
                state.isAuthenticated = false;
                state.userIsRegistered = false;
                state.status = "idle";
                state.userRoleLogin = User.userRole.VANLIFER;
                state.userSpecificationId = 0;
                sessionStorage.clear();
            },
            setStateCookieDrawer: (state, action) => {
                state.openCookieDrawer = action.payload.openCookieDrawer;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(loginUser.pending, (state) => {
                state.status = "pending";
            }).addCase(loginUser.fulfilled, (state, {payload}) => {
                state.status = "idle";
                if (payload) {
                    state.userIsRegistered = true;
                    sessionStorage.setItem("userIsRegistered", "true");
                    state.userRoleLogin = payload.userRole;
                    sessionStorage.setItem("role", state.userRoleLogin!);
                    state.userId = payload.userId;
                    sessionStorage.setItem("userID", `${state.userId}`);
                    state.userSpecificationId = payload.userSpecificationId!;
                    sessionStorage.setItem("userSpecificationID", `${state.userSpecificationId}`);
                }
                state.isAuthenticated = true;
                sessionStorage.setItem("isAuthenticated", "true");
            });
        },
    }
);

export const {
    setUserState,
    setIsAuthenticated,
    setUserRoleLogin,
    setUserIdFromStorage,
    setUserIsRegistered,
    setSpecificationIdLogin,
    setUserDataAfterRegistration,
    setOpenLoginDialogState,
    removeUserFromState,
    setStateCookieDrawer,
    setLoginEmailFromStorage
} = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
