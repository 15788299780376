import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {numberOfPeople} from "../../constants/constants";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {createSite, editSite, getTotalOwnedSites} from "../../store/Host/sitesManagement/siteSlice";
import {fetchAllSizes} from "../../store/Host/sitesManagement/sizesSlice";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Site, Size} from "../../services/restserver-openapi";
import UploadPictures from "../presentationalComponents/UploadPictures";
import {Save} from "@mui/icons-material";
import {
    fetchExistingImages,
    handleImageDelete,
    handleImageUploadSite,
    handleUpdateImageChange
} from "../../utils/Host/offerImageUtil";

type CreateSiteFormProps = {
    onBack: () => void
}

const SiteForm: React.FC<CreateSiteFormProps> = ({onBack}) => {
    const dispatch = useAppDispatch();
    const sizes = useAppSelector(state => state.sizes.sizes);
    const site = useAppSelector(state => state.site.site);
    const [siteData, setSiteData] = useState<Site>(site);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<string[]>([]);

    const [loadingSave, setLoadingSave] = useState(false);

    useEffect(() => {
        dispatch(fetchAllSizes());
    }, [dispatch]);

    useEffect(() => {
        setSiteData(site)
    }, [dispatch, site]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (siteData.siteId) {
            setLoadingSave(true);
            await handleImageUploadSite(siteData,selectedFiles);
            await dispatch(editSite(siteData))
            setLoadingSave(false);
        } else {
            await dispatch(createSite(siteData)).then(async (response) => {
                if (createSite.fulfilled.match(response)) {
                    setSiteData(response.payload);
                    await handleImageUploadSite(response.payload, selectedFiles);
                }
            });
        }
        await dispatch(getTotalOwnedSites());
        onBack()
    }

    const handleSiteFeaturesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSiteData({
            ...siteData,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        // Fetch existing images and prepopulate selectedFiles
        if (siteData.imageIds) {
            fetchExistingImages(siteData.imageIds, setSelectedFiles, selectedFiles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData.imageIds]);


    const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateImageChange(e, files, setFiles, selectedFiles, setSelectedFiles);
    }

    const handleUploadDelete = (index: number) => {
        handleImageDelete(index, files, setFiles, selectedFiles, setSelectedFiles);
    }

    return (
        <form onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} justifyContent="space-between">
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        required
                        name="label"
                        label="Platz Nr."
                        value={siteData.label}
                        fullWidth
                        onChange={(e) => setSiteData({...siteData, label: e.target.value})}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        required
                        name="offer.name"
                        label="Stellplatz Name"
                        value={siteData.offer?.name}
                        fullWidth
                        onChange={(e) => setSiteData({...siteData, offer: {...siteData.offer, name: e.target.value}})}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField name="sizeId" label='Platz Grösse'
                               value={siteData.sizeId ? siteData.sizeId : ""} select fullWidth
                               onChange={(e) => setSiteData({...siteData, sizeId: +e.target.value})}>
                        {sizes.map((size: Size) =>
                            (<MenuItem key={size.sizeId} value={size.sizeId}>{size.name}</MenuItem>))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField name="offer.maxPerson" label='Maximale Anzahl Gäste'
                               value={siteData.offer?.maxPerson ? siteData.offer?.maxPerson : ""} select fullWidth
                               onChange={(e) => setSiteData({
                                   ...siteData,
                                   offer: {...siteData.offer, maxPerson: +e.target.value}
                               })}>
                        {numberOfPeople.map((nrPeople: number) =>
                            (<MenuItem key={nrPeople} value={nrPeople}>{String(nrPeople)}</MenuItem>))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Beschreibung"
                        multiline
                        rows={4}
                        value={siteData.offer?.description}
                        onChange={(e) => setSiteData({
                            ...siteData,
                            offer: {...siteData.offer, description: e.target.value}
                        })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={siteData.electricity}
                                      onChange={handleSiteFeaturesChange}
                                      name="electricity"/>
                        }
                        label="Strom"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={siteData.water}
                                      onChange={handleSiteFeaturesChange}
                                      name="water"/>
                        }
                        label="Frischwasser"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5">Bilder</Typography>
                    <UploadPictures handleChange={handleUploadChange} selectedFiles={selectedFiles} handleDelete={handleUploadDelete} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Gästeinformationen zum Stellplatz"
                        multiline
                        rows={4}
                        value={siteData.guestInformation}
                        onChange={(e) => setSiteData({
                            ...siteData,
                            offer: {...siteData.offer},
                            guestInformation: e.target.value
                        })}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} sm={1} md={1}>
                    <Button variant="contained" color="primary" type="submit" endIcon={loadingSave ? <CircularProgress /> : <Save  />}> Speichern </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default SiteForm;
