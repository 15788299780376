import * as React from 'react';
import {useEffect} from 'react';
import {OfferReviewService, Review} from "../../services/restserver-openapi";
import Card from "@mui/material/Card";
import {Box, Grid, Skeleton, Typography} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import {formatAsSwissDate} from "../../utils/dateUtil";
import RateReviewIcon from '@mui/icons-material/RateReview';
import {TitleWithIcon} from "./TitleWithIcon";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";

interface CompoundReviewsProps {
    compoundId: number | undefined;
    setAverageRating: (rating: number | null) => void;
}

export function CompoundReviews({compoundId, setAverageRating}: CompoundReviewsProps): React.ReactElement {
    const [reviews, setReviews] = React.useState<Review[] | null>(null);
    const [error, setError] = React.useState<boolean>(false);
    const numberOfReviewsShown = 20;

    const calculateAverageRating = (reviews: Review[]): number | null => {
        if (reviews.length === 0) {
            return null;
        }

        const totalRating = reviews.reduce((sum, review) => sum + review.rating!, 0);
        return totalRating / reviews.length;
    };

    useEffect(() => {
        if (compoundId === undefined) {
            return;
        }

        setError(false);

        OfferReviewService.getCompoundReviews(compoundId)
            .then(reviews => {
                setReviews(reviews);
                setAverageRating(calculateAverageRating(reviews));
            })
            .catch(e => setError(true));
    }, [setAverageRating, compoundId]);

    if (error) {
        return <Typography>Es ist ein Fehler aufgetreten</Typography>;
    }

    let reviewCards: React.ReactNode;

    if (reviews === null) {
        reviewCards = (Array.from({length: 3}).map((_, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <Card>
                    <Skeleton variant="rounded" height={120} width="100%"/>
                </Card>
            </Grid>
        )));
    } else if (reviews.length === 0) {
        reviewCards = <Grid item><Typography>Es gibt leider noch keine Bewertungen...</Typography></Grid>;
    } else {
        reviewCards = (
            reviews.slice(0, numberOfReviewsShown).map((review, index) => (
                <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                    <Card>
                        <CardContent>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="h6">Stellplatz {review.siteLabel}</Typography>
                                <Typography variant="subtitle1">({formatAsSwissDate(Number(review.timestamp))})</Typography>
                            </Stack>

                            <Rating value={review.rating} precision={0.5} readOnly/>
                            <Typography>{review.comment}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))
        );
    }

    return (
        <Box>
            {/*<TitleWithIcon title="Was andere Vanlifer über diesen Campingplatz sagen" icon={<RateReviewIcon/>}/>*/}
            <Grid container sx={{my: 2}} spacing={2}>
                {reviewCards}
            </Grid>
        </Box>
    );
}
