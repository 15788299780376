import * as React from 'react';
import {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {GamificationHistoryService, HistoryEntry} from "../../../services/restserver-openapi";
import {useAppSelector} from "../../../hooks/hooks";
import {RewardTimeline} from './Timeline/RewardTimeline';
import {TimelineSkeleton} from './Timeline/TimelineSkeleton';

export function RewardHistory(): React.ReactElement {
    const [history, setHistory] = React.useState<HistoryEntry[] | null>(null);
    const userId = useAppSelector(state => state.login.userId);
    const [error, setError] = React.useState<boolean>(false);

    useEffect(() => {
        if (!userId) {
            return;
        }

        setError(false);
        GamificationHistoryService.getHistory().then((response) => {
            setHistory(response);
        }).catch(() => {
            setError(true);
        });
    }, [userId]);

    let content: React.ReactNode;
    if (error) {
        content = <Typography color={"error"}>Fehler beim Laden des Verlaufs</Typography>;
    } else if (history === null) {
        content = <TimelineSkeleton numberOfElements={6}/>
    } else if (history.length === 0) {
        content = <Typography>Noch keine Einträge im Verlauf</Typography>;
    } else {
        content = <RewardTimeline history={history}/>;
    }

    return content;
}
