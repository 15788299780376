import React, {useMemo} from 'react';
import Typography from '@mui/material/Typography';
import {User} from "../../services/restserver-openapi";
import {Box, Button, Grid} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {updateSelectedUserVerified} from "../../store/Agent/agentUserDetailSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import Tooltip from "@mui/material/Tooltip";

interface TravelAgentDetailsProps {
    user: User;
    agentId: number;
    isVerified: boolean;
}

const TravelAgentDetails: React.FC<TravelAgentDetailsProps> = ({user, agentId, isVerified}) => {
    const dispatch = useAppDispatch();
    const loggedInSpecificationId = useAppSelector((state) => state.login.userSpecificationId);

    const isOwnAgent = useMemo(() => {
        return Number(loggedInSpecificationId) === Number(agentId);
    }, [agentId, loggedInSpecificationId]);

    const handleLockAgent = () => {
        dispatch(updateSelectedUserVerified({specificationId: agentId, user, userRole: User.userRole.AGENT, newVerifiedValue: !isVerified}));
    }

    return (
        <Box>

            <Typography variant="h4" component="h2">
                Travel Agent Details
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontWeight="bold">Verifiziert?:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">{isVerified ? 'Ja' : 'Nein'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip title={isOwnAgent ? 'Sie können sich nicht selbst sperren' : ''}>
                        <span>
                         <Button variant="contained" color="primary" startIcon={isVerified ? <LockIcon/> : <LockOpenIcon/>}
                                 onClick={handleLockAgent} disabled={isOwnAgent}>
                        {isVerified ? 'Agent sperren' : 'Agent freischalten'}
                    </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>

    );
};

export default TravelAgentDetails;