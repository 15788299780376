/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Preferences = {
    preferencesId?: number;
    userId?: number;
    language?: Preferences.language;
    currency?: Preferences.currency;
    acceptedAgb?: boolean;
    acceptedSaveAndEdit?: boolean;
    acceptedNotifications?: boolean;
};
export namespace Preferences {
    export enum language {
        AA = 'AA',
        AB = 'AB',
        AE = 'AE',
        AF = 'AF',
        AK = 'AK',
        AM = 'AM',
        AN = 'AN',
        AR = 'AR',
        AS = 'AS',
        AV = 'AV',
        AY = 'AY',
        AZ = 'AZ',
        BA = 'BA',
        BE = 'BE',
        BG = 'BG',
        BH = 'BH',
        BM = 'BM',
        BI = 'BI',
        BN = 'BN',
        BO = 'BO',
        BR = 'BR',
        BS = 'BS',
        CA = 'CA',
        CE = 'CE',
        CH = 'CH',
        CO = 'CO',
        CR = 'CR',
        CS = 'CS',
        CU = 'CU',
        CV = 'CV',
        CY = 'CY',
        DA = 'DA',
        DE = 'DE',
        DV = 'DV',
        DZ = 'DZ',
        EE = 'EE',
        EL = 'EL',
        EN = 'EN',
        EO = 'EO',
        ES = 'ES',
        ET = 'ET',
        EU = 'EU',
        FA = 'FA',
        FF = 'FF',
        FI = 'FI',
        FJ = 'FJ',
        FO = 'FO',
        FR = 'FR',
        FY = 'FY',
        GA = 'GA',
        GD = 'GD',
        GL = 'GL',
        GN = 'GN',
        GU = 'GU',
        GV = 'GV',
        HA = 'HA',
        HE = 'HE',
        HI = 'HI',
        HO = 'HO',
        HR = 'HR',
        HT = 'HT',
        HU = 'HU',
        HY = 'HY',
        HZ = 'HZ',
        IA = 'IA',
        ID = 'ID',
        IE = 'IE',
        IG = 'IG',
        II = 'II',
        IK = 'IK',
        IO = 'IO',
        IS = 'IS',
        IT = 'IT',
        IU = 'IU',
        JA = 'JA',
        JV = 'JV',
        KA = 'KA',
        KG = 'KG',
        KI = 'KI',
        KJ = 'KJ',
        KK = 'KK',
        KL = 'KL',
        KM = 'KM',
        KN = 'KN',
        KO = 'KO',
        KR = 'KR',
        KS = 'KS',
        KU = 'KU',
        KV = 'KV',
        KW = 'KW',
        KY = 'KY',
        LA = 'LA',
        LB = 'LB',
        LG = 'LG',
        LI = 'LI',
        LN = 'LN',
        LO = 'LO',
        LT = 'LT',
        LU = 'LU',
        LV = 'LV',
        MG = 'MG',
        MH = 'MH',
        MI = 'MI',
        MK = 'MK',
        ML = 'ML',
        MN = 'MN',
        MR = 'MR',
        MS = 'MS',
        MT = 'MT',
        MY = 'MY',
        NA = 'NA',
        NB = 'NB',
        ND = 'ND',
        NE = 'NE',
        NG = 'NG',
        NL = 'NL',
        NN = 'NN',
        NR = 'NR',
        NV = 'NV',
        NY = 'NY',
        OC = 'OC',
        OJ = 'OJ',
        OM = 'OM',
        OR = 'OR',
        OS = 'OS',
        PA = 'PA',
        PI = 'PI',
        PL = 'PL',
        PS = 'PS',
        PT = 'PT',
        QU = 'QU',
        RM = 'RM',
        RN = 'RN',
        RO = 'RO',
        RU = 'RU',
        RW = 'RW',
        SA = 'SA',
        SC = 'SC',
        SD = 'SD',
        SE = 'SE',
        SG = 'SG',
        SI = 'SI',
        SK = 'SK',
        SL = 'SL',
        SM = 'SM',
        SN = 'SN',
        SO = 'SO',
        SQ = 'SQ',
        SR = 'SR',
        SS = 'SS',
        ST = 'ST',
        SU = 'SU',
        SV = 'SV',
        SW = 'SW',
        TA = 'TA',
        TE = 'TE',
        TG = 'TG',
        TH = 'TH',
        TI = 'TI',
        TK = 'TK',
        TL = 'TL',
        TN = 'TN',
        TO = 'TO',
        TR = 'TR',
        TS = 'TS',
        TT = 'TT',
        TW = 'TW',
        TY = 'TY',
        UG = 'UG',
        UK = 'UK',
        UR = 'UR',
        UZ = 'UZ',
        VE = 'VE',
        VI = 'VI',
        VO = 'VO',
        WA = 'WA',
        WO = 'WO',
        XH = 'XH',
        YI = 'YI',
        YO = 'YO',
        ZA = 'ZA',
        ZH = 'ZH',
        ZU = 'ZU',
    }
    export enum currency {
        ADP = 'ADP',
        AED = 'AED',
        AFA = 'AFA',
        AFN = 'AFN',
        ALK = 'ALK',
        ALL = 'ALL',
        AMD = 'AMD',
        ANG = 'ANG',
        AOA = 'AOA',
        AOK = 'AOK',
        AON = 'AON',
        AOR = 'AOR',
        ARA = 'ARA',
        ARP = 'ARP',
        ARS = 'ARS',
        ARY = 'ARY',
        ATS = 'ATS',
        AUD = 'AUD',
        AWG = 'AWG',
        AYM = 'AYM',
        AZM = 'AZM',
        AZN = 'AZN',
        BAD = 'BAD',
        BAM = 'BAM',
        BBD = 'BBD',
        BDT = 'BDT',
        BEC = 'BEC',
        BEF = 'BEF',
        BEL = 'BEL',
        BGJ = 'BGJ',
        BGK = 'BGK',
        BGL = 'BGL',
        BGN = 'BGN',
        BHD = 'BHD',
        BIF = 'BIF',
        BMD = 'BMD',
        BND = 'BND',
        BOB = 'BOB',
        BOP = 'BOP',
        BOV = 'BOV',
        BRB = 'BRB',
        BRC = 'BRC',
        BRE = 'BRE',
        BRL = 'BRL',
        BRN = 'BRN',
        BRR = 'BRR',
        BSD = 'BSD',
        BTN = 'BTN',
        BUK = 'BUK',
        BWP = 'BWP',
        BYB = 'BYB',
        BYN = 'BYN',
        BYR = 'BYR',
        BZD = 'BZD',
        CAD = 'CAD',
        CDF = 'CDF',
        CHC = 'CHC',
        CHE = 'CHE',
        CHF = 'CHF',
        CHW = 'CHW',
        CLF = 'CLF',
        CLP = 'CLP',
        CNY = 'CNY',
        COP = 'COP',
        COU = 'COU',
        CRC = 'CRC',
        CSD = 'CSD',
        CSJ = 'CSJ',
        CSK = 'CSK',
        CUC = 'CUC',
        CUP = 'CUP',
        CVE = 'CVE',
        CYP = 'CYP',
        CZK = 'CZK',
        DDM = 'DDM',
        DEM = 'DEM',
        DJF = 'DJF',
        DKK = 'DKK',
        DOP = 'DOP',
        DZD = 'DZD',
        ECS = 'ECS',
        ECV = 'ECV',
        EEK = 'EEK',
        EGP = 'EGP',
        ERN = 'ERN',
        ESA = 'ESA',
        ESB = 'ESB',
        ESP = 'ESP',
        ETB = 'ETB',
        EUR = 'EUR',
        FIM = 'FIM',
        FJD = 'FJD',
        FKP = 'FKP',
        FRF = 'FRF',
        GBP = 'GBP',
        GEK = 'GEK',
        GEL = 'GEL',
        GHC = 'GHC',
        GHP = 'GHP',
        GHS = 'GHS',
        GIP = 'GIP',
        GMD = 'GMD',
        GNE = 'GNE',
        GNF = 'GNF',
        GNS = 'GNS',
        GQE = 'GQE',
        GRD = 'GRD',
        GTQ = 'GTQ',
        GWE = 'GWE',
        GWP = 'GWP',
        GYD = 'GYD',
        HKD = 'HKD',
        HNL = 'HNL',
        HRD = 'HRD',
        HRK = 'HRK',
        HTG = 'HTG',
        HUF = 'HUF',
        IDR = 'IDR',
        IEP = 'IEP',
        ILP = 'ILP',
        ILR = 'ILR',
        ILS = 'ILS',
        INR = 'INR',
        IQD = 'IQD',
        IRR = 'IRR',
        ISJ = 'ISJ',
        ISK = 'ISK',
        ITL = 'ITL',
        JMD = 'JMD',
        JOD = 'JOD',
        JPY = 'JPY',
        KES = 'KES',
        KGS = 'KGS',
        KHR = 'KHR',
        KMF = 'KMF',
        KPW = 'KPW',
        KWD = 'KWD',
        KYD = 'KYD',
        KZT = 'KZT',
        LAJ = 'LAJ',
        LAK = 'LAK',
        LBP = 'LBP',
        LKR = 'LKR',
        LRD = 'LRD',
        LSL = 'LSL',
        LSM = 'LSM',
        LTL = 'LTL',
        LTT = 'LTT',
        LUC = 'LUC',
        LUF = 'LUF',
        LUL = 'LUL',
        LVR = 'LVR',
        LYD = 'LYD',
        MAD = 'MAD',
        MDL = 'MDL',
        MGA = 'MGA',
        MGF = 'MGF',
        MKD = 'MKD',
        MLF = 'MLF',
        MMK = 'MMK',
        MNT = 'MNT',
        MOP = 'MOP',
        MRO = 'MRO',
        MRU = 'MRU',
        MTL = 'MTL',
        MTP = 'MTP',
        MUR = 'MUR',
        MVQ = 'MVQ',
        MVR = 'MVR',
        MWK = 'MWK',
        MXN = 'MXN',
        MXP = 'MXP',
        MXV = 'MXV',
        MYR = 'MYR',
        MZE = 'MZE',
        MZM = 'MZM',
        MZN = 'MZN',
        NAD = 'NAD',
        NGN = 'NGN',
        NIC = 'NIC',
        NIO = 'NIO',
        NLG = 'NLG',
        NOK = 'NOK',
        NPR = 'NPR',
        NZD = 'NZD',
        OMR = 'OMR',
        PAB = 'PAB',
        PEH = 'PEH',
        PEI = 'PEI',
        PEN = 'PEN',
        PES = 'PES',
        PGK = 'PGK',
        PHP = 'PHP',
        PKR = 'PKR',
        PLN = 'PLN',
        PLZ = 'PLZ',
        PTE = 'PTE',
        PYG = 'PYG',
        QAR = 'QAR',
        RHD = 'RHD',
        ROK = 'ROK',
        ROL = 'ROL',
        RON = 'RON',
        RSD = 'RSD',
        RUB = 'RUB',
        RUR = 'RUR',
        RWF = 'RWF',
        SAR = 'SAR',
        SBD = 'SBD',
        SCR = 'SCR',
        SDD = 'SDD',
        SDG = 'SDG',
        SDP = 'SDP',
        SEK = 'SEK',
        SGD = 'SGD',
        SHP = 'SHP',
        SIT = 'SIT',
        SKK = 'SKK',
        SLL = 'SLL',
        SOS = 'SOS',
        SRD = 'SRD',
        SRG = 'SRG',
        SSP = 'SSP',
        STD = 'STD',
        STN = 'STN',
        SUR = 'SUR',
        SVC = 'SVC',
        SYP = 'SYP',
        SZL = 'SZL',
        THB = 'THB',
        TJR = 'TJR',
        TJS = 'TJS',
        TMM = 'TMM',
        TMT = 'TMT',
        TND = 'TND',
        TOP = 'TOP',
        TPE = 'TPE',
        TRL = 'TRL',
        TRY = 'TRY',
        TTD = 'TTD',
        TWD = 'TWD',
        TZS = 'TZS',
        UAH = 'UAH',
        UAK = 'UAK',
        UGS = 'UGS',
        UGW = 'UGW',
        UGX = 'UGX',
        USD = 'USD',
        USN = 'USN',
        USS = 'USS',
        UYI = 'UYI',
        UYN = 'UYN',
        UYP = 'UYP',
        UYU = 'UYU',
        UYW = 'UYW',
        UZS = 'UZS',
        VEB = 'VEB',
        VEF = 'VEF',
        VES = 'VES',
        VNC = 'VNC',
        VND = 'VND',
        VUV = 'VUV',
        WST = 'WST',
        XAF = 'XAF',
        XAG = 'XAG',
        XAU = 'XAU',
        XBA = 'XBA',
        XBB = 'XBB',
        XBC = 'XBC',
        XBD = 'XBD',
        XCD = 'XCD',
        XDR = 'XDR',
        XEU = 'XEU',
        XFO = 'XFO',
        XFU = 'XFU',
        XOF = 'XOF',
        XPD = 'XPD',
        XPF = 'XPF',
        XPT = 'XPT',
        XRE = 'XRE',
        XSU = 'XSU',
        XTS = 'XTS',
        XUA = 'XUA',
        XXX = 'XXX',
        YDD = 'YDD',
        YER = 'YER',
        YUD = 'YUD',
        YUM = 'YUM',
        YUN = 'YUN',
        ZAL = 'ZAL',
        ZAR = 'ZAR',
        ZMK = 'ZMK',
        ZMW = 'ZMW',
        ZRN = 'ZRN',
        ZRZ = 'ZRZ',
        ZWC = 'ZWC',
        ZWD = 'ZWD',
        ZWL = 'ZWL',
        ZWN = 'ZWN',
        ZWR = 'ZWR',
    }
}

