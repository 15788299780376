import React, {useEffect, useRef} from 'react';
import routeMarkerPicto from '../../assets/SVGIcons/Routen-Marker.svg';

interface HereMapWithMarkerProps  {
    width: string,
    height: string,
    mapMarker: {
        longitude: number,
        latitude: number
    }
};
const HereMapWithMarker: React.FC<HereMapWithMarkerProps> = ({width, height, mapMarker}:HereMapWithMarkerProps) => {
    const HERE_MAP_ID = process.env.REACT_APP_HERE_MAP_ID!;

    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<H.Map | null>(null);
    const platform = useRef(new H.service.Platform({
        apikey: HERE_MAP_ID
    }));

    useEffect(() => {
        const iconSizeMap = new H.math.Size(24, 32);
        var routenMarker = new H.map.Icon(routeMarkerPicto, {size: iconSizeMap});
        if (mapRef.current && !mapInstance.current) {

            const latitude = mapMarker.latitude;
            const longitude = mapMarker.longitude;
            const defaultLayers : any = platform.current.createDefaultLayers();
            const map = new H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map,
                {
                    center: {lat: latitude, lng: longitude},
                    zoom: 14,
                    pixelRatio: window.devicePixelRatio || 1
                }
            );

            mapInstance.current = map;
            const marker = new H.map.Marker({lat:latitude, lng:longitude}, {
                data: undefined,
                volatility: true,
                icon: routenMarker
            });
            map.addObject(marker);

        } else if (mapInstance.current) {
            mapInstance.current.removeObjects(mapInstance.current.getObjects());
            const latitude = mapMarker.latitude;
            const longitude = mapMarker.longitude;
            const marker = new H.map.Marker({lat:latitude, lng:longitude}, {
                data: undefined,
                volatility: true,
                icon: routenMarker
            });
            mapInstance.current.addObject(marker);

            mapInstance.current.setCenter({lat: latitude, lng: longitude})
        }
    }, [mapMarker]);

    return (
        <div ref={mapRef} style={{width, height}}/>
    );
};

export default HereMapWithMarker;