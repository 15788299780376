import {Email, LocationCity, Phone} from "@mui/icons-material";

export const filltext100Words = "Lorem ipsum sapientem ne neque dolor erat,eros solet invidunt duo Quisque aliquid leo. Pretium patrioque sociis eu nihil Cum enim ad, ipsum alii vidisse justo id. Option porttitor diam voluptua. Cu Eam augue dolor dolores quis, Nam aliquando elitr Etiam consetetur. Fringilla lucilius mel adipiscing rebum. Sit nulla Integer ad volumus, dicta scriptorem viderer lobortis est Utinam, enim commune corrumpit Aenean erat tellus. Metus sed amet dolore justo, gubergren sed. "
export const sizes = ['Hütte', 'Wohnwagen', 'Wohnmobil', 'Zelt']
export const siteProperties = ['Strom', 'Frischwasser']
export const numberOfPeople=[1,2,3,4,5,6,7,8,9,10,11,12]
export const travelAgentPages = ['Verwaltung Reiseangebote', 'Abrechnungen' ]

export const urlLogo = process.env.PUBLIC_URL + '/assets/vlt_logo.jpg';
export const urlLogoDark = process.env.PUBLIC_URL + '/assets/vlt_logo_dark.png';

export const urlAGBPDF = process.env.PUBLIC_URL + '/docs/AGB-VLT_20240507.pdf';
export const urlPrivacyPDF = process.env.PUBLIC_URL + '/docs/Datenschutz_20240528.pdf';
/*
const startpoint = '46.980461,9.652114';
const endpoint = '45.993594,8.902131';
const waypoints = ['46.679586,7.813954', '46.509373,6.543947', '46.706827,8.276694'];
*/
export const freeContactInformation = [
    {
        label: 'E-Mail',
        value: 'support@vanlife-travel.ch',
        icon: (
            <Email />
        ),
        link: 'mailto:support@vanlife-travel.ch'
    }
];

export const hostAbos = [
    {
        title: 'Stellplatzkalender BASIC',
        chargebeeId: 'host-free-CHF-Monthly',
        price: '0 CHF',
        unit: 'pro Monat',
        features: [
            'Nutzbar für max. 5 Stellplätze',
            'Anlegen eines Profils für den Hof bzw. Campingplatz',
            'Verlinkung bzw. Einbindung existierender Webseiten',
            'Anlegen von individuellen Stellplatzprofilen mit Bildern & Texten',
            'Eintragen und Löschen von manuellen Buchungen',
            'Kompakte Übersicht über bevorstehende Anreisen',
            'Möglichkeit, um den Anreistenden erste Infos zu übermitteln und Fragen zu stellen z.B zur Anreisezeit',
            'Benachrichtigungen über die geplante Ankunftszeit der Reisenden'
        ],
        isHighlighted: false,
    },
    {
        title: 'Buchungsservice FLAT',
        chargebeeId: 'host-flat-CHF-Monthly',
        price: '29 CHF',
        unit: 'pro Monat',
        features: [
            'Alle Funktionen aus dem "Stellplatzkalender BASIC"-Abo',
            'Termine für automatische Buchungen können freigegeben werden',
            'Pauschale Servicegebühr für alle automatischen Buchungen',
        ],
        isHighlighted: true,
    },
    {
        title: 'Buchungsservice FLEX',
        chargebeeId: 'host-flex-CHF-Monthly',
        price: '2.90 CHF',
        unit: 'pro zusätzliche Buchung',
        features: [
            'Alle Funktionen aus dem "Stellplatzkalender BASIC"-Abo',
            'Termine für automatische Buchungen können freigegeben werden',
            'Keine Servicegebühr für die ersten 5 Buchungen (Anzahl variabel nach Absprache)',
            'Erhöhte Servicegebühr für jede zusätzlich erfolgte automatische Buchung',
        ],
        isHighlighted: false,
    },
];

export const vanliferAbos = [
    {
        title: 'Free',
        chargebeeId: 'vanlifer-free-CHF-Monthly',
        price: '0 CHF',
        unit: 'pro Monat',
        features: [
            'Persönliches Vanlifer Profil',
            'Infos und Reviews zu Regionen, POI und Stellplätzen',
            'Verwaltung von 1 Reisegruppe',
            'Reisen generieren (max. 24/Tag)',
            'Eigene gebuchte Reisen ansehen'
        ],
        isHighlighted: false,
    },
    {
        title: 'Early Access',
        chargebeeId: 'vanlifer-early-access-CHF-Every-5-years',
        price: '25 CHF',
        unit: 'Einmalig bis zum Marktstart',
        features: [
            'Alle Funktionen unbegrenzt nutzen',
            'Sei ein wichtiger Teil unserer Produktentwicklung',
            'Deine Reise zählt',
            'Nach dem Marktstart erhältst du das "Flex-Traveller"-Abo für 1 ganzes Jahr',
        ],
        isHighlighted: true,
    },
    {
        title: 'Flex Traveller',
        price: '19 CHF',
        chargebeeId: 'vanlifer-premium-CHF-Monthly',
        unit: 'pro Monat',
        features: [
            'Alle Funktionen aus dem "Free"-Abo',
            'Kuratierte Reisevorschläge erhalten',
            'Individuelle POI Vorschläge erhalten',
            'Reisen mit dem Editor schnell und einfach auf Deine Wünsche anpassen',
            'Reisen buchen, ändern und stornieren',
            'Reisepläne exportieren (Mail, PDF)',
            'Support Chat & Hotline'
        ],
        isHighlighted: false,
    },
    {
        title: 'Season Pass',
        price: '39 CHF',
        chargebeeId: 'vanlifer-premium-CHF-Every-3-months',
        unit: 'pro 3 Monate',
        features: [
            'Alle Funktionen aus dem "Flex Traveller"-Abo',
            'Vergünstigter Preis durch Zahlung nur alle 3 Monate',
        ],
        isHighlighted: false,
    },
    {
        title: 'Year-round Pass',
        price: '59 CHF',
        chargebeeId: 'vanlifer-premium-CHF-Yearly',
        unit: 'pro Jahr',
        features: [
            'Alle Funktionen aus dem "Flex Traveller"-Abo',
            'Vergünstigter Preis durch jährlicher Zahlung',
        ],
        isHighlighted: true,
    },
]

export const paidContactInformation = [
    {
        label: 'Telefonnummer',
        value: ' +41 44 500 50 96',
        icon: (
            <Phone />
        ),
        link: 'tel:+41445005096'
    },
    {
        label: 'E-Mail',
        value: 'support@vanlife-travel.ch',
        icon: (
            <Email />
        ),
        link: 'mailto:support@vanlife-travel.ch'
    },
    {
        label: 'Adresse',
        value: 'Rapperswil SG, Switzerland',
        icon: (
            <LocationCity />
        ),
        link: 'https://share.here.com/l/47.22534,8.81715,Rapperswil-Jona,%20St%20Gallen,%20Switzerland?z=13.2&t=normal'
    },
];
