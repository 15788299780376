import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

type VltAlertDealogProps = {
    content: string
    open: boolean
    textAgreeButton: string
    textDisagreeButton?: string
    handleClose: () => void
    handleAgree: () => void
}

function VltAlertDialog({
                            content,
                            open,
                            handleClose,
                            handleAgree,
                            textAgreeButton,
                            textDisagreeButton
                        }: VltAlertDealogProps) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Hinweis"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={handleClose}>{textDisagreeButton}</Button>
                <Button variant="text" color="primary" onClick={handleAgree} autoFocus>
                    {textAgreeButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default VltAlertDialog;