import {
    GamificationKudosService,
    GamificationLevelService,
    OpenAPI
} from "../../services/restserver-openapi";

export const getGamificationKudos = (checkKudosSinceDate: number) => {
    return GamificationKudosService.getKudos(checkKudosSinceDate);
}

export const getLevelAndRankConfig = () => {
    return GamificationLevelService.getLevelAndRankConfig();
}

export function setTokenGamificationInApi(identityToken: string) {
    OpenAPI.TOKEN = identityToken;
}
