import * as React from 'react';
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

interface OppositeContentProps {
    children: React.ReactNode;
}

export function OppositeContent({children}: OppositeContentProps): React.ReactElement {
    return (
        <TimelineOppositeContent sx={{mt: '15px'}} align="right" variant="body2" color="text.secondary">
            {children}
        </TimelineOppositeContent>
    );
}
