import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    deleteSelectedUser,
    getAsyncSpecificationId, getEntitlementsForSelectedUser, getMaxAllowedSitesWithoutAddonForSelectedHost,
    getSubscriptionsForSelectedUser, getTotalOwnedSitesForSelectedHost,
    getUserDetails, resetAgentUserDetail
} from "../../store/Agent/agentUserDetailSlice";
import { User } from '../../services/restserver-openapi';
import {Main} from "../../layouts";
import Container from "../../components/Container";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Snackbar,
    Typography
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";import IconButton from "@mui/material/IconButton";
import HostDetails from "../../components/agentComponents/hostDetails";
import VanliferDetails from "../../components/agentComponents/vanliferDetails";
import TravelAgentDetails from "../../components/agentComponents/travelAgentDetails";
import AboOverview from "../../components/profileComponents/AboOverview";
import Divider from "@mui/material/Divider";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from "@mui/material/DialogContentText";


const AgentUserDetailPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    let { userRole, userId } = useParams<{ userRole: string, userId: string}>();
    userRole = userRole?.toUpperCase();
    const selectedUser = useAppSelector((state) => state.agentUserDetail.selectedUser);
    const specificationId = useAppSelector((state) => state.agentUserDetail.specificationId);

    const subscriptions = useAppSelector(state => state.agentUserDetail.subscriptions);
    const totalOwnedSites = useAppSelector(state => state.agentUserDetail.selectedHost.totalOwnedSites);
    const maxAllowedSitesWithoutAddon = useAppSelector(state => state.agentUserDetail.selectedHost.maxAllowedSitesWithoutAddon);
    const userEntitlements = useAppSelector(state => state.agentUserDetail.userEntitlements);
    const isVerifiedHost = useAppSelector(state => state.agentUserDetail.selectedHost.isVerified);
    const isVerifiedAgent = useAppSelector(state => state.agentUserDetail.selectedAgent.isVerified);
    const goBack = () => {
        dispatch(resetAgentUserDetail())
        navigate(-1);
    }

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    }
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("")

    useEffect(() => {
        if (userRole && userId) {
            const userRoleValues = Object.values(User.userRole);
            if (userRoleValues.includes(userRole as any) && !isNaN(Number(userId))) {
                dispatch(getAsyncSpecificationId({userId: parseInt(userId), userRoleInput: userRole as User.userRole})).unwrap().then((payload) => {
                    dispatch(getUserDetails({
                        userSpecificationId: payload,
                        userRoleInput: userRole
                    }));
                    dispatch(getSubscriptionsForSelectedUser({userId: parseInt(userId)}));
                    dispatch(getEntitlementsForSelectedUser({userId: parseInt(userId)}));
                    if (userRole === User.userRole.HOST) {
                        dispatch(getTotalOwnedSitesForSelectedHost({hostId: parseInt(userId)}));
                        dispatch(getMaxAllowedSitesWithoutAddonForSelectedHost({hostId: parseInt(userId)}));
                    }
                });
            }
        }
    }, [dispatch, userId, userRole]);

const UserSpecificComponent = () => {
    switch (userRole) {
        case User.userRole.HOST:
            return <HostDetails user={selectedUser} hostId={specificationId} totalSites={totalOwnedSites} isVerified={isVerifiedHost} />;
        case User.userRole.VANLIFER:
            return <VanliferDetails user={selectedUser} vanliferId={specificationId} />;
        case User.userRole.AGENT:
            return <TravelAgentDetails user={selectedUser} agentId={specificationId} isVerified={isVerifiedAgent} />;
        default:
            return <div>Invalid user role</div>;
    }
}

    const loggedInUserIsVerifiedAgent = useAppSelector((state) => state.user.isVerifiedAgent);

    if (!loggedInUserIsVerifiedAgent) {
        return (
            <Main>
                <Container>
                    <Alert severity="error">
                        Sie haben keine Agent-Berechtigung. Womöglich ist Ihr Account noch nicht freigeschaltet.
                    </Alert>
                </Container>
            </Main>
        );
    }

    const handleDelete = async () => {
        const userRoleValues = Object.values(User.userRole);
        if (userRoleValues.includes(userRole as any) && specificationId) {
            dispatch(deleteSelectedUser({
                specificationId: specificationId,
                userRole: userRole as User.userRole
            })).then((resultAction) => {
                if (resultAction.meta.requestStatus === 'fulfilled') {
                    goBack();
                } else {
                        if ('error' in resultAction && resultAction.error) {
                            setErrorSnackbarMessage("Fehler beim Löschen des Users: '" + resultAction.error.message + "'");
                        } else {
                            setErrorSnackbarMessage("Unbekannter Fehler beim Löschen des Users");
                        }
                        setOpenErrorSnackbar(true);
                    }
                handleCloseDeleteDialog();
            });
        }
    }


    return (
        <Main>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000}
                      onClose={() => setOpenErrorSnackbar(false)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error">
                    {errorSnackbarMessage}
                </Alert>
            </Snackbar>
            <Container>
                <Box display="flex" alignItems="center" ml={-10} >
                    <IconButton onClick={goBack} size="large">
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h3" component="h1">
                        {userRole ? userRole?.charAt(0).toUpperCase() + userRole?.slice(1) : "User"} {selectedUser.person?.firstname} {selectedUser.person?.lastname}
                    </Typography>
                    <IconButton onClick={handleOpenDeleteDialog}>
                        <DeleteIcon />
                    </IconButton>
                    <Dialog
                        open={openDeleteDialog}
                        onClose={handleCloseDeleteDialog}
                    >
                        <DialogTitle>
                            {"User" + selectedUser.person?.firstname + " " + selectedUser.person?.lastname + " löschen?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Möchtest du wirklich diesen User löschen? Diese Aktion kann nicht rückgängig gemacht werden.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteDialog} variant="outlined">
                                Abbrechen
                            </Button>
                            <Button onClick={handleDelete} color="primary" autoFocus variant="contained">
                                Löschen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Persönliche Informationen
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body1" fontWeight="bold">User ID:</Typography>
                                    <Typography variant="body1" fontWeight="bold">{selectedUser.userRole} ID</Typography>
                                    <Typography variant="body1" fontWeight="bold">Chargebee ID:</Typography>
                                    <Typography variant="body1" fontWeight="bold">Rolle:</Typography>
                                    <Typography variant="body1" fontWeight="bold">Vorname:</Typography>
                                    <Typography variant="body1" fontWeight="bold">Nachname:</Typography>
                                    <Typography variant="body1" fontWeight="bold">E-Mail:</Typography>
                                    <Typography variant="body1" fontWeight="bold">Telefon:</Typography>
                                    <Typography variant="body1" fontWeight="bold">Geburtstag:</Typography>
                                    <Typography variant="body1" fontWeight="bold">Geschlecht:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{selectedUser.userId}</Typography>
                                    <Typography variant="body1">{specificationId}</Typography>
                                    <Typography variant="body1"><Link href={`https://${process.env.REACT_APP_CHARGEBEE_SITE}.chargebee.com/d/customers/${selectedUser.chargebeeId}`} target="_blank" rel="noopener noreferrer">
                                        {selectedUser.chargebeeId}
                                    </Link></Typography>
                                    <Typography variant="body1">{selectedUser.userRole}</Typography>
                                    <Typography variant="body1">{selectedUser.person?.firstname}</Typography>
                                    <Typography variant="body1">{selectedUser.person?.lastname}</Typography>
                                    <Typography variant="body1">{selectedUser.person?.email}</Typography>
                                    <Typography variant="body1">{selectedUser.person?.phone}</Typography>
                                    <Typography variant="body1">{selectedUser.person?.birthday}</Typography>
                                    <Typography variant="body1">{selectedUser.person?.gender}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Adresse
                            </Typography>
                            <Typography variant="body1">
                                {selectedUser.person?.address?.company}{selectedUser.person?.address?.company && <br/>}
                                {selectedUser.person?.address?.line1} <br/>
                                {selectedUser.person?.address?.line2} {selectedUser.person?.address?.line2 && <br/>}
                                {selectedUser.person?.address?.country}-{selectedUser.person?.address?.zipCode} {selectedUser.person?.address?.city}, {selectedUser.person?.address?.state}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box m={4}>
                    <Divider />
                </Box>
                <Box>
                    <Typography variant="h4" component="h2" gutterBottom>
                        Abonnements
                    </Typography>
                    <AboOverview
                        subscriptions={subscriptions}
                        maxAllowedSitesWithoutAddon={maxAllowedSitesWithoutAddon}
                        totalOwnedSites={totalOwnedSites}
                        userEntitlements={userEntitlements}
                        userRole={userRole as User.userRole}
                        editable={false}
                    />
                </Box>
                <Box m={4}>
                    <Divider />
                </Box>
                <Box>
                    <Typography variant="h4" component="h2" gutterBottom>
                        Entitlements
                    </Typography>
                    <ul>
                        {userEntitlements.map(entitlement => (
                            <li key={entitlement.featureId}>
                                {entitlement.featureName} (Value: {entitlement.value})
                            </li>
                        ))}
                    </ul>
                </Box>
                <Box m={4}>
                    <Divider />
                </Box>
                <Box>
                    <UserSpecificComponent />
                </Box>
                <Box m={4}>
                    <Divider />
                </Box>
                <Box mt={4}>
                    <Button variant="contained" color="primary" onClick={goBack}>
                        <ArrowBackIosNewRoundedIcon /> Zurück
                    </Button>
                </Box>

            </Container>
        </Main>
    );
};

export default AgentUserDetailPage;