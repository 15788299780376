import React from 'react';
import {Grid} from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';

type ErrorHandlingProp = {
    errorMessage: String | null;
}

function ErrorHandling({errorMessage}: ErrorHandlingProp) {
    return (
        <Grid container my={10} direction="row"
              justifyContent="center"
              alignItems="flex-start" sx={{minHeight: '100vh'}}>
            <Alert severity="error">
                <AlertTitle>Fehler</AlertTitle>
                {errorMessage}
            </Alert>
        </Grid>
    );
}

export default ErrorHandling;
