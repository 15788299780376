import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getLevelAndRankConfig} from "../../../utils/Vanlifer/gamification";
import {LevelAndRankConfig} from "../../../services/restserver-openapi";
import {mapErrorMessages} from "../../../utils/errorHandling";

export type GamificationConfigState = {
    loading: boolean;
    levelAndRankConfig: LevelAndRankConfig | null;
    error: string | null;
}

const initialState: GamificationConfigState = {
    loading: false,
    levelAndRankConfig: null,
    error: null,
}

export const asyncGetLevelAndRankConfig = createAsyncThunk(
    'config',
    async () => {
        return getLevelAndRankConfig();
    }
);

export const gamificationConfigSlice = createSlice({
    name: 'gamificationConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(asyncGetLevelAndRankConfig.pending, (state) => {
                state.loading = true
            })
            .addCase(asyncGetLevelAndRankConfig.fulfilled, (state, {payload}) => {
                state.levelAndRankConfig = payload
                state.loading = false
            })
            .addCase(asyncGetLevelAndRankConfig.rejected, (state, action) => {
                if (action.error.message) {
                    let errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
    }
});

//export const {} = gamificationConfigSlice.actions;

export const gamificationConfigReducer = gamificationConfigSlice.reducer;