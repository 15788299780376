export const vanliferPages = [
    {
        title: 'Reiseplanung',
        href: '/vanlifer/VanliferOverview',
    },
    {
        title: 'Reiseziele',
        href: '/vanlifer/regions',
    },
    {
        title: 'Meine Reisen',
        href: '/vanlifer/BookingOverview',
    },
    {
        title: 'Community',
        href: '/vanlifer/Community',
    },
    {
        title: 'Einstellungen',
        href: '/profil/personal',
    }
];

export const hostPages = [
    {
        title: 'Startseite',
        href: '/host/HostOverview',
    },
    {
        title: 'Verfügbarkeiten',
        href: '/host/SitesManagement',
    },
    {
        title: 'Mein Betrieb',
        href: '/host/Compounds',
    },
    {
        title: 'Einstellungen',
        href: '/profil/abos',
    }];

export const agentPages = [
    {
        title: 'Übersicht',
        href: '/travelAgent/travelAgentOverview',
    },
    {
        title: 'Profil',
        href: '/profil/personal',
    }
];