/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LevelAndRankConfig } from '../models/LevelAndRankConfig';
import type { UserStats } from '../models/UserStats';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GamificationLevelService {
    /**
     * Get the user's level stats
     * @returns UserStats OK
     * @throws ApiError
     */
    public static getUserLevelStats(): CancelablePromise<UserStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gamification/user/stats',
        });
    }
    /**
     * Get the level and rank configuration
     * @returns LevelAndRankConfig OK
     * @throws ApiError
     */
    public static getLevelAndRankConfig(): CancelablePromise<LevelAndRankConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gamification/config',
        });
    }
}
