import React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CompoundImages } from '../campsiteDetailComponents/CompoundImages';
import HereMapWithMarker from '../presentationalComponents/HereMapWithMarker';
import CompoundsListing from '../presentationalComponents/CompoundsListing';
import { CompoundDetailCard } from '../campsiteDetailComponents/CompoundDetailCard';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import SitesListing from '../presentationalComponents/SitesListing';
import { EcoFriendlyIcon } from '../vanliferComponents/EcoFriendlyIcon';


function CompoundComponent() {
  const compound = useAppSelector(state => state.compounds.selectedCompound);

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Name:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'} variant="body1">{compound.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Website:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'} variant="body1"><a href={compound.externalLink} target="_blank"
                                                             rel="noreferrer">{compound.externalLink}</a></Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Adresse:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'}
                          variant="body1">{compound.addressLine1 + ', ' + compound.zipCode + ' ' + compound.city + ', ' + compound.country}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Koordinaten:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'}
                          variant="body1">{'lat:' + compound.coordinates?.x?.toFixed(5) + ', long:' + compound.coordinates?.y?.toFixed(5)}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Regionen:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'}
                          variant="body1">{compound.regions?.map(region => region.name).join(',')}</Typography>
            </Grid>
          </Grid>
          <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
        </Grid>
        <Grid item xs={12}>
          {compound.coordinates?.x && compound.coordinates?.y &&
            <HereMapWithMarker mapMarker={{ latitude: compound.coordinates.x, longitude: compound.coordinates.y }}
                               width={window.innerWidth.toString()} height="250px" />}

        </Grid>
      </Grid>
      <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Standard Check-In Zeit:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography component={'p'} variant="body1">{compound.arrivalTime}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Standard Check-Out Zeit:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography component={'p'} variant="body1">{compound.departureTime}</Typography>
        </Grid>
        <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>Beschreibung des Betriebs:</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography component={'p'} variant="body1">{compound.guestInformation}</Typography>
        </Grid>
        <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>Ausstattungen:</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <FormControlLabel control={<Checkbox checked={compound.wifiAvailable} disabled />}
                            label="Wifi / WLAN" />
          <FormControlLabel control={<Checkbox checked={compound.toilet} disabled />}
                            label="WC" />
          <FormControlLabel control={<Checkbox checked={compound.shop} disabled />}
                            label="Shop" />
          <FormControlLabel control={<Checkbox checked={compound.shower} disabled />}
                            label="Dusche" />
          <FormControlLabel control={<Checkbox checked={compound.sewerage} disabled />}
                            label="Abwasseranschluss" />
          <FormControlLabel control={<Checkbox checked={compound.dogsAllowed} disabled />}
                            label="Hunde erlaubt" />
          <FormControlLabel control={<Checkbox checked={compound.familyFriendly} disabled />}
                            label="Familienfreundlich" />
          <FormControlLabel control={<Checkbox checked={compound.inTheMountains} disabled />}
                            label="In den Bergen" />
          <FormControlLabel control={<Checkbox checked={compound.byTheWater} disabled />}
                            label="Am Wasser" />
          <FormControlLabel control={<Checkbox checked={compound.culturalOfferings} disabled />}
                            label="Kulturangebot" />
          <FormControlLabel control={<Checkbox checked={compound.ecoFriendly} disabled />}
                            label="Umweltfreundlicher Betrieb" />
        </Grid>
      </Grid>
      <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Bilder des Betriebs:</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <CompoundImages compound={compound} />
      </Grid>
      <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Unterkünfte des Betriebs:</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        {compound?.compoundId && <SitesListing compoundId={compound.compoundId} />}
      </Grid>
    </Grid>
  );
}

export default CompoundComponent;
