import {Availability, AvailabilityDetail, Site} from "../../services/restserver-openapi";

class CalendarEvent {
    title: string;
    allDay?: boolean;
    startDate: Date;
    endDate: Date;
    desc?: string;
    resourceId?: number | undefined;
    tooltip?: string | undefined;
    status?: Availability.availabilityStatus;

    constructor(
        _title: string,
        _start: Date,
        _endDate: Date,
        _allDay?: boolean,
        _desc?: string,
        _resourceId?: number,
        _state?: Availability.availabilityStatus
    ) {
        this.title = _title;
        this.allDay = _allDay || false;
        this.startDate = _start;
        this.endDate = _endDate;
        this.desc = _desc || '';
        this.resourceId = _resourceId;
        this.status = _state;
    }
}

function getEventStyle(event: CalendarEvent) {
    const backgroundColor =
        event.status === Availability.availabilityStatus.BOOKED
            ? '#CCDA10'
            : event.status === Availability.availabilityStatus.BOOKED_BY_HOST
                ? '#CBD3D9'
                : event.status === Availability.availabilityStatus.RESERVED
                    ? '#FF4136'
                    : '#FFFFFF';
    const style = {
        backgroundColor: backgroundColor,
        color: 'black',
        borderRadius: '5px',
        border: '1px solid black',
        height: 'auto',
        width: '100%',
    };
    return {
        style: style,
    };
}

function getEventsBase(availabilitiesFromState: AvailabilityDetail[], sites: Site[], includeSiteLabelInTitle: boolean): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    const offerIds = sites.map((site) => {
        return site.offer === undefined ? -1 : site.offer!.offerId;
    });
    availabilitiesFromState.forEach((availability) => {
        if (offerIds.includes(availability.offerId)) {
            let siteForOffer = sites.find((site) => site.offer!.offerId === availability.offerId);
            let generalAvailability: CalendarEvent = {
                status: availability.availabilityStatus,
                title: `${availability.price!.toString()} CHF`,
                startDate: new Date(availability.datetime!),
                endDate: new Date(availability.datetime!),
                resourceId: availability?.availabilityId,
            };
            let newAvailability: CalendarEvent;

            switch (generalAvailability.status) {
                case Availability.availabilityStatus.AVAILABLE:
                    newAvailability = {
                        ...generalAvailability,
                        title: `${includeSiteLabelInTitle ? 'Stellplatz ' + siteForOffer?.label + ' - ' : ''}Frei für ${availability.price!.toString()} CHF`,
                        startDate: new Date(availability.datetime! + 43200000),
                        endDate: new Date(availability.datetime! + 79200000 - 1),
                    };
                    break;
                case Availability.availabilityStatus.RESERVED:
                    newAvailability = { ...generalAvailability, title: `Reserviert` };
                    break;
                case Availability.availabilityStatus.BOOKED:
                    newAvailability = {
                        ...generalAvailability,
                        title: `${availability.vanliferFirstname} ${availability.vanliferLastname}, Tel:${availability.vanliferPhone}, ${availability.vanliferMail}`,
                    };
                    break;
                case Availability.availabilityStatus.BOOKED_BY_HOST:
                    newAvailability = {
                        ...generalAvailability,
                        title: `${availability.comment}`,
                    };
                    break;
                default:
                    newAvailability = { ...generalAvailability, title: `Error` };
                    break;
            }
            events.push(newAvailability);
        }
    });
    return events;
}

export { CalendarEvent, getEventsBase, getEventStyle };