/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
export type Person = {
    personId?: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
    birthday?: string;
    gender?: Person.gender;
    address?: Address;
};
export namespace Person {
    export enum gender {
        MALE = 'MALE',
        FEMALE = 'FEMALE',
        OTHER = 'OTHER',
    }
}

