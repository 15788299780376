import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Card, Grid, IconButton, PaletteMode, Stack, Typography, useTheme} from '@mui/material';
import {Close, HelpOutline} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {asyncGetLevelAndRankConfig} from '../../../../store/Vanlifer/Gamification/gamificationConfigSlice';

export default function RankAndLevelExplanationDialog() {
    const theme = useTheme();
    const mode = theme.palette.mode;

    const [open, setOpen] = useState(false);
    const config = useAppSelector(state => state.gamificationConfig.levelAndRankConfig);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(asyncGetLevelAndRankConfig());
    }, [dispatch]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function LevelExplanation() {
        return (
            <React.Fragment>
                <Typography>Das Level verliert man nicht, es setzt sich aus allen gesammelten Travel Points zusammen.</Typography>
                {(config?.levels !== undefined)
                    ? <Typography>Es gibt die Levels 1 bis {config?.levels?.length}.</Typography>
                    : null}
            </React.Fragment>
        );
    }

    function getRankColor(index: number, maxIndex: number | undefined) {
        if (maxIndex === undefined) {
            return theme.palette.primary.light;
        } else if (index === 0) {
            return theme.palette.primary.light ;
        } else if (index === maxIndex - 1) {
            return "gold";
        } else {
            return mode === "light" as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.dark;
        }
    }

    function RankExplanation() {
        return (
            <Grid container spacing={2} alignItems={"space-around"}>
                <Grid item xs={12}>
                    <Typography>Der Rang basiert auf den Travel Points, die du in den letzten {config?.travelPointsExpirationInMonths} Monaten gesammelt hast.</Typography>
                    {(config?.ranks !== undefined) ? <Typography>Es gibt {config?.ranks?.length} Ränge:</Typography> :null}
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={2} direction={"row"} alignItems="center" useFlexGap flexWrap="wrap">
                        {config?.ranks?.map((rank, index) => {
                            return (
                                <Card key={index} sx={{ backgroundColor: getRankColor(index, config?.ranks?.length), p: 1 }}>
                                    <Typography noWrap>{rank.name}</Typography>
                                    <Typography variant="subtitle2" noWrap>
                                        {index === 0 ? "Start-Rang" : `${config?.ranks?.[index].travelPointThreshold} TP notwendig`}
                                    </Typography>
                                </Card>
                            );

                        })}
                    </Stack>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <HelpOutline/>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="rank-and-level-dialog-title"
                aria-describedby="rank-and-level-dialog-description"
                maxWidth={"md"}
            >
                <DialogTitle id="rank-and-level-dialog-title">
                    {"Wie funktionieren Ränge & Levels?"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} id="rank-and-level-dialog-description">
                        <Grid item xs={12}>
                            <Typography>Ränge und Levels bestehen aus <b>Travel Points (TP)</b>.</Typography>
                            <Typography>Travel Points sammelst du zum Beispiel, wenn du eine Tour buchst oder eine Bewertung schreibst.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}><b>Rang:</b></Typography>
                            {RankExplanation()}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}><b>Level:</b></Typography>
                            {LevelExplanation()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<Close/>} color="primary" variant="outlined" onClick={handleClose} autoFocus>
                        Schliessen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}