import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Compound, Region} from "../../services/restserver-openapi";
import {getCompoundsByRegion, getRegions} from "../../utils/Host/offer";
import {mapErrorMessages} from "../../utils/errorHandling";

let errorMessage: string | null;

export const defaultRegion: Region = {
    regionId: 0,
    name: "",
    country: Region.country.CH,
}

export type RegionState = {
    loading: boolean;
    error: string | null;
    region: Region;
    regions: Region[];
    compoundsOfRegion: Compound[];
}

const initialState: RegionState = {
    loading: false,
    error: null,
    region: defaultRegion,
    regions: [],
    compoundsOfRegion: []
}

export const fetchAllRegions = createAsyncThunk(
    'region/fetchAllRegions',
    async () => {
        return getRegions();
    }
)

export const fetchCompoundsOfRegion = createAsyncThunk(
    'region/fetchCompoundsOfRegion',
    async (regionId: number) => {
        return getCompoundsByRegion(regionId);
    }
)

export const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegionById: (state, action) => {
            const regionId = action.payload;
            const region = state.regions.find(region => region.regionId === Number(regionId));
            if (region) {
                state.region = region;
            }
        },
    },
    extraReducers: (builder => {
        builder
            .addCase(fetchAllRegions.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(fetchAllRegions.fulfilled, (state, {payload}) => {
                state.regions = payload
                state.loading = false
            })
            .addCase(fetchAllRegions.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
            .addCase(fetchCompoundsOfRegion.pending, (state, {payload}) => {
                state.loading = true
            })
            .addCase(fetchCompoundsOfRegion.fulfilled, (state, action) => {
                state.compoundsOfRegion = action.payload;
                state.loading = false
            })
            .addCase(fetchCompoundsOfRegion.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            })
    })
});

export const { setRegionById } = regionSlice.actions
export const regionReducer = regionSlice.reducer

