import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography, useTheme} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import ImageIcon from "@mui/icons-material/Image";
import CardContent from "@mui/material/CardContent";
import {createGeneralStyling} from "../../theme/generalStyling";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchAllSites, setSite} from "../../store/Host/sitesManagement/siteSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {fetchAllSizes} from "../../store/Host/sitesManagement/sizesSlice";
import {fetchAllAvailabilities, resetAvailabilityState} from "../../store/Host/sitesManagement/availabilitySlice";
import CardActions from "@mui/material/CardActions";
import ManagementCalendar from "../hostComponents/ManagementCalendar";
import {CalendarIcon} from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";

type SitesListingProp = {
    compoundId: number;
    compoundName?: string;
}

const SitesListing = ({compoundId, compoundName}: SitesListingProp) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);

    const sites = useAppSelector(state => state.site.sites);
    const sizes = useAppSelector(state => state.sizes.sizes);
    const availabilities = useAppSelector(state => state.availability.availabilities);
    const isVerifiedAgent = useAppSelector((state) => state.user.isVerifiedAgent);

    const [openCalendar, setOpenCalendar] = useState(false)

    const handleOpenCalendar = async (offerId: number) => {
        const site = sites.find(site => site.offer!.offerId === offerId);
        if (site) {
            dispatch(setSite(site));
            await dispatch(fetchAllAvailabilities(offerId));
            setOpenCalendar(true);
        } else {
            console.error("Site not found");
        }
    };

    const handleCloseCalendar = () => {
        dispatch(resetAvailabilityState());
        setOpenCalendar(false);
    };

    useEffect(() => {
        dispatch(fetchAllSizes);
        dispatch(fetchAllSites(compoundId));
    }, [compoundId, dispatch]);

    return (
        <Box>
            <Grid container spacing={4}>
                {sites.map((site, i) => (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                            <Box
                                component={Card}
                                width={1}
                                height={1}
                                boxShadow={0}
                                sx={{bgcolor: 'transparent', backgroundImage: 'none'}}
                            >
                                <CardMedia
                                    title={site.label}
                                    sx={{
                                        height: {xs: 200, md: 260},
                                        position: 'relative',
                                        filter:
                                            theme.palette.mode === 'dark'
                                                ? 'brightness(0.7)'
                                                : 'none',
                                    }}
                                >

                                    {
                                        site.imageIds && site.imageIds.length === 0 ? (
                                            <Box height="100%" sx={generalStyling.tourProposalCard.missingImage}>
                                                <ImageIcon fontSize="large" color="disabled"/>
                                                <Typography component={"article"} variant="body1"
                                                            sx={{textAlign: 'center'}}>Kein Bild
                                                    vorhanden.</Typography>
                                            </Box>
                                        ) : (
                                            <img
                                                src={`${process.env.REACT_APP_API_URL as string}/image/${site.imageIds![0]}`}
                                                alt={`Bild vom ${site.label}`}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        )
                                    }
                                </CardMedia>
                                <Box
                                    width={'90%'}
                                    margin={'0 auto'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    boxShadow={3}
                                    bgcolor={'background.paper'}
                                    position={'relative'}
                                    zIndex={3}
                                    sx={{transform: 'translateY(-30px)'}}
                                >
                                    <Box component={CardContent} position={'relative'}>
                                        <Typography variant={'h6'} gutterBottom>
                                            {site.offer!.name} - {site.label}
                                        </Typography>
                                        {/*<Typography variant="subtitle1">*/}
                                        {/*    {site.offer?.description}*/}
                                        {/*</Typography>*/}
                                        {/*<Box>*/}
                                        {/*    <FormControlLabel control={<Checkbox disabled checked={site.electricity} name="electricity"/>}*/}
                                        {/*                      label="Strom"/>*/}
                                        {/*    <FormControlLabel control={<Checkbox disabled checked={site.water} name="water"/>}*/}
                                        {/*                      label="Frischwasser"/>*/}
                                        {/*</Box>*/}
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={4}>
                                                <Typography fontWeight="bold">Grösse:</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography>{sizes.map(size => size.sizeId === site.sizeId ? size.name : "")}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography fontWeight="bold">Gäste:</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography>{site.offer!.maxPerson}</Typography>
                                            </Grid>
                                            {/*<Grid item xs={4}>*/}
                                            {/*    <Typography fontWeight="bold">Gästeinformationen:</Typography>*/}
                                            {/*</Grid>*/}
                                            {/*<Grid item xs={8}>*/}
                                            {/*    <Typography component={"article"} variant="body1">{site.guestInformation}</Typography>*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    </Box>
                                    <Box component={CardActions}>
                                        {isVerifiedAgent && (
                                            <Button
                                                startIcon={<CalendarIcon />}
                                                onClick={() => handleOpenCalendar(site.offer!.offerId!)}
                                                variant="outlined"
                                            >
                                                Kalender
                                            </Button>
                                        )}
                                        <Dialog open={openCalendar} onClose={handleCloseCalendar} fullWidth maxWidth="md">
                                            <DialogContent>
                                                <ManagementCalendar availabilities={availabilities} />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseCalendar} color="primary" startIcon={<CloseIcon />} variant="outlined">
                                                    Kalender schliessen
                                                </Button>
                                            </DialogActions>

                                        </Dialog>

                                    </Box>
                                    <Box flexGrow={1}/>
                                </Box>
                            </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

}

export default SitesListing;